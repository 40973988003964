import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddStatNumberDocument, DeleteStatNumberDocument, StatNumber, StatNumbersDocument, UpdateStatNumberDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";
import { Close, Check } from '@mui/icons-material';
import { formatPhoneNumber } from "../../agent/board/components/EditLeadStatNumber";

export default function StatNumberScreen() {
    const [query, { loading }] = useLazyQuery(StatNumbersDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddStatNumberDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateStatNumberDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteStatNumberDocument);

    const columns: GridColDef<StatNumber>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'phone', headerName: 'Phone', valueGetter: ({ row }: { row: StatNumber }) => `${formatPhoneNumber(row?.phone!)}`, flex: 1 },
        {
            field: 'active', headerName: 'Active', sortable: false, renderCell: (params) => {
                return (params.row.active ? <Check /> : <Close />)
            }
        }
    ];

    function formatParams(args: StatNumber) {
        args.phone = args.phone!.replace(/\D/g, '');
        args.active = Boolean(args.active);
        return args;
    }

    return (<DataGridCRUD singularQueryName={"statNumber"} pluralQueryName={"statNumbers"} formatParams={formatParams} form={Form} title={"Stat Number"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}