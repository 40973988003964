import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import { CompanyUsersDocument, AddCompanyUserDocument, UpdateCompanyUserDocument, DeleteCompanyUserDocument, CompanyUser } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";
import Form from "./form";
import { Check, Close } from "@mui/icons-material";

export default function CompanyUsersScreen() {
    const [query, { loading }] = useLazyQuery(CompanyUsersDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddCompanyUserDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateCompanyUserDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteCompanyUserDocument);

    const columns: GridColDef<CompanyUser>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'alias', headerName: 'Alias', flex: 1 },
        { field: 'user_name', headerName: 'Name', valueGetter: ({ row }: { row: CompanyUser }) => `${row?.User?.first_name ?? ''} ${row?.User?.last_name ?? ''}`, flex: 1 },
        { field: 'user_email', headerName: 'Email', valueGetter: ({ row }: { row: CompanyUser }) => `${row?.User?.email}`, flex: 1 },
        { field: 'permission', headerName: 'Permission', valueGetter: ({ row }: { row: CompanyUser }) => `${row?.Permission?.name}`, flex: 1 },
        {
            field: 'active', headerName: 'Active', sortable: false, renderCell: (params) => {
                return (params.row.active ? <Check /> : <Close />)
            }
        },
        {
            field: 'accepted', headerName: 'Accepted', sortable: false, renderCell: (params) => {
                return (params.row.accepted ? <Check /> : <Close />)
            }
        }
    ];

    function formatParams(args: CompanyUser) {
        args.active = Boolean(args.active);
        return args;
    }

    return (<DataGridCRUD singularQueryName={"companyUser"} pluralQueryName={"companyUsers"} formatParams={formatParams} form={Form} title={"Users"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}