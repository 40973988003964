import { DialogContent, TextField } from "@mui/material";
import { BankRouting } from "../../../../gql/types.graphql";
import AddressForm from "../../settings/address/form";

export default function BankRoutingForm({ defaultValue }: Readonly<{ defaultValue: BankRouting }>) {
    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.bank_name}
            margin="dense"
            id="bank_name"
            name="bank_name"
            label="Bank Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.number}
            margin="dense"
            id="number"
            name="number"
            label="Number"
            type="number"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            margin="dense"
            id="phone"
            defaultValue={defaultValue?.phone}
            name="phone"
            label="Phone"
            type="text"
            fullWidth
            variant="standard"
        />
        <AddressForm defaultValue={defaultValue?.Address!}></AddressForm>
    </DialogContent>)
}