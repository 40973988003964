import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Copyright from '../../components/Copyright';
import { useLoginMutation } from '../../gql/types.graphql';
import { UserLogin, signIn } from '../../store/reducers/user';
import { useAppDispatch } from '../../store/hooks';
import Logo from '../../components/Logo';
import { Card } from '@mui/material';

export default function SignIn(): JSX.Element {
  const [mutationLogin, { loading, error }] = useLoginMutation();
  const dispatch = useAppDispatch()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    mutationLogin({
      variables: {
        email: data.get('email') as string,
        password: data.get('password') as string,
      }
    }).then(response => {
      let data = response?.data?.login as UserLogin;
      if (data.token) {
        dispatch(signIn(data))
      }
    }).catch(err => console.log(err.message))
  };

  return (
    <Grid container>
      <Grid
        item
        sm={12}
        md={6}
        sx={{
          height: '100vh',
          background: "#fafafa",
          padding: "20px"
        }}>
        <CssBaseline />
        <Box
          sx={{
            my: 8,
            mx: 'auto',
            maxWidth: "500px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Logo fillColor='#0169ff' size={0.7}></Logo>
          <Card style={{ padding: "40px", marginTop: "20px" }} variant="outlined">
            <Typography style={{ marginBottom: "20px", fontWeight: "bolder" }} component="h1" variant="h5">
              Log in to your account
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Log In
              </Button>
              <Box>
                <Link style={{ color: "#0169ff" }} href="#" variant="body2">
                  Forgot password?
                </Link>
              </Box>
              <Box>
                <Link style={{ color: "#0169ff" }} href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Box>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Card>
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
        style={{
          height: '100vh',
          backgroundImage: 'url(https://ui-managed-content.nyc3.cdn.digitaloceanspaces.com/H100_Login_Variant_Image_6c2c109569.jpg)',
          backgroundRepeat: 'no-repeat',
          //backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
      </Grid>
    </Grid>
  );
}