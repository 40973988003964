import { Box, Button, Card, CardContent, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { Company, InputLead, Template, TemplateParam, TemplatesDocument, UploadCsvTemplateParamsDocument } from "../../../../gql/types.graphql";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "@emotion/styled";

export interface JSONSelect {
    [x: number]: string;
}

export interface ImportBuilderType {
    template_name?: string;
    new_template?: boolean;
    TemplateParams?: TemplateParam[];
    SelectedFields?: JSONSelect;
    Template?: Template | null;
    leads?: InputLead[]
    valid?: boolean
    Files?: FileList | null
}

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export default function PortfolioImportBuilder({ defaultValue, next, back, onChange }: Readonly<{ defaultValue: ImportBuilderType | null, next: () => void, back: () => void, onChange: (args: ImportBuilderType) => void; }>) {
    const [queryTemplates] = useLazyQuery(TemplatesDocument);
    const [uploadCsvTemplateParams, { loading: loadingUpload }] = useMutation(UploadCsvTemplateParamsDocument);
    const [params, setParams] = useState<ImportBuilderType>({
        new_template: false,
        SelectedFields: [],
        Template: null,
        template_name: '',
        TemplateParams: [],
        valid: false
    });

    function backBtn() {
        onChange(params);
        back();
    }

    useEffect(() => {
        if (defaultValue) {
            setParams(defaultValue)
        }
    }, [defaultValue]);

    function formatParams(args: Company): string {
        return `${args.name}`;
    }

    function updateParams(args: ImportBuilderType) {
        setParams({
            ...params,
            ...args
        })
    }


    const onSelectChange = (event: SelectChangeEvent, index: number) => {
        let selected: JSONSelect = {
            ...params.SelectedFields
        }
        if (event.target.value) {
            selected[index] = event.target.value;
        } else {
            delete selected[index];
        }

        let new_template = params.Template;
        let required: string[] = [];
        new_template!.TemplateTypeFields = params.Template!.TemplateTypeFields!.map(param => {
            if (Object.values(selected).includes(param!.external_id) && !param!.multiple) {
                param!.disabled = true
            } else {
                param!.disabled = false;
            }
            if (param?.required) {
                required.push(param.external_id!)
            }
            return param;
        });

        updateParams({
            ...params,
            SelectedFields: selected,
            Template: new_template,
            valid: required.filter(req => !Object.values(selected).includes(req)).length == 0
        });
    };

    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            uploadCsvTemplateParams({
                variables: {
                    file: event.target.files[0]
                }
            }).then(response => {
                let items = response.data.uploadCSVTemplateParams;
                if (items) {
                    updateParams({
                        TemplateParams: items as TemplateParam[],
                        Files: event.target.files
                    })
                }
            })
        }
    };

    return (<Box onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onChange(params);
        next();
    }} component={'form'}>
        <Grid spacing={5} container sx={{ textAlign: 'start', paddingTop: '30px' }}>
            <Grid item sm={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Template
                        </Typography>
                        <AutocompleteSelect onChange={(value: Template) => {
                            updateParams({
                                Template: value
                            });
                        }} defaultValue={params.Template} name={'template_id'} formatParams={formatParams} queryName={"templates"} query={queryTemplates} label="Templates" id="template_id"></AutocompleteSelect>
                        <FormControlLabel control={<Checkbox checked={params.new_template} onChange={(event) => updateParams({ new_template: event.target.checked })} name="new_template" id="new_template" />} label="Save as new template" />
                        {params.new_template && <TextField
                            autoFocus
                            required
                            onChange={(event) => updateParams({ template_name: event.target.value })}
                            value={params.template_name}
                            margin="dense"
                            id="name"
                            name="name"
                            label="Template Name"
                            type="text"
                            fullWidth
                            variant="standard"
                        />}
                        <Box sx={{ marginTop: '30px' }}>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload file
                                <VisuallyHiddenInput onChange={handleCapture} type="file" />
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={8}>
                <Card>
                    <CardContent>
                        <Typography sx={{ marginBottom: '20px' }} variant="h5" component="div">
                            Fields
                        </Typography>
                        {params.TemplateParams!.length == 0 ? (loadingUpload ? <CircularProgress size={30} /> : <Typography>No fields found</Typography>) : params.TemplateParams!.map((param, i) => {
                            return (<Box key={i.toString()} sx={{
                                display: 'flex',
                                marginBottom: '20px'
                            }}>
                                <Grid container spacing={5}>
                                    <Grid sm={6} item>
                                        <Typography sx={{
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }} variant="subtitle1" fontWeight={'bold'}>{param.name || ''}</Typography>
                                        <Typography sx={{
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'
                                        }}>{param.value || ''}</Typography>
                                    </Grid>
                                    <Grid sm={6} item>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-label">Field</InputLabel>
                                            <Select
                                                labelId="select-label"
                                                id="select"
                                                label="Field"
                                                value={params?.SelectedFields?.[i] ?? ''}
                                                onChange={(value) => onSelectChange(value, i)}
                                            >
                                                <MenuItem key={"0"} value={''}>Select Field</MenuItem>
                                                {params.Template?.TemplateTypeFields!.map((field) => {
                                                    return (
                                                        <MenuItem disabled={field!.disabled || false} key={field?.external_id} value={field?.external_id ?? ''}>{field?.name}{field?.required && ' - Required'}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>)
                        })}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button onClick={backBtn} variant="contained">
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button disabled={!params.valid} type="submit" variant="contained">
                        Next
                    </Button>
                </Box>
            </Grid>
        </Grid>
    </Box>)
}