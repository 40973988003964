import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddPermissionDocument, DeletePermissionDocument, Permission, PermissionsDocument, UpdatePermissionDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function PermissionScreen() {
    const [query, { loading }] = useLazyQuery(PermissionsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddPermissionDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdatePermissionDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeletePermissionDocument);

    const columns: GridColDef<Permission>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 }
    ];

    function formatParams(args: Permission) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"permission"} pluralQueryName={"permissions"} formatParams={formatParams} form={Form} title={"Permissions"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}