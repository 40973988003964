import * as React from "react";
import {
    createBrowserRouter,
    Navigate,
} from "react-router-dom";
import LandingPage from "../screens/public/LandingPage";
import UnsubscribeScreen from "../screens/public/unsubscribe";
export const PUBLIC_ROUTE = createBrowserRouter([
    {
        path: "/",
        element: <LandingPage></LandingPage>,
    },
    {
        path: "/unsubscribe",
        element: <UnsubscribeScreen></UnsubscribeScreen>,
    }
]);