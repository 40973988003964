import { Box, Card, CardContent, CardHeader, Checkbox, Chip, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { addActivityList, enterToSubmit, lead, leadActivityLists, leadExternalId, updateEnterToSubmit } from "../../../../../store/reducers/lead";
import { ActivityList, ActivityType, ActivityTypeEnum, ActivityTypesDocument, useAddActivityListMutation } from "../../../../../gql/types.graphql";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import AutocompleteSelect from "../../../../../components/AutocompleteSelect";
import { LoadingButton } from "@mui/lab";
import { Send } from "@mui/icons-material";
import { DataGridPro, GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import moment from "moment";
export default function ActivityListTable(): JSX.Element {
    const leadExternalIdSelector = useAppSelector(leadExternalId);
    const enterToSubmitSelector = useAppSelector(enterToSubmit);
    const activityLists = useAppSelector(leadActivityLists);
    const [note, setNote] = useState<string>("");
    const [activityType, setActivityType] = useState<ActivityType>();
    const [queryTypes, { loading }] = useLazyQuery(ActivityTypesDocument);
    const dispatch = useAppDispatch();
    const [checked, setChecked] = useState(enterToSubmitSelector);
    const [add, { loading: loadingAdd }] = useAddActivityListMutation();
    const apiRef = useGridApiRef();

    const columns: GridColDef[] = [
        {
            field: 'ActivityType', headerName: 'Activity Type', valueGetter: (value: ActivityType) => {
                return `${value?.name}`;
            }, width: 130,
        },
        {
            field: 'description', headerName: 'Description', flex: 1, renderCell: ({ row }: { row: ActivityList }) => {
                return <Chip label={row.description} style={{ backgroundColor: row.ActivityType?.color! }} variant="outlined" />
            }
        },
        {
            field: 'created_at', headerName: 'Created At', width: 200, valueGetter: (value: string) => {
                return moment(value).format('MMMM Do YYYY, h:mm:ss a');
            }
        }
    ]

    function addActivity() {
        add({
            variables: {
                description: note,
                lead_id: leadExternalIdSelector!,
                activity_type_id: activityType?.external_id
            }
        }).then(response => {
            const result = response.data?.addActivityList;
            if (result) {
                dispatch(addActivityList(result));
                setNote('');
                apiRef.current.scrollToIndexes({
                    rowIndex: 0
                })
            }
        })
    }

    function updateCheck(check: boolean) {
        setChecked(check);
        dispatch(updateEnterToSubmit(check));
    }

    function formatParams(args: ActivityType): string {
        return `${args.name}`;
    }

    return (<Card>
        <CardHeader action={<></>} avatar={<Typography fontSize={'20px'} fontWeight={600}>Activity List</Typography>}></CardHeader>
        <CardContent style={{ paddingTop: '0px' }}>
            <DataGridPro
                apiRef={apiRef}
                rows={activityLists as ActivityList[] ?? []}
                columns={columns}
                style={{ height: '433px' }}
                rowCount={activityLists?.length ?? 0}
                hideFooter={true}
            />
            <TextField
                id="outlined-multiline-static"
                label="Note"
                disabled={loadingAdd}
                multiline
                onKeyDown={(event) => {
                    if (enterToSubmitSelector && event.key == 'Enter' && note) {
                        addActivity()
                    }
                }}
                fullWidth
                value={note}
                onChange={(event) => setNote(event.target.value)}
                style={{ marginTop: '20px' }}
                minRows={2}
                maxRows={2}
            />
            <Box marginTop={'10px'} display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
                <Box width={'200px'}>
                    <AutocompleteSelect variant="outlined" style={{ marginTop: '5px' }} queryParams={{ type: ActivityTypeEnum.User }} required={false} onChange={(value: ActivityType) => setActivityType(value)} name={'activity_type_id'} formatParams={formatParams} queryName={"activityTypes"} query={queryTypes} label="Activity Type" id="activity_type_id"></AutocompleteSelect>
                </Box>
                <Box alignContent={'center'}>
                    <FormControlLabel control={<Checkbox checked={checked} onChange={(event) => updateCheck(event.target.checked)} name="check" id="check" />} label="Enter to Submit" />
                    <LoadingButton loading={loadingAdd} disabled={!note} onClick={() => addActivity()} variant="contained" endIcon={<Send></Send>}>Send</LoadingButton>
                </Box>
            </Box>
        </CardContent>
    </Card>)
}