import Button, { ButtonOwnProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, useEffect, useState } from 'react';
import { Box, CircularProgress, ThemeProvider, Typography, createTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface ArgsType {
    form?: JSX.Element;
    submit?: Function;
    openModal?: boolean;
    closeModal?: Function;
    defaultValue?: {
        external_id?: string
    },
    btnProps?: ButtonOwnProps,
    btnText?: string,
    btnIcon?: JSX.Element
}

export default function FormModal({ form, submit = Function, openModal = false, closeModal = Function, defaultValue, btnProps = {
    sx: { marginLeft: '10px', backgroundColor: '#137c44', paddingInline: '20px', marginRight: '10px', color: 'white' }
}, btnText = 'Create', btnIcon = <AddIcon style={{ fontSize: '14px', marginRight: '5px' }}></AddIcon> }: Readonly<ArgsType>): JSX.Element {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(openModal);
    }, [openModal]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        closeModal(false);
    };


    const handleSubmit = (data: any) => {
        handleClose();
        submit(data, defaultValue?.external_id);
    };

    return (
        <Fragment>
            <Button {...btnProps} onClick={handleClickOpen}>
                {btnIcon}<Typography style={{ fontSize: '14px', textTransform: 'capitalize' }}>{btnText}</Typography>
            </Button>
            <Dialog
                open={open}
                onClose={() => handleClose()}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const data = Object.fromEntries(formData.entries());
                        handleSubmit(data);
                    },
                }}
            >
                <DialogTitle style={{ paddingBottom: "0px" }}>{defaultValue?.external_id ? 'Update' : 'Add'}</DialogTitle>
                {form}
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Submit</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}