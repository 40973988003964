import { Box, Grid, Step, StepButton, Stepper } from "@mui/material";
import { useState } from "react";
import BasicHeader from "../../../../components/BasicHeader";
import PortfolioOptionsForm, { PortfolioOption } from "./portfolio-options-form";
import PortfolioImportBuilder, { ImportBuilderType } from "./portfolio-import-builder";
import PortfolioImportPreview from "./portfolio-import-preview";
import { AddLeadsDocument, AddPortfolioDocument, InputLead, useAddLeadsMutation, useAddPortfolioMutation } from "../../../../gql/types.graphql";
import { useMutation } from "@apollo/client";

export interface FormField {
    ImportBuilder: ImportBuilderType | null
    PortfolioOption: PortfolioOption | null
}

export interface Validator {
    [x: number]: boolean;
}

export default function ImportPortfolioForm() {
    const [activeStep, setActiveStep] = useState(0);
    const [form, setForm] = useState<FormField>({
        ImportBuilder: null,
        PortfolioOption: null
    });
    const [validators, setValidators] = useState<Validator>({});
    const [skipped, setSkipped] = useState(new Set<number>());
    const [addPortfolio, { loading }] = useMutation(AddPortfolioDocument);
    const steps = ['Portfolio Options', 'Import Builder', 'Preview CSV'];

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const complete = (args: ImportBuilderType) => {
        let values = {
            ...form,
            ImportBuilder: args
        };
        addPortfolio({
            variables: {
                leads: values.ImportBuilder.leads as InputLead[],
                vendor_id: values.PortfolioOption?.Vendor?.external_id,
                debt_type_id: values.PortfolioOption?.DebtType?.external_id,
                portfolio_type_id: values.PortfolioOption?.PortfolioType?.external_id,
                portfolio_name: values.PortfolioOption?.portfolio_name,
                purchase_price: values.PortfolioOption?.purchase_price,
                purchase_date: values.PortfolioOption?.purchase_date,
                interest: values.PortfolioOption?.interest,
                description: values.PortfolioOption?.description,
                portfolio_id: values.PortfolioOption?.Portfolio?.external_id,
                portfolio_option: values.PortfolioOption?.portfolio_option,
                subaccount_option: values.PortfolioOption?.subaccount_option,
                subaccount_name: values.PortfolioOption?.subaccount_name,
                subaccount_id: values.PortfolioOption?.Subaccount?.external_id,
                new_template: values.ImportBuilder?.new_template,
                template_name: values.ImportBuilder?.template_name,
                keys: Object.keys(values.ImportBuilder?.SelectedFields!),
                values: Object.values(values.ImportBuilder?.SelectedFields!),
                branch_id: values.PortfolioOption?.Branch?.external_id
            }
        }).then(response => {
            console.log(response?.data)
        });
    }

    const handleNext = () => {
        let selected: Validator = {
            ...validators
        }
        if (!selected[activeStep]) {
            selected[activeStep] = true
        }
        setValidators(selected);
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (<Box sx={{ flexGrow: 1 }}>
        <BasicHeader text="Import Portfolio"></BasicHeader>
        <Grid sx={{ padding: "20px" }} container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ width: '800px', margin: '0px auto;' }}>
                    <Stepper nonLinear={false} activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={validators[index] || false}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep == 0 && <PortfolioOptionsForm onChange={(args) => setForm({
                        ...form,
                        PortfolioOption: args
                    })} next={handleNext} defaultValue={form?.PortfolioOption || null}></PortfolioOptionsForm>}
                    {activeStep == 1 && <PortfolioImportBuilder onChange={(args) => setForm({
                        ...form,
                        ImportBuilder: args
                    })} back={handleBack} next={handleNext} defaultValue={form?.ImportBuilder || null}></PortfolioImportBuilder>}
                    {activeStep == 2 && <PortfolioImportPreview next={(args) => complete(args)} back={handleBack} defaultValue={form?.ImportBuilder || null}></PortfolioImportPreview>}
                </Box>
            </Grid>
        </Grid>
    </Box >)
}