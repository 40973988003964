import { Add, Close } from "@mui/icons-material";
import { Box, CardHeader, List, Typography } from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import AddLeadFile from "./AddLeadFile";
import { addLeadFile, lead, leadFiles, removeLeadFile } from "../../../../../store/reducers/lead";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { InputLeadFile, LeadFile, useAddLeadFileMutation, useDeleteLeadFileMutation } from "../../../../../gql/types.graphql";

export default function LeadFiles(): JSX.Element {
    const leadSelector = useAppSelector(lead);
    const files = useAppSelector(leadFiles);
    const dispatch = useAppDispatch();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [add, { loading: loadingAdd }] = useAddLeadFileMutation();
    const [destroy, { loading: loadingDestroy }] = useDeleteLeadFileMutation();

    function onSubmit(args: InputLeadFile) {
        setOpenEdit(false);
        add({
            variables: {
                file: args.file,
                lead_id: leadSelector?.external_id!
            }
        }).then(response => {
            const result = response.data?.addLeadFile;
            if (result) {
                dispatch(addLeadFile(result))
            }
        })
    }

    function onDelete(args: LeadFile) {
        dispatch(removeLeadFile(args));
        destroy({
            variables: {
                external_id: args.external_id!
            }
        }).then(response => {

        })
    }


    return (
        <Box>
            <CardHeader style={{ padding: '0px', marginBottom: '10px' }} action={<LoadingButton loading={loadingAdd} onClick={() => setOpenEdit(!openEdit)}>{openEdit ? <Close></Close> : <Add></Add>}</LoadingButton>} avatar={<Typography fontWeight={600}>Files</Typography>} />
            {openEdit && <AddLeadFile loading={loadingAdd} onSubmit={onSubmit}></AddLeadFile>}
            <List dense={true} sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                paddingLeft: '0px',
                maxHeight: 170,
                '& ul': { padding: 0 }
            }}>
                {files?.map((file, index) => {
                    return (
                        <AddLeadFile key={file?.external_id} onDelete={onDelete} onSubmit={onSubmit} defaultValue={file!}></AddLeadFile>
                    )
                })}
            </List>
        </Box>
    );
}