import { DialogContent, FormControl, Grid, Input, InputLabel, TextField } from "@mui/material";
import { Company, User, UsersDocument } from "../../../../gql/types.graphql";
import { PhoneField } from "../../../../components/PhoneField";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { useLazyQuery } from "@apollo/client";
import AddressForm from "../../settings/address/form";

export default function BankRoutingForm({ defaultValue }: Readonly<{ defaultValue: Company }>) {
    const [query, { loading }] = useLazyQuery(UsersDocument);

    function formatParams(args: User): string {
        return `${args.first_name} ${args.last_name}`;
    }

    return (<DialogContent style={{ width: '500px' }}>
        <Grid padding={"10px"} container spacing={2}>
            <Grid item padding={"5px"} xs={12} md={6}>
                <TextField
                    autoFocus
                    required
                    defaultValue={defaultValue?.name}
                    margin="dense"
                    id="name"
                    inputProps={{
                        maxLength: 100
                    }}
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    autoFocus
                    required
                    defaultValue={defaultValue?.short_name}
                    margin="dense"
                    id="short_name"
                    name="short_name"
                    inputProps={{
                        maxLength: 20
                    }}
                    label="Short Name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    autoFocus
                    required
                    defaultValue={defaultValue?.email}
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <FormControl style={{ marginTop: "8px" }} fullWidth variant="standard">
                    <InputLabel htmlFor="formatted-text-mask-input">Phone</InputLabel>
                    <Input
                        autoFocus
                        required
                        type="text"
                        margin="dense"
                        inputProps={{
                            maxLength: 14
                        }}
                        defaultValue={defaultValue?.phone}
                        name="phone"
                        id="phone"
                        inputComponent={PhoneField as any}
                    />
                </FormControl>
                <AutocompleteSelect defaultValue={defaultValue?.Owner} name={'owner_id'} formatParams={formatParams} queryName={"users"} query={query} label="Owner" id="owner_id"></AutocompleteSelect>
            </Grid>
            <Grid item padding={"5px"} xs={12} md={6}>
                <AddressForm defaultValue={defaultValue?.Address!}></AddressForm>
            </Grid>
        </Grid>
    </DialogContent>)
}