import { DialogContent, TextField } from "@mui/material";
import { CompaniesDocument, Company, FileType, VendorType } from "../../../../gql/types.graphql";
import { useLazyQuery } from "@apollo/client";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";

export default function Form({ defaultValue }: Readonly<{ defaultValue: VendorType }>) {
    const [query, { loading }] = useLazyQuery(CompaniesDocument);

    function formatParams(args: Company): string {
        return `${args.name}`;
    }


    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            defaultValue={defaultValue?.description}
            margin="dense"
            id="description"
            name="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            minRows={4}
            maxRows={4}
            variant="standard"
        />
        <AutocompleteSelect defaultValue={defaultValue?.Company} name={'company_id'} formatParams={formatParams} queryName={"companies"} query={query} label="Company" id="company_id"></AutocompleteSelect>
    </DialogContent>)
}