import Form from "./form";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import { ActivityType, useActivityTypeLazyQuery, useActivityTypesLazyQuery, useAddActivityTypeMutation, useDeleteActivityTypeMutation, useUpdateActivityTypeMutation } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function ActivityTypeScreen() {
    const [query, { loading }] = useActivityTypesLazyQuery();
    const [add, { loading: loadingAdd }] = useAddActivityTypeMutation();
    const [update, { loading: loadingUpdate }] = useUpdateActivityTypeMutation();
    const [destroy, { loading: loadingDestroy }] = useDeleteActivityTypeMutation();

    const columns: GridColDef<ActivityType>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'type', headerName: 'Type', minWidth: 150 },
        {
            field: 'color', headerName: 'Color', flex: 1, renderCell: ({ row }: { row: ActivityType }) => {
                return <div style={{ width: '20px', height: '20px', border: '1px solid black', backgroundColor: `${row.color}`, borderRadius: '50%' }}></div>
            }
        }
    ];

    function formatParams(args: ActivityType) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"activityType"} pluralQueryName={"activityTypes"} formatParams={formatParams} form={Form} title={"Activity Type"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}