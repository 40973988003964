import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../../components/DataGridCRUD";
import { AddEmailTemplateDocument, DeleteEmailTemplateDocument, EmailTemplate, EmailTemplatesDocument, UpdateEmailTemplateDocument, } from "../../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";
import ButtonLink from "../../../../../components/ButtonLink";
import { useNavigate } from "react-router-dom";
import Form from "./form";

export default function EmailTemplateListScreen() {
    const [query, { loading }] = useLazyQuery(EmailTemplatesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddEmailTemplateDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateEmailTemplateDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteEmailTemplateDocument);
    const navigate = useNavigate();

    const columns: GridColDef<EmailTemplate>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 300 },
        { field: 'subject', headerName: 'Subject', flex: 1 }
    ];

    function formatParams(args: EmailTemplate) {
        return args;
    }

    return (<DataGridCRUD editButtonClick={(row: EmailTemplate) => navigate(`/marketing/email-templates/edit/${row.external_id}`)} addButton={<ButtonLink text={'Create'} onClick={() => navigate("/marketing/email-templates/create")}></ButtonLink>} singularQueryName={"emailTemplate"} pluralQueryName={"emailTemplates"} formatParams={formatParams} form={Form} title={"Email Templates"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}