import { DialogContent, Grid, TextField } from "@mui/material";
import { Contract } from "../../../../gql/types.graphql";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { Editor as TinyMCEEditor } from 'tinymce';

export default function Form({ defaultValue }: Readonly<{ defaultValue: Contract }>) {
    const editorRef = useRef<any>(null);
    const [contract, setContract] = useState(defaultValue?.contract ?? "");

    const handleEditorChange = (html: string, editor: TinyMCEEditor) => {
        setContract(html)
    }

    return (<DialogContent style={{ width: '600px' }}>
        <Grid spacing={2} container>
            <Grid item sm={6}>
                <TextField
                    autoFocus
                    required
                    defaultValue={defaultValue?.name}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item sm={6}>
                <TextField
                    id="description"
                    autoFocus
                    defaultValue={defaultValue?.description}
                    margin="dense"
                    name="description"
                    label="Description"
                    variant="standard"
                    type="text"
                    fullWidth
                />
            </Grid>
            <Grid item sm={12}>
                <TextField
                    id={'contract'}
                    required
                    style={{
                        position: 'relative',
                        width: "0px",
                        height: "0px",
                        top: "100px",
                        right: "-50%",
                        overflow: "hidden",
                        pointerEvents: "none"
                    }}
                    name={'contract'}
                    value={contract}
                    type="text"
                />
                <Editor
                    textareaName="contract"
                    onInit={(_evt, editor) => editorRef.current = editor}
                    apiKey={process.env.REACT_APP_EDITOR_TOKEN}
                    value={contract}
                    init={{
                        media_live_embeds: true,
                        file_picker_types: 'file image media',
                        plugins: 'preview image fullscreen autolink emoticons lists searchreplace wordcount checklist formatpainter permanentpen powerpaste advcode mentions mergetags autocorrect typography markdown',
                        toolbar: 'image preview fullscreen forecolor backcolor fontsize bold italic underline mergetags | blocks fontfamily | strikethrough | spellcheckdialog typography | align lineheight | checklist numlist indent outdent | emoticons charmap | removeformat',
                        mergetags_list: [
                            { value: 'LeadName.first_name', title: 'Lead - First Name' },
                            { value: 'LeadName.last_name', title: 'Lead - Last Name' },
                            { value: 'LeadName.full_name', title: 'Lead - Full Name' },
                            { value: 'LeadAddress.full_address', title: 'Lead - Full Address' },
                            { value: 'LeadAddress.addresss', title: 'Lead - Address' },
                            { value: 'LeadAddress.city', title: 'Lead - City' },
                            { value: 'LeadAddress.state', title: 'Lead - State' },
                            { value: 'LeadAddress.zipcode', title: 'Lead - ZipCode' },
                            { value: 'StatNumber.phone', title: 'Lead - Stat Number' },
                            { value: 'id', title: 'Lead - ID' },
                            { value: 'CompanyUser.alias', title: 'User - Alias' },
                            { value: 'CompanyUser.first_name', title: 'User - First Name' },
                            { value: 'CompanyUser.last_name', title: 'User - Last Name' },
                            { value: 'CompanyUser.full_name', title: 'User - Full Name' },
                            { value: 'Company.short_name', title: 'Company - Short Name' },
                            { value: 'Company.Image.url', title: 'Company - Logo' },
                            { value: 'Company.Image.url', title: 'Company - Logo' },
                            { value: 'Company.full_address', title: 'Company - Full Address' },
                            { value: 'Company.phone', title: 'Company - Phone' },
                        ],
                        image_uploadtab: true,
                        image_advtab: true,
                        images_upload_handler: (blobInfo, progress) => {
                            return new Promise((resolve, reject) => {
                                console.log(blobInfo.blob())
                                //uploadFileToS3Bucket(blobInfo.blob(), resolve, reject);
                            })
                        }
                    }}
                    initialValue={defaultValue?.contract ?? ""}
                    onEditorChange={handleEditorChange}
                />
            </Grid>
        </Grid>
    </DialogContent>)
}