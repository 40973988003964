import { Box, Divider, Typography } from "@mui/material";
import OnlyIconLogo from "./OnlyIconLogo";

export default function ToolbarTitle({ title }: { title?: String }): JSX.Element {
    return (<Box sx={{ display: 'flex', alignItems: 'center' }}>
        <OnlyIconLogo fillColor='#0169ff'></OnlyIconLogo>
        <Divider sx={{ height: '35px', marginInline: "10px" }} orientation="vertical"></Divider>
        {title &&
            <Typography style={{ color: '#000000', fontWeight: 'lighter' }} variant="h6" component="div">
                {title}
            </Typography>
        }
    </Box>)
}