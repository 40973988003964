import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddSegmentSelectDocument, DeleteSegmentSelectDocument, SegmentSelect, SegmentSelectsDocument, UpdateSegmentSelectDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function SegmentSelectScreen() {
    const [query, { loading }] = useLazyQuery(SegmentSelectsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddSegmentSelectDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateSegmentSelectDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteSegmentSelectDocument);

    const columns: GridColDef<SegmentSelect>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 }
    ];

    function formatParams(args: SegmentSelect) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"segmentSelect"} pluralQueryName={"segmentSelects"} formatParams={formatParams} form={Form} title={"Segment Selects"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}