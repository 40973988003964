import { DataGrid, GridActionsCellItem, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import BasicHeader from "./BasicHeader";
import { Box, Card, CardContent, CircularProgress, colors, LinearProgress, Typography } from "@mui/material";
import ConfirmModal from "./ConfirmModal";
import FormModal from "./FormModal";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled, alpha } from '@mui/material/styles';

interface ModalDataType {
    data?: any
    open: boolean
}

interface ArgsType {
    loading: boolean;
    columns: Readonly<GridColDef<any>[]>;
    query: Function;
    add: Function;
    update?: Function;
    destroy: Function;
    title: string;
    singularQueryName: string;
    pluralQueryName: string;
    formatParams: Function;
    form?: Function;
    addButton?: JSX.Element | undefined;
    editButtonClick?: Function;
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .no-rows-primary': {
        fill: theme.palette.mode === 'light' ? '#AEB8C2' : '#3D4751',
    },
    '& .no-rows-secondary': {
        fill: theme.palette.mode === 'light' ? '#E8EAED' : '#1D2126',
    },
}));

export default function DataGridCRUD({ loading, columns: newColumns, query, add, update, destroy, title, form, formatParams, singularQueryName, pluralQueryName, addButton, editButtonClick }: Readonly<ArgsType>): JSX.Element {
    const [columns, setColumns] = useState([
        ...newColumns,
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ row, id }: { row: any, id: string }) => {
                return [
                    <GridActionsCellItem
                        key={1}
                        icon={<EditIcon />}
                        label="Save"
                        style={{ display: (update || editButtonClick) ? 'flex' : 'none' }}
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={() => editButtonClick ? editButtonClick(row) : setOpenModal({
                            open: true,
                            data: row
                        })}
                    />,
                    <GridActionsCellItem
                        key={2}
                        icon={<DeleteIcon />}
                        label="Delete"
                        sx={{
                            color: colors.red[600],
                        }}
                        onClick={() => setConfirmModal({
                            open: true,
                            data: row
                        })}
                    />]
            }
        }
    ])
    const apiRef = useGridApiRef();

    const [openModal, setOpenModal] = useState<ModalDataType>({
        open: false,
        data: {}
    });

    const [confirmModal, setConfirmModal] = useState<ModalDataType>({
        open: false,
        data: {}
    });

    const [rows, setRows] = useState({
        data: [],
        totalCount: 0,
        pageInfo: {
            cursor: 0,
            hasNextPage: 0,
            limit: 0
        }
    });

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100
    });

    useEffect(() => {
        queryData()
    }, []);

    async function queryData(search?: string) {
        query({
            variables: {
                limit: paginationModel.pageSize,
                cursor: paginationModel.page,
                search
            }
        }).then((result: any) => {
            if (result.data[`${pluralQueryName}`]) {
                setRows(result.data[`${pluralQueryName}`]);
            }
        }).catch((err: any) => console.error(err));
    }

    async function updateData(args: any, external_id: string) {
        args = formatParams(args);
        if (update) {
            update({
                variables: {
                    ...args,
                    external_id
                }
            }).then((value: any) => {
                let data = value.data[`update${singularQueryName[0].toUpperCase()}${singularQueryName.slice(1)}`];
                if (data) {
                    apiRef.current.updateRows([data])
                }
            }).catch((err: any) => console.error(err))
        }
    }

    async function addData(args: any) {
        args = formatParams(args);
        add({
            variables: args
        }).then((value: any) => {
            let data = value.data[`add${singularQueryName[0].toUpperCase()}${singularQueryName.slice(1)}`];
            apiRef.current.updateRows([data])
        }).catch((err: any) => console.error(err))
    }

    async function destroyData() {
        let external_id = confirmModal.data.external_id;
        destroy({
            variables: {
                external_id
            }
        }).then(() => {
            apiRef.current.updateRows([{ id: confirmModal.data.id, _action: 'delete' }])
        }).catch((err: any) => console.error(err))
    }

    async function submit(data: any, external_id: string) {
        if (external_id) {
            await updateData(data, external_id);
        } else {
            await addData(data);
        }
    }


    return (
        <Box sx={{ flex: 1 }}>
            <BasicHeader refresh={() => queryData()} add={addButton ?? <FormModal defaultValue={openModal.data} closeModal={(open: boolean) => setOpenModal({
                open
            })} openModal={openModal.open} submit={submit} form={form && form({ defaultValue: openModal.data })}></FormModal>} search={queryData} text={title}></BasicHeader>
            <ConfirmModal title={'You are going to delete this item, are you sure?'} close={() => setConfirmModal({
                ...confirmModal,
                open: false
            })} confirm={() => destroyData()} open={confirmModal.open}></ConfirmModal>
            <Box padding={'10px'}>
                <Card>
                    <CardContent style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            apiRef={apiRef}
                            density="compact"
                            rows={rows?.data ?? []}
                            loading={loading}
                            columns={columns}
                            rowCount={rows?.totalCount ?? 0}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: paginationModel.page, pageSize: paginationModel.pageSize },
                                },
                            }}
                            pageSizeOptions={[10, 50, 100]}
                            paginationModel={paginationModel}
                            paginationMode="server"
                            onPaginationModelChange={setPaginationModel}
                        />
                    </CardContent>
                </Card>

            </Box>
        </Box>
    );
}