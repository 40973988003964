import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddPortfolioDocument, DeletePortfolioDocument, Portfolio, PortfoliosDocument, UpdatePortfolioDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";
import ButtonLink from "../../../../components/ButtonLink";
import { useNavigate } from "react-router-dom";
import { Check, Close } from "@mui/icons-material";

export default function PortfolioScreen() {
    const [query, { loading }] = useLazyQuery(PortfoliosDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddPortfolioDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdatePortfolioDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeletePortfolioDocument);
    const navigate = useNavigate();

    const columns: GridColDef<Portfolio>[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'leads', headerName: 'Leads', valueGetter: ({ row }: { row: Portfolio }) => `${row?.leads?.toLocaleString()}`, flex: 1 },
        { field: 'vendor', headerName: 'Vendor', valueGetter: ({ row }: { row: Portfolio }) => `${row?.Vendor?.name}`, flex: 1 },
        { field: 'debt_type', headerName: 'Debt Type', valueGetter: ({ row }: { row: Portfolio }) => `${row?.DebtType?.name}`, flex: 1 },
        { field: 'portfolio_type', headerName: 'Portfolio Type', valueGetter: ({ row }: { row: Portfolio }) => `${row?.PortfolioType?.name}`, flex: 1 },
        { field: 'branch', headerName: 'Branch', valueGetter: ({ row }: { row: Portfolio }) => `${row?.Branch?.name}`, flex: 1 },
        {
            field: 'active', headerName: 'Active', sortable: false, renderCell: (params) => {
                return (params.row.active ? <Check /> : <Close />)
            }
        },
    ];

    function formatParams(args: Portfolio) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"portfolio"} pluralQueryName={"portfolios"} formatParams={formatParams} form={Form} addButton={<ButtonLink text={'Import Portfolio'} onClick={() => navigate("/settings/import-portfolios")}></ButtonLink>} title={"Portfolios"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}