import { AddCircle, Delete, Save } from "@mui/icons-material";
import { Box, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { LeadAddress } from "../../../../../gql/types.graphql";
import { useEffect, useState } from "react";
import { LoadingButton } from '@mui/lab';

export default function AddLeadAddress({ defaultValue, onSubmit, onDelete, loading: loadingValue }: Readonly<{ defaultValue?: LeadAddress, onSubmit: (args: LeadAddress) => void; onDelete?: (args: LeadAddress) => void; loading: boolean }>): JSX.Element {
    const [address, setAddress] = useState<LeadAddress>({
        Address: {
            address: "",
            city: "",
            second_address: "",
            state: "",
            zipcode: ""
        }
    });

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(loadingValue);
    }, [loadingValue])

    useEffect(() => {
        if (defaultValue) {
            setAddress(defaultValue)
        }
    }, [defaultValue]);

    return (
        <Box key={'list-item-' + address.external_id} style={{ width: '100%' }} component={'form'} onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit(address)
        }} >
            <ListItem disablePadding>
                <ListItemText
                    primary={<TextField onChange={(event) => setAddress({
                        ...address,
                        Address: {
                            ...address.Address,
                            address: event.target.value
                        }
                    })} required id="address" name="address" value={address.Address?.address} fullWidth size="small" label="Address" variant="outlined" />}
                />
                {!defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }} aria-label="delete">
                    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" style={{ margin: 'auto' }} color="secondary"><AddCircle /></LoadingButton>
                </ListItemIcon>}
                {defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center', marginInline: '10px' }} aria-label="delete">
                    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" style={{ margin: 'auto' }} color="success"><Save /></LoadingButton>
                </ListItemIcon>}
                {defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center' }} aria-label="delete">
                    <LoadingButton size="large" variant="outlined" onClick={() => onDelete && onDelete(address)} style={{ margin: 'auto' }} color="error"><Delete /></LoadingButton>
                </ListItemIcon>}
            </ListItem>
            <ListItem disablePadding>
                <ListItemText
                    primary={<TextField onChange={(event) => setAddress({
                        ...address,
                        Address: {
                            ...address.Address,
                            city: event.target.value
                        }
                    })} required id="city" name="city" value={address.Address?.city} fullWidth size="small" label="City" variant="outlined" />}
                />
                <ListItemText
                    style={{ marginInline: '10px' }}
                    primary={<TextField onChange={(event) => setAddress({
                        ...address,
                        Address: {
                            ...address.Address,
                            state: event.target.value
                        }
                    })} required id="state" name="state" value={address.Address?.state} fullWidth size="small" label="State" variant="outlined" />}
                />
                <ListItemText
                    primary={<TextField onChange={(event) => setAddress({
                        ...address,
                        Address: {
                            ...address.Address,
                            zipcode: event.target.value
                        }
                    })} required id="zipcode" name="zipcode" value={address.Address?.zipcode} fullWidth size="small" label="Zip Code" variant="outlined" />}
                />
            </ListItem>
        </Box>
    );
}