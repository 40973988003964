import { DialogContent, FormControl, Input, InputLabel, TextField } from "@mui/material";
import { Phone } from "../../../../gql/types.graphql";
import { PhoneField } from "../../../../components/PhoneField";

export default function Form({ defaultValue }: Readonly<{ defaultValue: Phone }>) {

    return (<DialogContent style={{ width: '400px' }}>
        <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="formatted-text-mask-input">Phone Number</InputLabel>
            <Input
                autoFocus
                required
                type="text"
                margin="dense"
                defaultValue={defaultValue?.phone}
                name="phone"
                id="phone"
                inputComponent={PhoneField as any}
            />
        </FormControl>
    </DialogContent>)
}