import { Box, Button, Card, CardContent, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Branch, BranchesDocument, Company, DebtType, DebtTypesDocument, ImportPortfolioOption, ImportSubaccountOption, Portfolio, PortfolioType, PortfolioTypesDocument, PortfoliosDocument, Subaccount, SubaccountsDocument, Vendor, VendorsDocument } from "../../../../gql/types.graphql";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

export interface PortfolioOption {
    Branch?: Branch | null;
    Portfolio?: Portfolio | null;
    Subaccount?: Subaccount | null;
    Vendor?: Vendor | null;
    PortfolioType?: PortfolioType | null;
    DebtType?: DebtType | null;
    subaccount_option?: ImportSubaccountOption;
    portfolio_name?: string;
    subaccount_name?: string;
    portfolio_option?: ImportPortfolioOption;
    interest?: number
    purchase_price?: number,
    purchase_date?: Dayjs,
    description?: string;
}

export default function PortfolioOptionsForm({ defaultValue, next, onChange }: Readonly<{
    defaultValue: PortfolioOption | null, next: () => void, onChange: (args: PortfolioOption) => void;
}>) {
    const [query] = useLazyQuery(BranchesDocument);
    const [queryPortfolio] = useLazyQuery(PortfoliosDocument);
    const [querySubaccount] = useLazyQuery(SubaccountsDocument);
    const [queryDebtType] = useLazyQuery(DebtTypesDocument);
    const [queryVendor] = useLazyQuery(VendorsDocument);
    const [queryPortfolioType] = useLazyQuery(PortfolioTypesDocument);

    const [params, setParams] = useState<PortfolioOption>({
        Branch: null,
        DebtType: null,
        Portfolio: null,
        PortfolioType: null,
        Subaccount: null,
        Vendor: null,
        portfolio_option: ImportPortfolioOption.New,
        subaccount_option: ImportSubaccountOption.Sheet,
        portfolio_name: '',
        subaccount_name: '',
        interest: 0,
        purchase_date: dayjs(),
        purchase_price: 0,
        description: ''
    });


    useEffect(() => {
        if (defaultValue) {
            setParams(defaultValue)
        }
    }, [defaultValue]);

    function formatParams(args: Company): string {
        return `${args.name}`;

    }

    function updateParams(args: PortfolioOption) {
        setParams({
            ...params,
            ...args
        })
    }

    const handlePortfolioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as ImportPortfolioOption;
        updateParams({ portfolio_option: value, subaccount_option: value == ImportPortfolioOption.New && params?.subaccount_option == ImportSubaccountOption.Existing ? ImportSubaccountOption.Sheet : params?.subaccount_option });
    };

    return (<Box onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onChange(params);
        next();
    }} component={'form'}>
        <Grid spacing={5} container sx={{ paddingTop: '30px', textAlign: 'start' }}>
            <Grid item sm={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Portfolio
                        </Typography>
                        <AutocompleteSelect defaultValue={defaultValue?.Branch} name={'branch_id'} onChange={(value: Branch) => updateParams({ Branch: value })} formatParams={formatParams} queryName={"branches"} query={query} label="Branch" id="branch_id"></AutocompleteSelect>
                        {params?.portfolio_option === ImportPortfolioOption.New && <TextField autoFocus required value={params?.portfolio_name} onChange={(event) => updateParams({ portfolio_name: event.target.value })} margin="dense" id="name" name="name" label="Name" type="text" fullWidth variant="standard" />}
                        {params?.portfolio_option === ImportPortfolioOption.Existing && <AutocompleteSelect onChange={(value: Portfolio) => updateParams({ Portfolio: value })} defaultValue={null} name={'portfolio_id'} formatParams={formatParams} queryName={"portfolios"} query={queryPortfolio} label="Portfolio" id="portfolio_id"></AutocompleteSelect>}
                        <RadioGroup
                            sx={{ marginTop: "20px" }}
                            row
                            value={params?.portfolio_option}
                            onChange={(value) => handlePortfolioChange(value)}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="portfolio_type"
                        >
                            <FormControlLabel value={ImportPortfolioOption.New} control={<Radio />} label="New" />
                            <FormControlLabel value={ImportPortfolioOption.Existing} control={<Radio />} label="Existing Portfolio" />
                        </RadioGroup>
                    </CardContent>
                </Card>

                {
                    <Card sx={{ marginTop: '20px' }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Subaccount
                            </Typography>
                            <RadioGroup
                                value={params?.subaccount_option}
                                row
                                onChange={(value) => updateParams({ subaccount_option: value.target.value as ImportSubaccountOption })}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="subaccount_type"
                            >
                                <FormControlLabel value={ImportSubaccountOption.New} control={<Radio />} label="New" />
                                {params?.portfolio_option == ImportPortfolioOption.Existing && <FormControlLabel value={ImportSubaccountOption.Existing} control={<Radio />} label="Existing" />}
                                <FormControlLabel value={ImportSubaccountOption.Sheet} control={<Radio />} label="Sheet Column" />
                            </RadioGroup>
                            {params?.subaccount_option == ImportSubaccountOption.New && <TextField autoFocus required onChange={(event) => updateParams({ subaccount_name: event.target.value })} value={params?.subaccount_name} margin="dense" id="subaccount_name" name="subaccount_name" label="Name" type="text" fullWidth variant="standard" />}
                            {params?.subaccount_option == ImportSubaccountOption.Existing && <AutocompleteSelect onChange={(value: Subaccount) => updateParams({ Subaccount: value })} defaultValue={params?.Subaccount} name={'subaccount_id'} formatParams={formatParams} queryName={"subaccounts"} query={querySubaccount} label="Subaccounts" id="subaccount_id"></AutocompleteSelect>}
                        </CardContent>
                    </Card>
                }
            </Grid>

            <Grid item sm={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Details
                        </Typography>
                        <TextField
                            autoFocus
                            required
                            value={params?.interest}
                            onChange={(event) => updateParams({ interest: Number(event.target.value) || 0 })}
                            margin="dense"
                            id="interest"
                            name="interest"
                            label="Interest"
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <TextField
                            autoFocus
                            required
                            value={params?.purchase_price}
                            onChange={(event) => updateParams({ purchase_price: Number(event.target.value) || 0 })}
                            margin="dense"
                            id="purchase_price"
                            name="purchase_price"
                            label="Purchase Price"
                            type="number"
                            fullWidth
                            variant="standard"
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker value={params?.purchase_date} slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        fullWidth: true
                                    }
                                }} label="Purchase date" />
                            </DemoContainer>
                        </LocalizationProvider>
                        <TextField
                            id="description"
                            autoFocus
                            value={params?.description}
                            onChange={(event) => updateParams({ description: event.target.value })}
                            name="description"
                            label="Description"
                            variant="standard"
                            type="text"
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={3}
                        />
                        <AutocompleteSelect defaultValue={defaultValue?.DebtType} onChange={(value: DebtType) => updateParams({ DebtType: value })} name={'debt_type_id'} formatParams={formatParams} queryName={"debtTypes"} query={queryDebtType} label="Debt Type" id="debt_type_id"></AutocompleteSelect>
                        <AutocompleteSelect defaultValue={defaultValue?.Vendor} onChange={(value: Vendor) => updateParams({ Vendor: value })} name={'vendor_id'} formatParams={formatParams} queryName={"vendors"} query={queryVendor} label="Vendors" id="vendor_id"></AutocompleteSelect>
                        <AutocompleteSelect defaultValue={defaultValue?.PortfolioType} onChange={(value: PortfolioType) => updateParams({ PortfolioType: value })} name={'portfolio_type_id'} formatParams={formatParams} queryName={"portfolioTypes"} query={queryPortfolioType} label="Portfolio Types" id="portfolio_type_id"></AutocompleteSelect>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item sm={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <></>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button type="submit" variant="contained">
                        Next
                    </Button>
                </Box>
            </Grid>
        </Grid>
    </Box>)
}