import TextField, { TextFieldVariants } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Variant } from '@mui/material/styles/createTypography';

interface ArgsType {
    id: string
    label: string
    query: Function
    name: string
    queryName: string
    formatParams: Function
    defaultValue?: any
    queryParams?: any
    onChange?: Function
    variant?: TextFieldVariants
    hidden?: boolean
    style?: React.CSSProperties | undefined
    required?: boolean
}

export default function AutocompleteSelect({ id, name, label, query, queryName, formatParams, defaultValue, onChange, hidden = false, style = { marginTop: "15px" }, required = true, queryParams = {}, variant = 'standard' }: Readonly<ArgsType>) {
    const [options, setOptions] = useState<readonly any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const [inputValue, setInputValue] = useState('');
    const [selectValue, setSelectValue] = useState<any>(null);

    useEffect(() => {
        if (defaultValue) {
            setSelectValue(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (onChange) {
            onChange(selectValue || null);
        }
        if (selectValue) {
            setValue(selectValue?.external_id || '')
        }
    }, [selectValue])

    async function queryData(search?: string) {
        setLoading(true);
        query({
            variables: {
                limit: 10,
                cursor: 0,
                search: search,
                ...queryParams
            }
        }).then((result: any) => {
            setLoading(false);
            if (result.data[`${queryName}`]) {
                setOptions(result.data[`${queryName}`].data);
            }
        }).catch((err: any) => console.error(err));
    }

    useEffect(() => {
        queryData(inputValue);
    }, [inputValue])

    return (
        <Box style={style}>
            <Autocomplete
                autoFocus
                hidden={hidden}
                onChange={(event, newValue) => {
                    setSelectValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => formatParams(option)}
                options={options}
                isOptionEqualToValue={(option, value) => option?.external_id === value?.external_id}
                value={selectValue}
                loading={loading}
                renderInput={(params) => {
                    return (
                        <TextField
                            required={required}
                            {...params}
                            label={label}
                            variant={variant}
                            size='small'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                ),
                            }}
                        />
                    )
                }}
            />
            <TextField
                style={{ display: 'none' }}
                id={id}
                name={name}
                value={value}
                type="text"
            />
        </Box>
    );
}
