import { styled, alpha } from '@mui/material/styles';
import { Typography, Link, TypographyOwnProps, InputBase, CircularProgress } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    fontSize: '20px',
    width: '100%',
    '& .MuiInputBase-input': {
        color: '#000',
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function SearchInput({ onChange, loading }: Readonly<{ onChange: (value: string) => void, loading: boolean }>): JSX.Element {
    const [value, setValue] = useState<string>("");
    return (
        <Search>
            <SearchIconWrapper>
                {loading ? <CircularProgress size={20} /> : <SearchIcon color='primary' />}
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search…"
                onChange={(event) => setValue(event.target.value)}
                onKeyDown={(event) => event.key === 'Enter' && onChange(value)}
                inputProps={{ 'aria-label': 'search' }}
            />
        </Search>
    );
}