import { LightMode } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Chip, Divider, Grid, IconButton, Typography } from "@mui/material";
import { blue, green, orange } from "@mui/material/colors";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import LeadNames from "./LeadName";
import LeadEmails from "./LeadEmail";
import LeadAddresses from "./LeadAddress";
import LeadFiles from "./LeadFile";
import LeadBalance from "./LeadBalance";
import LeadInfo from "./LeadInfo";
import FormModal from "../../../../../components/FormModal";
import { useAppSelector } from "../../../../../store/hooks";
import { lead, leadBalance, leadFeeBalance, leadInterestBalance, leadPaid, leadPrincipalBalance } from "../../../../../store/reducers/lead";
import ButtonModal from "../../../../../components/ButtonModal";

export default function LeadInfoCard(): JSX.Element {
    const leadSelector = useAppSelector(lead);
    const principalBalance = useAppSelector(leadPrincipalBalance);
    const balance = useAppSelector(leadBalance);
    const paid = useAppSelector(leadPaid);
    return (<Card>
        <CardHeader
            style={{ borderBottom: '1px solid #00000020', alignItems: 'center', display: 'flex' }}
            avatar={
                <Box sx={{ textAlign: 'start', lineHeight: '10px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontWeight={600} fontSize={'20px'} textTransform={'capitalize'}>{leadSelector?.first_name} {leadSelector?.last_name}</Typography>
                        <Chip color='success' style={{ marginLeft: '10px' }} label="Active" />
                    </Box>
                    <Typography fontSize={'14px'}>{leadSelector?.Subaccount?.Portfolio?.Branch?.name}</Typography>
                </Box>
            }
            action={
                <Box height={'60px'} display={'flex'} alignItems={'center'}>
                    <Box margin={'auto'} display={'flex'} alignItems={'center'} marginRight={'20px'}>
                        <Box alignItems={'center'}>
                            <Typography fontSize={'16px'} fontWeight={600}>${principalBalance?.toLocaleString() || 0}</Typography>
                            <Typography fontWeight={600} color={blue[700]} fontSize={'12px'}>ORIGINAL</Typography>
                        </Box>
                        <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                        <Box textAlign={'center'}>
                            <Typography fontSize={'16px'} fontWeight={600}>${balance?.toLocaleString() || 0}</Typography>
                            <Typography fontWeight={600} color={orange[700]} fontSize={'12px'}>BALANCE</Typography>
                        </Box>
                        <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                        <Box textAlign={'center'}>
                            <Typography fontSize={'16px'} fontWeight={600}>${paid?.toLocaleString() || 0}</Typography>
                            <Typography fontWeight={600} color={green[700]} fontSize={'12px'}>PAID</Typography>
                        </Box>
                    </Box>
                    <IconButton aria-label="settings">
                        <GridMoreVertIcon />
                    </IconButton>
                </Box>
            }
        />
        <Grid style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} container>
            <Grid sx={{ backgroundColor: '#f9f9f9' }} borderBottom={'1px solid rgba(0, 0, 0, 0.125)'} item sm={12}>
                <ButtonGroup color="inherit" variant="text" aria-label="Basic button group">
                    <Button style={{ paddingInline: '15px' }} color="primary" size='small'>Close Deal</Button>
                    <ButtonModal text={"DETAILS"} component={<LeadBalance></LeadBalance>}></ButtonModal>
                    <Button style={{ paddingInline: '15px' }} color="primary" size='small'>Details</Button>
                    <Button style={{ paddingInline: '15px' }} color="primary" size='small'>Export Contract</Button>
                    <Button style={{ paddingInline: '15px' }} color="primary" size='small'>Export Check</Button>
                    <Button style={{ paddingInline: '15px' }} color="primary" size='small'>Scripts</Button>
                </ButtonGroup>
            </Grid>
            <Grid borderRight={'1px solid rgba(0, 0, 0, 0.125)'} item sm={6}>
                <Typography component={'span'} fontWeight={600} fontSize={'14px'}>SSN: </Typography>
                <Typography component={'span'}>{leadSelector?.ssn || 'Not found.'}</Typography>
            </Grid>
            <Grid item sm={6}>
                <Typography component={'span'} fontWeight={600} fontSize={'14px'}>DOB: </Typography>
                <Typography component={'span'}>{leadSelector?.dob || 'Not found.'}</Typography>
            </Grid>
            <Grid borderRight={'1px solid rgba(0, 0, 0, 0.125)'} borderTop={'1px solid rgba(0, 0, 0, 0.125)'} item sm={4}>
                <Typography component={'span'} fontWeight={600} fontSize={'14px'}>PHONE: </Typography>
                <Typography component={'span'}>{leadSelector?.LeadPhone?.phone || 'Not found.'}</Typography>
            </Grid>
            <Grid item borderRight={'1px solid rgba(0, 0, 0, 0.125)'} sm={4} borderTop={'1px solid rgba(0, 0, 0, 0.125)'}>
                <Typography component={'span'} fontWeight={600} fontSize={'14px'}>EMAIL: </Typography>
                <Typography component={'span'}>{leadSelector?.LeadEmail?.email || 'Not found.'}</Typography>
            </Grid>
            <Grid item sm={4} borderTop={'1px solid rgba(0, 0, 0, 0.125)'}>
                <Typography component={'span'} fontWeight={600} fontSize={'14px'}>AKA: </Typography>
                <Typography component={'span'}>{leadSelector?.LeadName?.external_id ? `${leadSelector?.LeadName?.first_name} ${leadSelector?.LeadName?.last_name}` : 'Not found.'}</Typography>
            </Grid>
            <Grid item sm={8} borderTop={'1px solid rgba(0, 0, 0, 0.125)'}>
                <Typography component={'span'} fontWeight={600} fontSize={'14px'}>ADDRESS: </Typography>
                <Typography component={'span'}>{leadSelector?.LeadAddress?.external_id ? `${leadSelector?.LeadAddress?.Address?.address}, ${leadSelector?.LeadAddress?.Address?.city}, ${leadSelector?.LeadAddress?.Address?.state}` : 'Not found.'}</Typography>
            </Grid>
            <Grid item sm={4} borderLeft={'1px solid rgba(0, 0, 0, 0.125)'} borderTop={'1px solid rgba(0, 0, 0, 0.125)'}>
                <Typography component={'span'} fontWeight={600} fontSize={'14px'}>TIME: </Typography>
                <Typography component={'span'}>{leadSelector?.TimeZone?.external_id ? <> <LightMode style={{ color: orange[800], marginRight: '10px' }}></LightMode>
                    <Typography fontWeight={600} fontSize={'17px'}>12:18:17 am</Typography></> : 'Not found.'}</Typography>
            </Grid>
        </Grid >
        <CardContent>
            <Grid spacing={2} container>
                <Grid item sm={12}>
                    <LeadInfo></LeadInfo>
                </Grid>
                <Grid item sm={6}>
                    <LeadNames></LeadNames>
                    <LeadAddresses></LeadAddresses>
                </Grid>
                <Grid item sm={6}>
                    <LeadEmails></LeadEmails>
                    <LeadFiles></LeadFiles>
                </Grid>
            </Grid>
        </CardContent>
    </Card >)
}