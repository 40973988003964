import './App.css';
import { Provider } from 'react-redux'
import AppScreen from './screens/app';
import { LicenseInfo } from '@mui/x-license';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/es/integration/react';

function App(): JSX.Element {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_LICENCE_KEY!);

  const LoadingScreen = () => {
    return (<div>Loading</div>)
  }

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate
          loading={<LoadingScreen></LoadingScreen>}
          persistor={persistor}>
          <AppScreen></AppScreen>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;