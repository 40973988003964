import { Box, TextField } from "@mui/material";
import { Address } from "../../../../gql/types.graphql";

export default function AddressForm({ defaultValue, required = true }: Readonly<{ defaultValue: Address, required?: boolean }>) {
    return (<Box>
        <TextField
            autoFocus
            required={required}
            defaultValue={defaultValue?.address}
            margin="dense"
            id="address"
            name="address"
            label="Address"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required={required}
            defaultValue={defaultValue?.zipcode}
            margin="dense"
            id="zipcode"
            name="zipcode"
            label="ZipCode"
            inputProps={{
                maxLength: 5
            }}
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required={required}
            defaultValue={defaultValue?.city}
            margin="dense"
            id="city"
            name="city"
            label="City"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required={required}
            defaultValue={defaultValue?.state}
            margin="dense"
            id="state"
            name="state"
            label="State"
            inputProps={{
                maxLength: 2
            }}
            type="text"
            fullWidth
            variant="standard"
        />
    </Box>)
}