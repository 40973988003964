import { Checkbox, DialogContent, FormControlLabel, TextField } from "@mui/material";
import { TemplateTypeField, TemplateTypesDocument } from "../../../../gql/types.graphql";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export default function Form({ defaultValue }: Readonly<{ defaultValue: TemplateTypeField }>) {

    const [query, { loading }] = useLazyQuery(TemplateTypesDocument);
    const [values, setValues] = useState<TemplateTypeField>();

    useEffect(() => {
        if (defaultValue) {
            setValues({
                ...defaultValue,
                multiple: typeof defaultValue?.multiple === "boolean" ? defaultValue.multiple : false
            });
        }
    }, [defaultValue]);

    function formatParams(args: TemplateTypeField): string | null {
        if (!args) return null;
        return `${args.name}`;
    }

    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.field_id}
            margin="dense"
            id="field_id"
            name="field_id"
            label="Field Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <FormControlLabel control={<Checkbox defaultChecked={values?.multiple!} name="multiple" id="multiple" />} label="Multiple" />
        <FormControlLabel control={<Checkbox defaultChecked={values?.required!} name="required" id="required" />} label="Required" />
        <AutocompleteSelect defaultValue={defaultValue?.TemplateType} name={'template_type_id'} formatParams={formatParams} queryName={"templateTypes"} query={query} label="Template Type" id="template_type_id"></AutocompleteSelect>
    </DialogContent>)
}