import { DialogContent, TextField } from "@mui/material";
import { DebtType } from "../../../../gql/types.graphql";

export default function Form({ defaultValue }: Readonly<{ defaultValue: DebtType }>) {

    return (<DialogContent style={{ width: '600px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            id="description"
            autoFocus
            required
            defaultValue={defaultValue?.description}
            name="description"
            label="Description"
            variant="standard"
            type="text"
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
        />
        <TextField
            autoFocus
            margin="dense"
            id="color"
            defaultValue={defaultValue?.color}
            name="color"
            label="Color"
            type="color"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}