import { Checkbox, DialogContent, FormControl, FormControlLabel, Input, InputLabel } from "@mui/material";
import { StatNumber } from "../../../../gql/types.graphql";
import { useEffect, useState } from "react";
import { PhoneField } from "../../../../components/PhoneField";

export default function Form({ defaultValue }: Readonly<{ defaultValue: StatNumber }>) {
    const [values, setValues] = useState<StatNumber>();

    useEffect(() => {
        if (defaultValue) {
            setValues({
                ...defaultValue,
                active: typeof defaultValue?.active === "boolean" ? defaultValue.active : true
            });
        }
    }, [defaultValue]);

    return (<DialogContent style={{ width: '300px' }}>
        <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="formatted-text-mask-input">Phone</InputLabel>
            <Input
                autoFocus
                required
                type="text"
                margin="dense"
                defaultValue={values?.phone}
                name="phone"
                id="phone"
                inputComponent={PhoneField as any}
            />
        </FormControl>
        <FormControlLabel control={<Checkbox defaultChecked={values?.active!} name="active" id="active" />} label="Active" />
    </DialogContent>)
}