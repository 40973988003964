import Button, { ButtonOwnProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Fragment, useEffect, useState } from 'react';
import { Box, ButtonBaseProps, CircularProgress, ThemeProvider, Typography, createTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface ArgsType {
    text: String
    component: JSX.Element;
    defaultValue?: Boolean
}

export default function ButtonModal({ component, text, defaultValue }: Readonly<ArgsType>): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (typeof defaultValue == "boolean") {
            setOpen(defaultValue)
        }
    }, [defaultValue])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Button style={{ paddingInline: '15px' }} color="primary" size='small' onClick={handleClickOpen}>
                <Typography style={{ fontSize: '14px', textTransform: 'capitalize' }}>{text}</Typography>
            </Button>
            <Dialog
                open={open}
                onClose={() => handleClose()}
            >
                {component}
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}