import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddCompanyDocument, DeleteCompanyDocument, Company, CompaniesDocument, UpdateCompanyDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function CompanyListScreen() {
    const [query, { loading }] = useLazyQuery(CompaniesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddCompanyDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateCompanyDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteCompanyDocument);

    const columns: GridColDef<Company>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'short_name', headerName: 'Short Name', flex: 1 },
        { field: 'owner', headerName: 'Owner', flex: 1, valueGetter: ({ row }: { row: Company }) => `${row?.Owner?.first_name} ${row?.Owner?.last_name}` },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'city', headerName: 'City', minWidth: 200, valueGetter: ({ row }: { row: Company }) => `${row?.Address?.city}` },
        { field: 'state', headerName: 'State', minWidth: 100, valueGetter: ({ row }: { row: Company }) => `${row?.Address?.state}` }
    ];

    function formatParams(args: Company) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"company"} pluralQueryName={"companies"} formatParams={formatParams} form={Form} title={"Companies"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}