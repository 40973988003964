import { DialogTitle, Dialog, DialogContent, DialogActions, Button, DialogContentText, Grid, Typography, Paper, IconButton, Snackbar, CircularProgress, Box, FormControlLabel, Checkbox } from "@mui/material";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import VoicemailPrice from "./VoicemailPrice";
import Confirm from "./Confirm";
import CloseIcon from '@mui/icons-material/Close';
import { useUploadLeadAudiosMutation } from "../gql/types.graphql";
import { GridRowId } from "@mui/x-data-grid";

interface ArgsType {
    onClose: Function
    open: boolean
    rowSelectionModel: GridRowId[]
}

export default function VoicemailCreatorModal({ onClose, open, rowSelectionModel }: Readonly<ArgsType>): JSX.Element {
    const [mutationUploadLeadAudios, { loading, error, data }] = useUploadLeadAudiosMutation();
    const [next, setNext] = useState(false);
    const [startDial, setStartDial] = useState(true);
    const [pricing, setPricing] = useState({
        leads: 0,
        total_character_count: 0,
        total_price: 0,
        voicemails: {
            total: 0
        }
    });

    function mutationVoicemails(preview: boolean) {
        mutationUploadLeadAudios({
            variables: {
                leadIds: rowSelectionModel as number[],
                preview: preview,
                start_dial: startDial
            }
        }).then(response => {
            let data = response?.data?.uploadLeadAudios;
            if (data?.success) {
                if (data?.data) {
                    setPricing({
                        leads: data?.data?.leads as number,
                        total_character_count: data?.data?.total_character_count as number,
                        total_price: data?.data?.total_price as number,
                        voicemails: {
                            total: data?.data?.voicemails?.total as number
                        }
                    });
                }
                if (!preview) {
                    onClose({
                        msg: data?.msg,
                        toast: true
                    });
                }
            }
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        if (open) {
            mutationVoicemails(true);
        }
    }, [open]);

    const handleClose = () => {
        onClose()
    };


    return (
        <Dialog onClose={handleClose} open={open}>
            {(!next && !loading) && <DialogTitle>Estimated Price</DialogTitle>}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent style={{ width: 500 }}>
                {!next && <VoicemailPrice loading={loading} description='*Estimated price to generate voicemails.' {...pricing}></VoicemailPrice>}
                {next && (loading ? <Box sx={{ display: "flex", width: "100%" }}><CircularProgress style={{ marginInline: "auto", marginTop: "30px", marginBottom: "10px" }}></CircularProgress></Box> : <Confirm></Confirm>)}
                {(!next && !loading) && <FormControlLabel value={startDial} /*onChange={(val) => setStartDial(val.target.checked)}*/ control={<Checkbox checked={startDial} />} label="Start Dial - Sent accounts to vicidial" />}
            </DialogContent>
            <DialogActions>
                {(next && !loading) && <Button onClick={() => setNext(false)}>Back</Button>}
                {(!loading) && <Button color={!next ? 'primary' : 'warning'} onClick={() => !next ? setNext(true) : mutationVoicemails(false)}>{!next ? 'Next' : 'Agree'}</Button>}
            </DialogActions>
        </Dialog>
    )
}