import { CloudUpload, Delete, Download, FileUpload } from "@mui/icons-material";
import { Box, Button, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { InputLeadFile, LeadFile } from "../../../../../gql/types.graphql";
import { useEffect, useState } from "react";
import { LoadingButton } from '@mui/lab';
import { VisuallyHiddenInput } from "../../../settings/portfolio/portfolio-import-builder";

export default function AddLeadFile({ defaultValue, onSubmit, onDelete, loading: loadingValue = false }: Readonly<{ defaultValue?: LeadFile, onSubmit: (args: InputLeadFile) => void; onDelete?: (args: LeadFile) => void; loading?: boolean }>): JSX.Element {
    const [leadFile, setLeadFile] = useState<LeadFile>({
        File: {
            name: ''
        }
    });

    const [file, setFile] = useState<React.SetStateAction<File | undefined>>();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(loadingValue);
    }, [loadingValue])

    useEffect(() => {
        if (defaultValue?.external_id) {
            setLeadFile(defaultValue)
        }
    }, [defaultValue]);


    const openInNewTab = (url: string) => {
        const win = window.open(url, '_blank');
        win?.focus();
    }

    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            setFile(event.target.files[0]);
        }
    }

    return (
        <Box style={{ width: '100%' }} component={'form'} onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            //onSubmit(leadFile)
        }} >
            <ListItem style={{ paddingBottom: '10px' }} key={'list-item-' + leadFile.external_id} disablePadding>
                <ListItemText
                    primary={<>
                        <Typography style={{ fontSize: '14px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{leadFile?.external_id ? leadFile?.File?.name : file?.name}</Typography>
                        <Typography style={{ fontSize: '10px' }}>{leadFile?.File?.FileType?.name}</Typography>
                        {!onDelete && <Button
                            fullWidth
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUpload />}
                        >
                            Upload file
                            <VisuallyHiddenInput onChange={handleCapture} type="file" />
                        </Button>}
                    </>}
                />
                {!defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }} aria-label="delete">
                    <LoadingButton onClick={() => onSubmit({
                        file: file
                    })} disabled={!file} loading={loading} type="submit" size="large" variant="outlined" style={{ margin: 'auto' }} color="secondary"><FileUpload /></LoadingButton>
                </ListItemIcon>}
                {defaultValue?.external_id && <ListItemIcon onClick={() => openInNewTab(defaultValue?.File?.link!)} style={{ display: 'flex', alignItems: 'center', marginInline: '10px' }} aria-label="delete">
                    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" style={{ margin: 'auto' }} color="success"><Download /></LoadingButton>
                </ListItemIcon>}
                {defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center' }} aria-label="delete">
                    <LoadingButton size="large" variant="outlined" onClick={() => onDelete && onDelete(leadFile)} style={{ margin: 'auto' }} color="error"><Delete /></LoadingButton>
                </ListItemIcon>}
            </ListItem>
        </Box>
    );
}