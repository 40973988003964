import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddTemplateDocument, DeleteTemplateDocument, Template, TemplatesDocument, UpdateTemplateDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

interface InputTemplate {
    template_type_id: string
    template_type_fields_id: string
    name: string
}

export default function TemplateScreen() {
    const [query, { loading }] = useLazyQuery(TemplatesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddTemplateDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateTemplateDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteTemplateDocument);

    const columns: GridColDef<Template>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 }
    ];

    function formatParams(args: InputTemplate) {
        console.log('dats', args)
        return {
            template_type_id: args.template_type_id,
            template_type_fields_id: args.template_type_fields_id.split(','),
            name: args.name
        };
    }

    return (<DataGridCRUD singularQueryName={"template"} pluralQueryName={"templates"} formatParams={formatParams} form={Form} title={"Templates"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}