import { Box, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { Lead, LeadStatus } from "../../../../../gql/types.graphql";
import { leadBalance, leadFeeBalance, leadInterestBalance, leadPrincipalBalance, leadSimilarAccounts } from "../../../../../store/reducers/lead";
import { useAppSelector } from "../../../../../store/hooks";
import { Edit } from "@mui/icons-material";

export default function SimilarAccountTable(): JSX.Element {
    const similarAccounts = useAppSelector(leadSimilarAccounts);
    const principalBalance = useAppSelector(leadPrincipalBalance);
    const balance = useAppSelector(leadBalance);
    const feeBalance = useAppSelector(leadFeeBalance);
    const interestBalance = useAppSelector(leadInterestBalance);
    
    const columns: GridColDef[] = [
        {
            field: 'name', headerName: 'Name',
            width: 150,
            renderCell: ({ row }: { row: Lead }) => {
                return <Typography component={'span'} textTransform={'capitalize'}>{row?.first_name} {row?.last_name}</Typography>
            }
        },
        {
            field: 'LeadStatus', headerName: 'Status', valueGetter: (value: LeadStatus, row: Lead) => {
                return `${value?.name}`;
            }, flex: 1
        },
        {
            field: 'service_date', headerName: 'Service Date', valueGetter: (value: LeadStatus, row: Lead) => {
                return `${row?.service_date || ""}`;
            }, flex: 1
        },
        {
            field: 'principal_balance', headerName: 'Princ Bal', valueGetter: (value: string, row: Lead) => {
                return `$${row?.LeadBalance?.principal_balance}`;
            },
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <Tooltip title="Principal Balance">
                        <Typography textAlign={"start"} fontWeight={'bold'}>
                            <Typography fontSize={'12px'}>{params.colDef.headerName}</Typography>
                            <Typography lineHeight={'14px'} fontWeight={'bold'} fontSize={'15px'}>${principalBalance}</Typography>
                        </Typography>
                    </Tooltip>
                )
            }
        },
        {
            field: 'interest_balance', headerName: 'Int Bal', valueGetter: (value: string, row: Lead) => {
                return `$${row?.LeadBalance?.interest_balance}`;
            },
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <Tooltip title="Interest Balance">
                        <Typography textAlign={"start"} fontWeight={'bold'}>
                            <Typography fontSize={'12px'}>{params.colDef.headerName}</Typography>
                            <Typography lineHeight={'14px'} fontWeight={'bold'} fontSize={'15px'}>${interestBalance}</Typography>
                        </Typography>
                    </Tooltip>
                )
            }
        },
        {
            field: 'fee_balance', headerName: 'Fee Bal', valueGetter: (value: string, row: Lead) => {
                return `$${row?.LeadBalance?.service_fee_balance}`;
            },
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <Tooltip title="Fee Balance">
                        <Typography textAlign={"start"} fontWeight={'bold'}>
                            <Typography fontSize={'12px'}>{params.colDef.headerName}</Typography>
                            <Typography lineHeight={'14px'} fontWeight={'bold'} fontSize={'15px'}>${feeBalance}</Typography>
                        </Typography>
                    </Tooltip>
                )
            }
        },
        {
            field: 'current_balance', headerName: 'Cur Bal', valueGetter: (value: string, row: Lead) => {
                return `$${row?.LeadBalance?.balance}`;
            },
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <Tooltip title="Current Balance">
                        <Typography textAlign={"start"} fontWeight={'bold'}>
                            <Typography fontSize={'12px'}>{params.colDef.headerName}</Typography>
                            <Typography lineHeight={'14px'} fontWeight={'bold'} fontSize={'15px'}>${balance}</Typography>
                        </Typography>
                    </Tooltip>
                )
            }
        },
        {
            field: 'edit',
            headerName: 'Edit',
            flex: 1,
            renderCell: ({ row }: { row: Lead }) => {
                return (
                    <Box component={'div'}>
                        <IconButton style={{ marginBottom: '3px' }} size="small"><Edit fontSize="small"></Edit></IconButton>
                    </Box>
                )
            }
        }
    ]
    return (<Card>
        <CardHeader action={<></>} avatar={<Typography fontSize={'20px'} fontWeight={600}>Accounts Details Cross-Ref</Typography>}></CardHeader>
        <CardContent style={{ paddingTop: '0px' }}>
            <DataGridPro
                density="compact"
                rows={similarAccounts as Lead[] ?? []}
                columns={columns}
                style={{ height: '233px' }}
                rowCount={similarAccounts?.length ?? 0}
            />
        </CardContent>
    </Card>
    )
}