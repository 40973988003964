import { Box, Typography } from "@mui/material";

export default function Confirm(): JSX.Element {
    return (
        <Box sx={{
            textAlign: "center",
            marginTop: 3
        }}>
            <Typography variant="h5">Are you sure?</Typography>
            <Typography variant="subtitle1">The process for creating voicemails will be generated.</Typography>
        </Box>
    );
}