import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { red } from "@mui/material/colors";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { lead, updateLeadInformation } from "../../../../../store/reducers/lead";
import { Lead, LeadStatus, LeadStatusesDocument, User, UsersDocument, useUpdateLeadInfoMutation, Worklist, WorklistsDocument } from "../../../../../gql/types.graphql";
import AutocompleteSelect from "../../../../../components/AutocompleteSelect";

export default function LeadInfo(): JSX.Element {
    const leadSelector = useAppSelector(lead);
    const [leadInfo, setLeadInfo] = useState<Lead>({});
    const [queryUsers, { loading }] = useLazyQuery(UsersDocument);
    const [queryWorklists, { loading: loadingWorklist }] = useLazyQuery(WorklistsDocument);
    const [queryLeadStatuses, { loading: loadingLeadStatuses }] = useLazyQuery(LeadStatusesDocument);
    const [updateLeadInfoMutation, { loading: loadingUpdateLeadInfo }] = useUpdateLeadInfoMutation();
    const dispatch = useAppDispatch();

    function formatUserParams(args: User): string {
        return `${args.first_name} ${args.last_name}`;
    }

    function formatWorklistParams(args: Worklist): string {
        return `${args.name}`;
    }

    function formatLeadStatusParams(args: LeadStatus): string {
        return `${args.name}`;
    }

    function updateLeadInfo() {
        updateLeadInfoMutation({
            variables: {
                external_id: leadSelector?.external_id!,
                bca: leadInfo.LeadSplit?.bca,
                closer_id: leadInfo.LeadSplit?.closer_id,
                four_way_id: leadInfo.LeadSplit?.four_way_id,
                free_closer: leadInfo.LeadSplit?.free_closer,
                lead_status_id: leadInfo.lead_status_id,
                three_way_id: leadInfo.LeadSplit?.three_way_id,
                user_id: leadInfo.LeadSplit?.user_id,
                warning: leadInfo.warning,
                worklist_id: leadInfo.LeadSplit?.worklist_id,
            }
        }).then(response => {
            const leadInfo = response.data?.updateLeadInfo;
            if (leadInfo) {
                dispatch(updateLeadInformation(leadInfo))
            }
        })
    }

    return (
        <Grid onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            updateLeadInfo();
        }} component={'form'} spacing={2} container>
            <Grid item sm={4}>
                <FormControlLabel name="bca" id="bca" control={<Checkbox defaultChecked={leadSelector?.LeadSplit?.bca!} onChange={(value) => setLeadInfo({
                    ...leadInfo,
                    LeadSplit: {
                        ...leadInfo.LeadSplit,
                        bca: value.target.checked
                    }
                })} />} componentsProps={{
                    typography: {
                        fontSize: '13px'
                    }
                }} label="BCA" />
            </Grid>
            <Grid item sm={4}>
                <FormControlLabel name="free_closer" id="free_closer" control={<Checkbox defaultChecked={leadSelector?.LeadSplit?.free_closer!} onChange={(value) => setLeadInfo({
                    ...leadInfo,
                    LeadSplit: {
                        ...leadInfo.LeadSplit,
                        free_closer: value.target.checked
                    }
                })} />} label="Free Closer" componentsProps={{
                    typography: {
                        fontSize: '13px'
                    }
                }} />
            </Grid>
            <Grid item sm={4}>
                <LoadingButton loading={loadingUpdateLeadInfo} type="submit" variant="contained" color="success" fullWidth>Save</LoadingButton>
            </Grid>
            <Grid style={{ paddingTop: '0px' }} item sm={4}>
                <AutocompleteSelect required={false} onChange={(value: User) => setLeadInfo({
                    ...leadInfo,
                    LeadSplit: {
                        ...leadInfo.LeadSplit,
                        user_id: value?.external_id || null
                    }
                })} defaultValue={leadSelector?.LeadSplit?.User} name={'user_id'} formatParams={formatUserParams} queryName={"users"} query={queryUsers} label="User" id="user_id"></AutocompleteSelect>

                <AutocompleteSelect required={false} onChange={(value: Worklist) => setLeadInfo({
                    ...leadInfo,
                    LeadSplit: {
                        ...leadInfo.LeadSplit,
                        worklist_id: value?.external_id || null
                    }
                })} defaultValue={leadSelector?.LeadSplit?.Worklist} name={'worklist_id'} formatParams={formatWorklistParams} queryName={"worklists"} query={queryWorklists} label="Worklist" id="worklist_id"></AutocompleteSelect>

                <AutocompleteSelect onChange={(value: LeadStatus) => setLeadInfo({
                    ...leadInfo,
                    lead_status_id: value?.external_id || null
                })} defaultValue={leadSelector?.LeadStatus} name={'lead_status_id'} formatParams={formatLeadStatusParams} queryName={"leadStatuses"} query={queryLeadStatuses} label="Lead Status" id="lead_status_id"></AutocompleteSelect>

            </Grid>
            <Grid style={{ paddingTop: '0px' }} item sm={4}>
                <AutocompleteSelect required={false} onChange={(value: User) => setLeadInfo({
                    ...leadInfo,
                    LeadSplit: {
                        ...leadInfo.LeadSplit,
                        closer_id: value?.external_id || null
                    }
                })} defaultValue={leadSelector?.LeadSplit?.Closer} name={'closer_id'} formatParams={formatUserParams} queryName={"users"} query={queryUsers} label="Closer" id="closer_id"></AutocompleteSelect>
                <AutocompleteSelect required={false} onChange={(value: User) => setLeadInfo({
                    ...leadInfo,
                    LeadSplit: {
                        ...leadInfo.LeadSplit,
                        three_way_id: value?.external_id || null
                    }
                })} defaultValue={leadSelector?.LeadSplit?.ThreeWay} name={'three_way_id'} formatParams={formatUserParams} queryName={"users"} query={queryUsers} label="Three Way" id="three_way_id"></AutocompleteSelect>
                <AutocompleteSelect required={false} onChange={(value: User) => setLeadInfo({
                    ...leadInfo,
                    LeadSplit: {
                        ...leadInfo.LeadSplit,
                        four_way_id: value?.external_id || null
                    }
                })} defaultValue={leadSelector?.LeadSplit?.FourWay} name={'four_way_id'} formatParams={formatUserParams} queryName={"users"} query={queryUsers} label="Four Way" id="four_way_id"></AutocompleteSelect>
            </Grid>
            <Grid item sm={4}>
                <TextField
                    fullWidth
                    variant='standard'
                    id="outlined-multiline-flexible"
                    label="Warning"
                    defaultValue={leadSelector?.warning}
                    onChange={(event) => setLeadInfo({
                        ...leadInfo,
                        warning: event.target.value
                    })}
                    focused
                    multiline
                    color='error'
                    inputProps={{
                        style: {
                            color: red[700]
                        }
                    }}
                    maxRows={6}
                    minRows={6}
                />
            </Grid>
        </Grid>
    )
}