import { DialogContent, Grid, TextField } from "@mui/material";
import { Script } from "../../../../gql/types.graphql";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { Editor as TinyMCEEditor } from 'tinymce';

export default function Form({ defaultValue }: Readonly<{ defaultValue: Script }>) {
    const editorRef = useRef<any>(null);
    const [script, setScript] = useState(defaultValue?.script ?? "");

    const handleEditorChange = (html: string, editor: TinyMCEEditor) => {
        setScript(html)
    }

    return (<DialogContent style={{ width: '600px' }}>
        <Grid spacing={2} container>
            <Grid item sm={6}>
                <TextField
                    autoFocus
                    required
                    defaultValue={defaultValue?.name}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item sm={6}>
                <TextField
                    id="description"
                    autoFocus
                    defaultValue={defaultValue?.description}
                    margin="dense"
                    name="description"
                    label="Description"
                    variant="standard"
                    type="text"
                    fullWidth
                />
            </Grid>
            <Grid item sm={12}>
                <TextField
                    id={'script'}
                    required
                    style={{
                        position: 'relative',
                        width: "0px",
                        height: "0px",
                        top: "100px",
                        right: "-50%",
                        overflow: "hidden",
                        pointerEvents: "none"
                    }}
                    name={'script'}
                    value={script}
                    type="text"
                />
                <Editor
                    textareaName="script"
                    onInit={(_evt, editor) => editorRef.current = editor}
                    apiKey={process.env.REACT_APP_EDITOR_TOKEN}
                    value={script}
                    init={{
                        plugins: 'preview fullscreen autolink emoticons lists searchreplace wordcount checklist formatpainter permanentpen powerpaste advcode mentions mergetags autocorrect typography markdown',
                        toolbar: 'preview fullscreen forecolor backcolor fontsize bold italic underline mergetags | blocks fontfamily | strikethrough | spellcheckdialog typography | align lineheight | checklist numlist indent outdent | emoticons charmap | removeformat',
                        mergetags_list: [
                            { value: 'LeadName.first_name', title: 'Lead - First Name' },
                            { value: 'LeadName.last_name', title: 'Lead - Last Name' },
                            { value: 'LeadName.full_name', title: 'Lead - Full Name' },
                            { value: 'LeadAddress.full_address', title: 'Lead - Full Address' },
                            { value: 'LeadAddress.addresss', title: 'Lead - Address' },
                            { value: 'LeadAddress.city', title: 'Lead - City' },
                            { value: 'LeadAddress.state', title: 'Lead - State' },
                            { value: 'LeadAddress.zipcode', title: 'Lead - ZipCode' },
                            { value: 'StatNumber.phone', title: 'Lead - Stat Number' },
                            { value: 'id', title: 'Lead - ID' },
                            { value: 'CompanyUser.alias', title: 'User - Alias' },
                            { value: 'CompanyUser.first_name', title: 'User - First Name' },
                            { value: 'CompanyUser.last_name', title: 'User - Last Name' },
                            { value: 'CompanyUser.full_name', title: 'User - Full Name' },
                        ]
                    }}
                    initialValue={defaultValue?.script ?? ""}
                    onEditorChange={handleEditorChange}
                />
            </Grid>
        </Grid>
    </DialogContent>)
}