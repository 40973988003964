import { DialogContent, TextField } from "@mui/material";
import { EmailTemplate } from "../../../../../gql/types.graphql";
export default function EditForm({ defaultValue }: Readonly<{ defaultValue: EmailTemplate }>) {

    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.subject}
            margin="dense"
            id="subject"
            name="subject"
            label="Subject"
            type="text"
            fullWidth
            variant="standard"
            multiline
            minRows={3}
            maxRows={3}
        />
        <TextField
            id="preview"
            autoFocus
            defaultValue={defaultValue?.preview}
            name="preview"
            label="Preview"
            variant="standard"
            type="text"
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
        />
    </DialogContent>)
}