import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddSegmentOperatorDocument, DeleteSegmentOperatorDocument, SegmentOperator, SegmentOperatorsDocument, UpdateSegmentOperatorDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function SegmentOperatorScreen() {
    const [query, { loading }] = useLazyQuery(SegmentOperatorsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddSegmentOperatorDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateSegmentOperatorDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteSegmentOperatorDocument);

    const columns: GridColDef<SegmentOperator>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'operator', headerName: 'Operator', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 }
    ];

    function formatParams(args: SegmentOperator) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"segmentOperator"} pluralQueryName={"segmentOperators"} formatParams={formatParams} form={Form} title={"Segment Operators"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}