import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink, concat, } from '@apollo/client';
import { Button, ThemeProvider, createTheme } from '@mui/material';
import { PUBLIC_ROUTE } from '../routes/publicRoute';
import { RouterProvider } from 'react-router-dom';
import { PRIVATE_ROUTE } from "../routes/privateRoute";
import { useAppSelector } from "../store/hooks";
import { user } from "../store/reducers/user";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { PRIVATE_COMPANY_ROUTE } from '../routes/privateCompanyRoute';

const AppScreen = () => {
    const userSelector = useAppSelector(user);
    const httpLink = createUploadLink({ uri: process.env.REACT_APP_BACKEND_URL });

    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                authorization: userSelector?.userToken ? `${userSelector.userToken}` : "",
                'Apollo-Require-Preflight': 'true'
            },
        });
        return forward(operation).map(response => {
            let error = null;
            error = response?.errors
            if (error && error.length > 0) {
                enqueueSnackbar(error[0].message, {
                    variant: "error",
                    action: <Button style={{ color: "white" }} variant='text' onClick={() => closeSnackbar()}>Close</Button>
                });
            }
            return response;
        });
    });

    const client = new ApolloClient({
        link: concat(authMiddleware, httpLink),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            }
        }
    });

    const defaultTheme = createTheme({
        palette: {
            background: {
                default: '#f9f9f9'
            },
            primary: {
                main: "#0169ff",
            },
            secondary: {
                main: '#000000'
            },
            success: {
                main: '#137c44'
            },
            info: {
                main: 'color(display-p3 0.3804 0.8314 0.8196)'
            }
        }
    });

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={defaultTheme}>
                <SnackbarProvider maxSnack={3}>
                    <RouterProvider router={userSelector.userToken == null ? PUBLIC_ROUTE : userSelector.companyUser ? PRIVATE_ROUTE : PRIVATE_COMPANY_ROUTE}></RouterProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default AppScreen;