import { Box, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

export default function LeadBalance(): JSX.Element {
    return (<>
        <DialogTitle>Account Details</DialogTitle>
        <DialogContent>
            <Grid spacing={2} width={"500px"} container>
                <Grid md={4} item>
                    <TextField type="text" label={"First Name"} variant='standard' fullWidth />
                </Grid>
                <Grid md={4} item>
                    <TextField type="text" label={"Last Name"} variant='standard' fullWidth />
                </Grid>
                <Grid md={4} item>
                    <TextField type="text" label={"Foreign #"} variant='standard' fullWidth />
                </Grid>
                <Grid md={4} item>
                    <TextField type="text" label={"First Deliquency"} variant='standard' fullWidth />
                </Grid>
                <Grid md={4} item>
                    <TextField type="text" label={"VIN"} variant='standard' fullWidth />
                </Grid>
                <Grid md={4} item>
                    <TextField type="text" label={"Account"} variant='standard' fullWidth />
                </Grid>
                <Grid md={4} item>
                    <TextField type="text" label={"Clt Ref No"} variant='standard' fullWidth />
                </Grid>
                <Grid md={4} item>
                    <TextField type="number" label={"Interest Rate"} variant='standard' fullWidth />
                </Grid>
                <Grid md={4} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true
                            }
                        }} label="Created At" />
                    </LocalizationProvider>
                </Grid>
                <Grid md={4} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true
                            }
                        }} label="DOB" />
                    </LocalizationProvider>
                </Grid>
                <Grid md={4} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true
                            }
                        }} label="Last Pmt Date" />
                    </LocalizationProvider>
                </Grid>
                <Grid md={4} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true
                            }
                        }} label="List Date" />
                    </LocalizationProvider>
                </Grid>
                <Grid md={4} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true
                            }
                        }} label="Int Calc Date" />
                    </LocalizationProvider>
                </Grid>
                <Grid md={4} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true
                            }
                        }} label="Statute Date" />
                    </LocalizationProvider>
                </Grid>
                <Grid md={4} item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true
                            }
                        }} label="Service Date" />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </DialogContent>
    </>)
}