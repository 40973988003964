import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { CSSObject, Theme, alpha, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Badge, Menu, MenuItem, MenuList, MenuProps, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { removeCompanyUser, signOut, user } from "../../store/reducers/user";
import { RecordVoiceOver, Send, Settings, SupportAgent, Tune } from "@mui/icons-material";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginLeft: '60px',
        marginTop: '-40px',
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export interface MenuSelect {
    [x: number]: {
        open: boolean,
        event: null | HTMLElement
    };
}


export default function Home() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string>('');
    const navigate = useNavigate();
    const userSelector = useAppSelector(user);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname == '' || location.pathname == '/') {
            if (userSelector?.companyUser?.Permission?.name == "Marketing") {
                navigate('marketing/dashboard')
            } else {
                navigate('voice/dashboard')
            }
        }
    }, [location]);

    const handleChange = (panel: string) => (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : '');
    };

    const [openMenu, setOpenMenu] = useState<MenuSelect>();

    const toogleMenu = (index: number, event: React.MouseEvent<HTMLElement> | null) => {
        let menu: MenuSelect = {
            ...openMenu
        }
        menu[index] = {
            open: menu[index]?.open ? !menu[index].open : true,
            event: event?.currentTarget ? event?.currentTarget : null
        };
        setOpenMenu(menu);
    };

    const menus = userSelector?.companyUser?.Permission?.name == "Marketing" ? [{
        title: 'Marketing',
        icon: <Send></Send>,
        submenus: [{
            title: "Dashboard",
            onClick: () => navigate("marketing/dashboard")
        }, {
            title: "Customers",
            onClick: () => navigate("marketing/customers")
        }, {
            title: "Email Templates",
            onClick: () => navigate("marketing/email-templates/list")
        }, {
            title: "Segments",
            onClick: () => navigate("marketing/segments/list")
        }, {
            title: "Identities",
            onClick: () => navigate("marketing/identities")
        }, {
            title: "Phones",
            onClick: () => navigate("marketing/phones")
        }]
    }, {
        title: "Account",
        icon: <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
        >
            <SmallAvatar alt={`${userSelector.user?.first_name} ${userSelector.user?.last_name}`} src="/static/images/avatar/1.jpg" />
        </StyledBadge>,
        submenus: [{
            title: 'Change Company',
            onClick: () => {
                navigate('/')
                dispatch(removeCompanyUser())
            }
        }, {
            title: "Log Out",
            onClick: () => {
                navigate('/')
                dispatch(signOut());
            }
        }]
    }] : [{
        title: "Agent",
        icon: <SupportAgent></SupportAgent>,
        submenus: [{
            title: "Board",
            onClick: () => navigate("agent/board")
        }]
    }, {
        title: "Voice",
        icon: <RecordVoiceOver></RecordVoiceOver>,
        submenus: [{
            title: "Dashboard",
            onClick: () => navigate("voice/dashboard")
        }, {
            title: "Voicemails",
            onClick: () => navigate("voice/voicemails")
        }, {
            title: "Voices",
            onClick: () => navigate("voice/creator")
        }]
    }, {
        title: "Settings",
        icon: <Settings></Settings>,
        submenus: [{
            title: "Users",
            onClick: () => navigate("settings/users")
        }, {
            title: "Branches",
            onClick: () => navigate("settings/branches")
        }, {
            title: "Portfolios",
            onClick: () => navigate("settings/portfolios")
        }, {
            title: "Portfolio Types",
            onClick: () => navigate("settings/portfolio-types")
        }, {
            title: "Subaccounts",
            onClick: () => navigate("settings/subaccounts")
        }, {
            title: "Product Types",
            onClick: () => navigate("settings/product-types")
        }, {
            title: "Stat Numbers",
            onClick: () => navigate("settings/stat-numbers")
        }, {
            title: "Contracts",
            onClick: () => navigate("settings/contracts")
        }, {
            title: "Vendor Types",
            onClick: () => navigate("settings/vendor-types")
        }, {
            title: "Worklists",
            onClick: () => navigate("settings/worklists")
        }, {
            title: "Vendors",
            onClick: () => navigate("settings/vendors")
        }, {
            title: "Debt Types",
            onClick: () => navigate("settings/debt-types")
        }, {
            title: "Lead Statuses",
            onClick: () => navigate("settings/lead-statuses")
        }, {
            title: "Original Clients",
            onClick: () => navigate("settings/original-clients")
        }, {
            title: "Scripts",
            onClick: () => navigate("settings/scripts")
        }, {
            title: "Permissions",
            onClick: () => navigate("settings/permissions")
        }],
    }, {
        title: 'Marketing',
        icon: <Send></Send>,
        submenus: [{
            title: "Dashboard",
            onClick: () => navigate("marketing/dashboard")
        }, {
            title: "Email Templates",
            onClick: () => navigate("marketing/email-templates/list")
        }, {
            title: "Segments",
            onClick: () => navigate("marketing/segments/list")
        }, {
            title: "Identities",
            onClick: () => navigate("marketing/identities")
        }, {
            title: "Phones",
            onClick: () => navigate("marketing/phones")
        }]
    }, {
        title: 'System',
        icon: <Tune></Tune>,
        submenus: [{
            title: "Companies",
            onClick: () => navigate("system/companies")
        }, {
            title: "Templates",
            onClick: () => navigate("system/templates")
        }, {
            title: "File Types",
            onClick: () => navigate("system/file-types")
        }, {
            title: "Template Types",
            onClick: () => navigate("system/template-types")
        }, {
            title: "Payment Status Codes",
            onClick: () => navigate("system/payment-status-codes")
        }, {
            title: "Template Type Fields",
            onClick: () => navigate("system/template-type-fields")
        }, {
            title: "Users",
            onClick: () => navigate("system/users")
        }, {
            title: "Phone Types",
            onClick: () => navigate("system/phone-types")
        }, {
            title: "Activity Types",
            onClick: () => navigate("system/activity-types")
        }, {
            title: "Bank Routings",
            onClick: () => navigate("system/bank-routings")
        }, {
            title: "Disposition Codes",
            onClick: () => navigate("system/disposition-codes")
        }, {
            title: "Email Templates",
            onClick: () => navigate("system/email-templates/list")
        }, {
            title: "Segment Inputs",
            onClick: () => navigate("system/segment-inputs")
        }, {
            title: "Segment Operators",
            onClick: () => navigate("system/segment-operators")
        }, {
            title: "Segment Categories",
            onClick: () => navigate("system/segment-categories")
        }, {
            title: "Segment Selects",
            onClick: () => navigate("system/segment-selects")
        }, {
            title: "Segment Filters",
            onClick: () => navigate("system/segment-filters")
        }]
    }, {
        title: "Account",
        icon: <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
        >
            <SmallAvatar alt={`${userSelector.user?.first_name} ${userSelector.user?.last_name}`} src="/static/images/avatar/1.jpg" />
        </StyledBadge>,
        submenus: [{
            title: 'Change Company',
            onClick: () => {
                navigate('/')
                dispatch(removeCompanyUser())
            }
        }, {
            title: "Log Out",
            onClick: () => {
                navigate('/')
                dispatch(signOut());
            }
        }]
    }];

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton style={{ marginRight: "3px" }} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {open ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List disablePadding>
                    {menus.map((menu, index) => (
                        <Accordion key={index} elevation={0} expanded={open ? expanded === `${index}` : false} onChange={handleChange(`${index}`)}>
                            <AccordionSummary
                                style={{ width: "100%" }}
                                sx={{ margin: "0px", padding: "0px" }}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <ListItem key={menu.title} disablePadding sx={{ display: 'block', margin: "0px" }}>
                                    <Tooltip placement="right" title={menu.title}>
                                        <ListItemButton
                                            onClick={(event) => !open && toogleMenu(index, event)}
                                            aria-controls={openMenu ? 'account-menu-' + index : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openMenu ? 'true' : undefined}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                    color: '#00000090'
                                                }}
                                            >
                                                {menu.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={menu.title} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </Tooltip>
                                </ListItem>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px" }}>
                                {menu.submenus.map(submenu => (<ListItem key={submenu.title} disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton onClick={submenu.onClick}>
                                        <ListItemText style={{ color: 'black' }} primary={submenu.title} />
                                    </ListItemButton>
                                </ListItem>))}
                            </AccordionDetails>
                            <StyledMenu
                                anchorEl={openMenu?.[index] ? openMenu[index]?.event : null}
                                id={'account-menu-' + index}
                                open={openMenu?.[index] ? openMenu[index]?.open : false}
                                onClose={() => toogleMenu(index, null)}
                                onClick={() => toogleMenu(index, null)}
                                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            >
                                <MenuList>
                                    {menu.submenus.map((submenu, index) => {
                                        return (<MenuItem key={'menu-list-' + index} onClick={submenu.onClick}>
                                            {submenu.title}
                                        </MenuItem>)
                                    })}
                                </MenuList>
                            </StyledMenu>
                        </Accordion>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Outlet></Outlet>
            </Box>
        </Box>)
}