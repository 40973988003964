import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Divider, Grid, IconButton, Typography, colors } from "@mui/material";
import { DataGrid, GridValidRowModel } from "@mui/x-data-grid";
import dayjs from 'dayjs';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import BasicHeader from "../../../../components/BasicHeader";
import { VoiceMail, VoiceMailChart, VoiceMailDashboard, VoiceMailResult, VoicemailCount, useVoicemailResultsLazyQuery, useVoicemailsLazyQuery } from "../../../../gql/types.graphql";
import { LineChart } from "@mui/x-charts";
import { Search } from "@mui/icons-material";

export default function VoiceDashboardScreen() {
    const [queryVoicemails, { loading, error, data, refetch }] = useVoicemailsLazyQuery();
    const [queryVoicemailResults, { loading: loadingResults }] = useVoicemailResultsLazyQuery();

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [rangeDateModel, setRangeDateModel] = useState([dayjs().subtract(2, "week"), dayjs()]);
    const [dashboard, setDashboard] = useState<VoiceMailDashboard>({
        total_character_count: 0,
        total_price: 0,
        leads: 0,
        voicemails: {
            total: 0,
            pending: 0,
            completed: 0
        },
    });

    const [voicemailChart, setVoicemailChart] = useState<VoiceMailChart>({
        counts: [],
        labels: []
    })

    const [results, setResults] = useState({
        call_backs: 0,
        earnings: 0,
        statuses: {
            OTR: 0,
            PPA: 0,
            PIF: 0
        }
    });

    const columns = [
        { field: 'id', headerName: 'ID', width: 130 },
        { field: 'foreign_id', headerName: 'Lead ID', width: 130 },
        { field: 'total_character_count', headerName: 'Characters', width: 130 },
        { field: 'text', headerName: 'Text', width: 400 },
        { field: 'total_price', headerName: 'Price', width: 100, valueGetter: ({ row }: { row: VoiceMailDashboard }) => `$${row.total_price!.toFixed(4) || ''}` },
        { field: 'status', headerName: 'Status', width: 120 },
    ]

    const reloadData = () => {
        fetchData();
        fetchResultsData()
    }

    const fetchData = () => {
        queryVoicemails({
            variables: {
                startDate: rangeDateModel[0],
                endDate: rangeDateModel[1],
                limit: paginationModel.pageSize,
                cursor: paginationModel.page
            }
        }).then(response => {
            let dash = response?.data?.voicemailsDashboard;
            if (dash) {
                setDashboard(dash);
            }
            if (response?.data?.voicemailChart) {
                setVoicemailChart(response?.data?.voicemailChart as VoiceMailChart)
            }
        }).catch(err => console.log(err.message))
    }

    const fetchResultsData = () => {
        queryVoicemailResults({
            variables: {
                startDate: rangeDateModel[0],
                endDate: rangeDateModel[1]
            }
        }).then(response => {
            let results = response?.data?.voicemailResults;
            if (results) {
                setResults({
                    call_backs: results.call_backs as number,
                    earnings: results.earnings as number,
                    statuses: {
                        OTR: results.statuses!.OTR as number,
                        PPA: results.statuses!.PPA as number,
                        PIF: results.statuses!.PIF as number
                    }
                });
            }
        }).catch(err => console.log(err.message))
    }

    /*useEffect(() => {
        fetchResultsData()
    }, []);*/

    useEffect(() => {
        fetchData()
    }, [paginationModel])

    return (<Box sx={{ flexGrow: 1 }}>
        <BasicHeader add={<Box sx={{ color: 'black', display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(val) => setRangeDateModel([val[0]!, val[1]!])}
                    defaultValue={[rangeDateModel[0], rangeDateModel[1]]}
                />
            </LocalizationProvider>
            <IconButton onClick={() => fetchData()} style={{ marginLeft: '10px' }} color="secondary"><Search></Search></IconButton>
        </Box>} text="Messages"></BasicHeader>
        <Grid sx={{ padding: "20px" }} container spacing={2}>
            <Grid item xs={2}>
                <Card style={{ backgroundColor: colors.orange["50"] }}>
                    <CardContent>
                        <Typography variant="h5">{dashboard?.total_character_count?.toLocaleString()}</Typography>
                        <Typography variant="body2">Characters</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <Card style={{ backgroundColor: colors.blue[50] }}>
                    <CardContent>
                        <Typography variant="h5">{dashboard?.voicemails?.total?.toLocaleString()}</Typography>
                        <Typography variant="body2">Voicemails</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <Card style={{ backgroundColor: colors.green[50] }}>
                    <CardContent>
                        <Typography variant="h5">{dashboard?.voicemails?.pending?.toLocaleString()}</Typography>
                        <Typography variant="body2">Pending</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <Card style={{ backgroundColor: colors.brown[50] }}>
                    <CardContent>
                        <Typography variant="h5">{dashboard?.voicemails?.completed?.toLocaleString()}</Typography>
                        <Typography variant="body2">Completed</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <Card style={{ backgroundColor: colors.amber[50] }}>
                    <CardContent>
                        <Typography variant="h5">{dashboard?.leads?.toLocaleString()}</Typography>
                        <Typography variant="body2">Leads</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <Card style={{ backgroundColor: colors.red[50] }}>
                    <CardContent>
                        <Typography variant="h5">${dashboard?.total_price?.toLocaleString()}</Typography>
                        <Typography variant="body2">Total Price</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title='Voicemail Usage' subheader='A snapshot of your voicemail usage, aggregated daily.'></CardHeader>
                    <CardContent>
                        <LineChart
                            xAxis={[
                                { scaleType: 'band', data: voicemailChart.labels! },
                            ]}
                            series={[{
                                data: voicemailChart.counts!,
                                label: 'Voicemails',
                                area: true,
                                color: '#8faeee'
                            }]}
                            sx={{
                                width: '100%'
                            }}
                            height={300}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title={'Voicemails'} subheader={'Speech Synthesis History'}></CardHeader>
                    <CardContent>
                        <DataGrid
                            loading={loading}
                            rows={data?.voicemails?.data as GridValidRowModel[] || []}
                            columns={columns}
                            rowCount={data?.voicemails?.totalCount || 0}
                            sx={{ height: "530px" }}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: paginationModel.page, pageSize: paginationModel.pageSize },
                                },
                            }}
                            pageSizeOptions={[10, 50, 100]}
                            paginationModel={paginationModel}
                            paginationMode="server"
                            onPaginationModelChange={setPaginationModel}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Box>)
}