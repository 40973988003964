import { AddCircle, Delete, Save } from "@mui/icons-material";
import { Box, ButtonGroup, Grid, IconButton, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { LeadName } from "../../../../../gql/types.graphql";
import { useEffect, useState } from "react";
import { LoadingButton } from '@mui/lab';

export default function AddLeadEmail({ defaultValue, onSubmit, onDelete, loading: loadingValue }: Readonly<{ defaultValue?: LeadName, onSubmit: (args: LeadName) => void; onDelete?: (args: LeadName) => void; loading: boolean }>): JSX.Element {
    const [name, setName] = useState<LeadName>({
        first_name: "",
        last_name: ""
    });

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(loadingValue);
    }, [loadingValue])

    useEffect(() => {
        if (defaultValue) {
            setName(defaultValue)
        }
    }, [defaultValue]);

    return (
        <Box component={'form'} onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit(name)
        }} >
            <ListItem key={'list-item-' + name.external_id} disablePadding>
                <ListItemText
                    style={{ marginRight: '5px' }}
                    primary={<TextField onChange={(event) => setName({
                        ...name,
                        first_name: event.target.value
                    })} required id="first_name" name="first_name" value={name.first_name} fullWidth size="small" label="First Name" variant="outlined" />}
                />
                <ListItemText
                    style={{ marginLeft: '5px' }}
                    primary={<TextField onChange={(event) => setName({
                        ...name,
                        last_name: event.target.value
                    })} required id="last_name" name="last_name" value={name.last_name} fullWidth size="small" label="Last Name" variant="outlined" />}
                />
                {!defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }} aria-label="delete">
                    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" style={{ margin: 'auto' }} color="secondary"><AddCircle /></LoadingButton>
                </ListItemIcon>}
                {defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center', marginInline: '10px' }} aria-label="delete">
                    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" style={{ margin: 'auto' }} color="success"><Save /></LoadingButton>
                </ListItemIcon>}
                {defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center' }} aria-label="delete">
                    <LoadingButton size="large" variant="outlined" onClick={() => onDelete && onDelete(name)} style={{ margin: 'auto' }} color="error"><Delete /></LoadingButton>
                </ListItemIcon>}
            </ListItem>
        </Box>
    );
}