import { Typography, Link, TypographyOwnProps } from "@mui/material";

export default function Copyright(props: Readonly<TypographyOwnProps>): JSX.Element {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link style={{
                color: "#000"
            }} href="https://mui.com/">

            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}