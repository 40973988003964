import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Box, Button, Card, CardContent, Checkbox, Chip, CircularProgress, CssBaseline, Divider, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Typography, colors } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Fragment, useEffect, useState } from "react";
//import { DataGrid, GridRowId } from '@mui/x-data-grid';
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowId } from "@mui/x-data-grid-pro";
import { DebitumLead, DebitumLeadResult, DebitumLeadStatus, DebitumLeadsDocument, DebitumPortfolio, DebitumPortfoliosStatusesUsersDocument, DebitumUser, MutationVicidialConfigDocument, Portfolio, QueryVicidialConfigDocument, UploadLeadAudiosDocument, User, useDebitumLeadsLazyQuery } from '../../../../gql/types.graphql'
import CloseIcon from '@mui/icons-material/Close';
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from 'dayjs';
import VoicemailCreatorModal from "../../../../components/VoicemailCreatorModal";
import BasicHeader from "../../../../components/BasicHeader";

export default function VoicemailCreatorScreen() {
    const [queryDebitumLeads, { loading, error, data, refetch }] = useDebitumLeadsLazyQuery();
    const [mutationUploadLeadAudios, { loading: loadingLeadAudios, error: errorLeadAudios }] = useMutation(UploadLeadAudiosDocument);
    const [mutationVoicemailConfig, { loading: loadingMVoicemailConfig, error: errorMVoicemailConfig }] = useMutation(MutationVicidialConfigDocument);
    const [queryVoicemailConfig, { loading: loadingQVoicemailConfig, error: errorQVoicemailConfig }] = useLazyQuery(QueryVicidialConfigDocument);
    const { loading: loadingParams, error: paramsError, data: paramsData } = useQuery(DebitumPortfoliosStatusesUsersDocument);
    const [portfolioId, setPorfolioId] = useState(null);
    const [rangeDateModel, setRangeDateModel] = useState([dayjs().subtract(2, "week"), dayjs()]);
    const [userId, setUserId] = useState<number | null>(null);

    const [leads, setLeads] = useState<DebitumLeadResult>({
        data: [],
        pageInfo: {
            cursor: 0,
            hasNextPage: false,
            limit: 0
        },
        totalCount: 0
    });

    const [statusId, setStatusId] = useState(4);
    const [recording, setRecording] = useState(false);
    const [assigned, setAssigned] = useState(true);
    const [blockUserVoicemail, setBlockUserVoicemail] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50
    });

    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name1', headerName: 'First Name', width: 130 },
        { field: 'name2', headerName: 'Last Name', width: 130 },
        { field: 'calling', headerName: 'Calling', width: 80 },
        { field: 'portfolio', headerName: 'Portfolio', width: 200, valueGetter: (value: any, row: DebitumLead) => `${row?.Portfolio?.name}` },
        { field: 'user', headerName: 'User', width: 80, valueGetter: (value: any, row: DebitumLead) => `${row?.User?.alias ?? ''}` },
        { field: 'status', headerName: 'Status', width: 80, valueGetter: (value: any, row: DebitumLead) => `${row?.LeadStatus?.status}` }
    ]

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: {
        msg: string
    }) => {
        if (value?.msg) {
            setToastMessage(value.msg);
            setToast(true);
            setRowSelectionModel([]);
            fetchData()
        }
        setOpen(false);
    };

    useEffect(() => {
        getVoicemailConfig();
    }, []);

    useEffect(() => {
        console.log(paginationModel)
        if (portfolioId || userId || statusId || assigned) {
            fetchData()
        }
    }, [paginationModel])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        fetchData()
    };

    const handleCloseToast = () => {
        setToast(false);
    }

    function getVoicemailConfig() {
        queryVoicemailConfig().then(response => {
            let config = response?.data?.vicidialConfig
            if (config) {
                setBlockUserVoicemail(config.block_voicemail);
            }
        }).catch(error => {

        });
    }

    function voicemailConfig() {
        mutationVoicemailConfig({
            variables: {
                blockVoicemail: blockUserVoicemail
            }
        }).then(response => {
            let msg = response.data?.vicidialConfig?.msg;
            if (msg) {
                setToastMessage(msg);
                setToast(true);
            }
        }).catch(error => console.log(error));
    }

    const action = (
        <Box>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => handleCloseToast()}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
    );


    const fetchData = () => {
        queryDebitumLeads({
            variables: {
                portfolio_id: portfolioId,
                client_id: userId,
                leadstatus_id: statusId,
                limit: paginationModel.pageSize,
                cursor: paginationModel.page,
                recordings: recording,
                assigned: assigned,
                assigned_start: rangeDateModel[0],
                assigned_end: rangeDateModel[1]
            }
        }).then(response => {
            setLeads(response.data?.debitumLeads!)
        }).catch(err => console.log(err.message))
    }

    return (<Box sx={{ flexGrow: 1 }}>
        <BasicHeader text="Voicemail Creator"></BasicHeader>
        <Grid sx={{ padding: "20px" }} container spacing={2}>
            <Grid xs={3}>
                <Card component="form" noValidate onSubmit={handleSubmit}>
                    <CardContent>
                        <Autocomplete
                            /*onChange={(event, value) => {
                                setPorfolioId(value?.id || null);
                            }}*/
                            loading={loadingParams}
                            getOptionLabel={(option: DebitumPortfolio) => option.name!}
                            getOptionKey={(option: DebitumPortfolio) => option.id!}
                            size="small"
                            options={paramsData?.debitumPortfolios?.length > 0 ? paramsData.debitumPortfolios : []}
                            sx={{ marginBottom: "10px" }}
                            renderInput={(params) => <TextField {...params} label="Portfolios" />}
                        />
                        <Autocomplete
                            //onChange={(val) => setUserId(val)}
                            loading={loadingParams}
                            getOptionLabel={(option: DebitumUser) => option.alias!}
                            getOptionKey={(option: DebitumUser) => option.id!}
                            size="small"
                            options={paramsData?.debitumUsers?.length > 0 ? paramsData.debitumUsers : []}
                            sx={{ marginBottom: "10px" }}
                            renderInput={(params) => <TextField {...params} label="Users" />}
                        />
                        <Autocomplete
                            /*onChange={(event, value) => {
                                setStatusId(value?.id || null);
                            }}*/
                            loading={loadingParams}
                            getOptionLabel={(option: DebitumLeadStatus) => option.status!}
                            getOptionKey={(option: DebitumLeadStatus) => option.id!}
                            size="small"
                            options={paramsData?.debitumLeadStatuses?.length > 0 ? paramsData.debitumLeadStatuses : []}
                            sx={{ marginBottom: "10px" }}
                            renderInput={(params) => <TextField {...params} label="Statuses" />}
                        />
                        <FormControlLabel value={recording} /*onChange={(val) => setRecording(val.target.checked)}*/ control={<Checkbox defaultChecked={recording} />} label="With Recording" sx={{ marginBottom: "10px" }} />
                        <FormControlLabel value={assigned} /*onChange={(val) => setAssigned(val.target.checked)}*/ control={<Checkbox defaultChecked={assigned} />} label="Assigned" sx={{ marginBottom: "10px" }} />
                        {assigned && <Box>
                            <Divider style={{ marginTop: "10px" }}>
                                <Chip label="Assigned Date" size="small" />
                            </Divider>
                            <Grid sx={{ marginBottom: "20px", marginTop: "20px", marginInline: 'auto' }} container>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateRangePicker
                                        onChange={(val) => setRangeDateModel([val[0]!, val[1]!])}
                                        defaultValue={[rangeDateModel[0], rangeDateModel[1]]}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Box>}
                        <Button
                            disabled={loading || loadingParams}
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            Search
                        </Button>
                    </CardContent>
                </Card>
                <Button
                    onClick={handleClickOpen}
                    fullWidth
                    color="success"
                    disabled={rowSelectionModel.length == 0}
                    style={{ marginTop: "10px" }}
                    variant="contained"
                >
                    CREATE VOICEMAILS
                </Button>
                {open && <VoicemailCreatorModal
                    open={open}
                    rowSelectionModel={rowSelectionModel}
                    onClose={handleClose}
                ></VoicemailCreatorModal>}
                <Snackbar
                    open={toast}
                    autoHideDuration={3000}
                    onClose={handleCloseToast}
                    message={toastMessage}
                    action={action}
                />
                {!loadingQVoicemailConfig ? <Box>
                    <Divider style={{ marginTop: "20px" }}>
                        <Chip label="Settings" size="small" />
                    </Divider>
                    <FormControlLabel value={blockUserVoicemail} control={<Checkbox onChange={(event) => setBlockUserVoicemail(event.target.checked)} checked={blockUserVoicemail} />} label="Block User Voicemails" />
                    <Button
                        size="small"
                        disabled={loadingMVoicemailConfig}
                        onClick={voicemailConfig}
                        variant="contained"
                        style={{ backgroundColor: colors.grey[700] }}
                    >
                        Save Setting..
                    </Button>
                </Box> : <Box style={{ marginTop: "20px" }} >
                    <CircularProgress size={"25px"} />
                    <Typography variant="body2">Loading settings...</Typography>
                </Box>}
            </Grid>
            <Grid xs={9}>
                <Card>
                    <CardContent>
                        <DataGridPro
                            loading={loading}
                            rows={leads.data as DebitumLead[]}
                            columns={columns}
                            rowCount={leads.totalCount!}
                            sx={{ height: "630px" }}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: paginationModel.page, pageSize: paginationModel.pageSize },
                                },
                            }}
                            pagination
                            pageSizeOptions={[50, 500, 1000, 3000]}
                            checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            paginationModel={paginationModel}
                            paginationMode="server"
                            onPaginationModelChange={(event) => {
                                console.log(event)
                                setPaginationModel(event)
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Box>)
}