import * as React from "react";
import {
    Navigate,
    createBrowserRouter,
} from "react-router-dom";
import Home from "../screens/private/Home";
import BankRouting from "../screens/private/system/bank-routings";
import ActivityType from "../screens/private/system/activity-types";
import SettingScreen from "../screens/private/settings";
import BranchScreen from "../screens/private/settings/branches";
import VoicemailCreatorScreen from "../screens/private/voice/voicemail";
import FileTypeScreen from "../screens/private/system/file-types";
import ProductTypeScreen from "../screens/private/settings/product-type";
import StatNumberScreen from "../screens/private/settings/stat-number";
import DispositionCodeScreen from "../screens/private/system/disposition-codes";
import DebtTypeScreen from "../screens/private/settings/debt-type";
import LeadStatusScreen from "../screens/private/settings/lead-status";
import OriginalClientScreen from "../screens/private/settings/original-client";
import PhoneTypeScreen from "../screens/private/system/phone-types";
import ScriptScreen from "../screens/private/settings/script";
import PaymentStatusCodeScreen from "../screens/private/system/payment-status-codes";
import PortfolioTypeScreen from "../screens/private/settings/portfolio-types";
import CompanyListScreen from "../screens/private/system/companies";
import UserScreen from "../screens/private/system/users";
import PortfolioScreen from "../screens/private/settings/portfolio";
import VendorTypeScreen from "../screens/private/settings/vendor-type";
import VendorScreen from "../screens/private/settings/vendor";
import ImportPortfolioForm from "../screens/private/settings/portfolio/form";
import TemplateTypeScreen from "../screens/private/system/template-types";
import TemplateTypeFieldScreen from "../screens/private/system/template-type-fields";
import TemplateScreen from "../screens/private/system/templates";
import SubaccountScreen from "../screens/private/settings/subaccount";
import AgentBoardScreen from "../screens/private/agent/board";
import AgentScreen from "../screens/private/agent";
import WorklistScreen from "../screens/private/settings/worklist";
import CompanyUsersScreen from "../screens/private/settings/users";
import SystemSettingsScreen from "../screens/private/system";
import VoicemailScreen from "../screens/private/voice";
import VoiceCreatorScreen from "../screens/private/voice/creator";
import VoiceDashboardScreen from "../screens/private/voice/dashboard";
import PermissionScreen from "../screens/private/settings/permissions";
import ContractScreen from "../screens/private/settings/contract";
import SystemEmailTemplateScreen from "../screens/private/system/email-templates";
import MarketingScreen from "../screens/private/marketing";
import SystemEmailTemplateListScreen from "../screens/private/system/email-templates/list";
import SystemEmailTemplateCreateScreen from "../screens/private/system/email-templates/create";
import EmailTemplateScreen from "../screens/private/marketing/email-templates";
import EmailTemplateListScreen from "../screens/private/marketing/email-templates/list";
import SegmentInputScreen from "../screens/private/system/segment-inputs";
import SegmentOperatorScreen from "../screens/private/system/segment-operators";
import SegmentCategoryScreen from "../screens/private/system/segment-categories";
import SegmentSelectScreen from "../screens/private/system/segment-selects";
import SegmentFilterScreen from "../screens/private/system/segment-filters";
import SegmentListScreen from "../screens/private/marketing/segments/list";
import SegmentScreen from "../screens/private/marketing/segments";
import CreateSegmentScreen from "../screens/private/marketing/segments/create";
import CreateEmailTemplateScreen from "../screens/private/marketing/email-templates/create";
import ImportSegmentScreen from "../screens/private/marketing/segments/import";
import EmailIdentityScreen from "../screens/private/marketing/email-identities";
import MarketingDashboardScreen from "../screens/private/marketing/dashboard";
import PhoneScreen from "../screens/private/marketing/phones";
import CustomerScreen from "../screens/private/marketing/customers";

export const PRIVATE_ROUTE = createBrowserRouter([
    {
        path: "/",
        element: <Home></Home>,
        errorElement: <Navigate to={"/"}></Navigate>,
        children: [
            {
                path: 'agent',
                element: <AgentScreen></AgentScreen>,
                children: [{
                    path: 'board',
                    element: <AgentBoardScreen></AgentBoardScreen>
                }]
            }, {
                path: 'marketing',
                element: <MarketingScreen></MarketingScreen>,
                children: [{
                    path: 'dashboard',
                    element: <MarketingDashboardScreen></MarketingDashboardScreen>
                }, {
                    path: 'identities',
                    element: <EmailIdentityScreen></EmailIdentityScreen>
                }, {
                    path: 'customers',
                    element: <CustomerScreen></CustomerScreen>
                }, {
                    path: 'phones',
                    element: <PhoneScreen></PhoneScreen>
                }, {
                    path: 'email-templates',
                    element: <EmailTemplateScreen></EmailTemplateScreen>,
                    children: [{
                        path: 'list',
                        element: <EmailTemplateListScreen></EmailTemplateListScreen>
                    }, {
                        path: 'create',
                        element: <CreateEmailTemplateScreen></CreateEmailTemplateScreen>
                    }, {
                        path: 'edit/:external_id',
                        element: <CreateEmailTemplateScreen></CreateEmailTemplateScreen>
                    }]
                }, {
                    path: 'segments',
                    element: <SegmentScreen></SegmentScreen>,
                    children: [{
                        path: 'list',
                        element: <SegmentListScreen></SegmentListScreen>
                    }, {
                        path: 'create',
                        element: <CreateSegmentScreen></CreateSegmentScreen>
                    }, {
                        path: 'edit/:external_id',
                        element: <CreateSegmentScreen></CreateSegmentScreen>
                    }, {
                        path: 'import',
                        element: <ImportSegmentScreen></ImportSegmentScreen>
                    }]
                }]
            }, {
                path: 'settings',
                element: <SettingScreen></SettingScreen>,
                children: [{
                    path: 'users',
                    element: <CompanyUsersScreen></CompanyUsersScreen>
                }, {
                    path: 'branches',
                    element: <BranchScreen></BranchScreen>
                }, {
                    path: 'worklists',
                    element: <WorklistScreen></WorklistScreen>
                }, {
                    path: 'product-types',
                    element: <ProductTypeScreen></ProductTypeScreen>
                }, {
                    path: 'stat-numbers',
                    element: <StatNumberScreen></StatNumberScreen>
                }, {
                    path: 'debt-types',
                    element: <DebtTypeScreen></DebtTypeScreen>
                }, {
                    path: 'contracts',
                    element: <ContractScreen></ContractScreen>
                }, {
                    path: 'lead-statuses',
                    element: <LeadStatusScreen></LeadStatusScreen>
                }, {
                    path: 'original-clients',
                    element: <OriginalClientScreen></OriginalClientScreen>
                }, {
                    path: 'scripts',
                    element: <ScriptScreen></ScriptScreen>
                }, {
                    path: "branches",
                    element: <BranchScreen></BranchScreen>
                }, {
                    path: "portfolios",
                    element: <PortfolioScreen></PortfolioScreen>
                }, {
                    path: "subaccounts",
                    element: <SubaccountScreen></SubaccountScreen>
                }, {
                    path: 'portfolio-types',
                    element: <PortfolioTypeScreen></PortfolioTypeScreen>
                }, {
                    path: "import-portfolios",
                    element: <ImportPortfolioForm></ImportPortfolioForm>
                }, {
                    path: 'vendor-types',
                    element: <VendorTypeScreen></VendorTypeScreen>
                }, {
                    path: 'vendors',
                    element: <VendorScreen></VendorScreen>
                }, {
                    path: 'permissions',
                    element: <PermissionScreen></PermissionScreen>
                }]
            },
            {
                path: 'system',
                element: <SystemSettingsScreen></SystemSettingsScreen>,
                children: [{
                    path: "companies",
                    element: <CompanyListScreen></CompanyListScreen>
                }, {
                    path: 'templates',
                    element: <TemplateScreen></TemplateScreen>
                }, {
                    path: 'file-types',
                    element: <FileTypeScreen></FileTypeScreen>
                }, {
                    path: 'template-types',
                    element: <TemplateTypeScreen></TemplateTypeScreen>
                }, {
                    path: 'payment-status-codes',
                    element: <PaymentStatusCodeScreen></PaymentStatusCodeScreen>
                }, {
                    path: 'template-type-fields',
                    element: <TemplateTypeFieldScreen></TemplateTypeFieldScreen>
                }, {
                    path: 'users',
                    element: <UserScreen></UserScreen>
                }, {
                    path: 'phone-types',
                    element: <PhoneTypeScreen></PhoneTypeScreen>
                }, {
                    path: 'activity-types',
                    element: <ActivityType></ActivityType>
                }, {
                    path: 'bank-routings',
                    element: <BankRouting></BankRouting>
                }, {
                    path: 'segment-inputs',
                    element: <SegmentInputScreen></SegmentInputScreen>
                }, {
                    path: 'segment-categories',
                    element: <SegmentCategoryScreen></SegmentCategoryScreen>
                }, {
                    path: 'segment-operators',
                    element: <SegmentOperatorScreen></SegmentOperatorScreen>
                }, {
                    path: 'segment-selects',
                    element: <SegmentSelectScreen></SegmentSelectScreen>
                }, {
                    path: 'segment-filters',
                    element: <SegmentFilterScreen></SegmentFilterScreen>
                }, {
                    path: 'disposition-codes',
                    element: <DispositionCodeScreen></DispositionCodeScreen>
                }, {
                    path: 'email-templates',
                    element: <SystemEmailTemplateScreen></SystemEmailTemplateScreen>,
                    children: [{
                        path: 'list',
                        element: <SystemEmailTemplateListScreen></SystemEmailTemplateListScreen>
                    }, {
                        path: 'create',
                        element: <SystemEmailTemplateCreateScreen></SystemEmailTemplateCreateScreen>
                    }, {
                        path: 'edit/:external_id',
                        element: <SystemEmailTemplateCreateScreen></SystemEmailTemplateCreateScreen>
                    }]
                }]
            },
            {
                path: 'voice',
                element: <VoicemailScreen></VoicemailScreen>,
                children: [{
                    path: "voicemails",
                    element: <VoicemailCreatorScreen></VoicemailCreatorScreen>
                }, {
                    path: "dashboard",
                    element: <VoiceDashboardScreen></VoiceDashboardScreen>
                }, {
                    path: "creator",
                    element: <VoiceCreatorScreen></VoiceCreatorScreen>
                }]
            }
        ]
    }
]);