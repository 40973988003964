import { ThemeProvider } from "@emotion/react";
import { Button, Dialog, DialogActions, DialogTitle, createTheme } from "@mui/material";
import { useEffect, useState } from "react";

interface ArgsType {
    open: boolean;
    title: string;
    confirm: Function;
    close: Function;
}

export default function ConfirmModal({ confirm, close, open: openModal, title }: Readonly<ArgsType>): JSX.Element {
    const [open, setOpen] = useState(false);

    const lightTheme = createTheme({
        palette: {
            mode: 'light'
        },
    });

    useEffect(() => {
        setOpen(openModal);
    }, [openModal]);

    const handleClose = () => {
        setOpen(false);
        close();
    }

    const handleConfirm = () => {
        handleClose();
        confirm();
    }

    return (<ThemeProvider theme={lightTheme}>
        <Dialog
            open={open}
            onClose={() => close()}
        >
            <DialogTitle style={{ paddingBottom: "0px" }}>{title}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    </ThemeProvider>)
}