import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddVendorDocument, DeleteVendorDocument, Vendor, VendorsDocument, UpdateVendorDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function VendorScreen() {
    const [query, { loading }] = useLazyQuery(VendorsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddVendorDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateVendorDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteVendorDocument);

    const columns: GridColDef<Vendor>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'vendor_type', headerName: 'Type', flex: 1, valueGetter: ({ row }: { row: Vendor }) => `${row?.VendorType?.name}` },
        { field: 'contact_name', headerName: 'Contact Name', flex: 1 },
        { field: 'city', headerName: 'City', minWidth: 200, valueGetter: ({ row }: { row: Vendor }) => `${row?.Address?.city || ''}` },
        { field: 'state', headerName: 'State', minWidth: 100, valueGetter: ({ row }: { row: Vendor }) => `${row?.Address?.state || ''}` }
    ];

    function formatParams(args: Vendor) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"vendor"} pluralQueryName={"vendors"} formatParams={formatParams} form={Form} title={"Vendors"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}