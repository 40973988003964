import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import BasicHeader from "../../../components/BasicHeader";
import { Logout } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { joinCompanyUser, restoreToken, signOut, updateAcceptCompanyUser, updateCompanyUsers, user } from "../../../store/reducers/user";
import { useNavigate } from "react-router-dom";
import { CompanyUser, useAcceptCompanyRequestMutation, useLoginCompanyUserMutation, useUserCompanyUsersLazyQuery } from "../../../gql/types.graphql";
import { useEffect } from "react";

export default function UserCompanySelector(): JSX.Element {
    const userSelector = useAppSelector(user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [acceptCompanyRequestMutation, { loading: loadingUpdate }] = useAcceptCompanyRequestMutation();
    const [loginCompanyUserMutation, { loading: loadingCompanyUserUpdate }] = useLoginCompanyUserMutation();
    const [query, { loading }] = useUserCompanyUsersLazyQuery();

    useEffect(() => {
        userCompanyUsers()
    }, []);

    function userCompanyUsers() {
        query().then(response => {
            const companyUsers = response.data?.userCompanyUsers;
            if (companyUsers) {
                dispatch(updateCompanyUsers(companyUsers as CompanyUser[]))
            }
        })
    }

    function acceptCompany(companyUser: CompanyUser) {
        acceptCompanyRequestMutation({
            variables: {
                external_id: companyUser?.external_id!
            }
        }).then(response => {
            const userCompany = response.data?.acceptCompanyRequest;
            if (userCompany) {
                dispatch(updateAcceptCompanyUser(userCompany))
            }
        })
    }

    function loginCompanyUser(companyUser: CompanyUser) {
        loginCompanyUserMutation({
            variables: {
                external_id: companyUser?.external_id!
            }
        }).then(response => {
            const user = response.data?.loginCompanyUser;
            if (user) {
                dispatch(restoreToken(user));
                dispatch(joinCompanyUser(companyUser))
            }
        });
    }

    return (<Box>
        <BasicHeader></BasicHeader>
        <Box width={'100%'} display={'flex'}>
            <Box width={'500px'} sx={{ margin: 'auto', marginTop: '20px' }}>
                <Typography fontWeight={'bold'} fontSize={'20px'}>Companies I can access</Typography>
                {userSelector.user?.CompanyUsers?.length ? userSelector.user?.CompanyUsers?.map(companyUser => {
                    return (<Card style={{ width: '400px', margin: '20px auto' }} key={companyUser?.external_id}>
                        <CardContent>
                            <Typography fontSize={'20px'} fontWeight={'bold'}>{companyUser?.Company?.name}</Typography>
                            {!companyUser?.accepted && <Button disabled={loadingUpdate} onClick={() => acceptCompany(companyUser!)} sx={{ marginTop: '10px' }} variant="contained" color="success">Accept access</Button>}
                            {companyUser?.accepted && <Button onClick={() => loginCompanyUser(companyUser)} disabled={loadingUpdate} sx={{ marginTop: '10px' }} variant="contained" color="primary">JOIN</Button>}
                        </CardContent>
                    </Card>)
                }) :
                    <Card style={{ width: '400px', margin: '20px auto' }}>
                        <CardContent>
                            <Typography fontSize={'20px'} fontWeight={'bold'}>You don't have access to any company.</Typography>
                        </CardContent>
                    </Card>}
                <Button color="success" onClick={() => {
                    dispatch(signOut());
                    navigate('/')
                }} sx={{ marginTop: '20px' }} variant="contained">
                    <Logout></Logout> Log Out
                </Button>
            </Box>
        </Box>
    </Box>)
}