import { Add, Close } from "@mui/icons-material";
import { Box, CardHeader, List, Typography } from "@mui/material";
import { LeadAddress, useAddLeadAddressMutation, useDeleteLeadAddressMutation, useUpdateLeadAddressMutation } from "../../../../../gql/types.graphql";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { addLeadAddress, lead, leadAddresses, removeLeadAddress, updateLeadAddress } from "../../../../../store/reducers/lead";
import { LoadingButton } from "@mui/lab";
import AddLeadAddress from "./AddLeadAddress";

export default function LeadAddresses(): JSX.Element {
    const leadSelector = useAppSelector(lead);
    const addresses = useAppSelector(leadAddresses);
    const dispatch = useAppDispatch();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [add, { loading: loadingAdd }] = useAddLeadAddressMutation();
    const [update, { loading: loadingUpdate }] = useUpdateLeadAddressMutation();
    const [destroy, { loading: loadingDestroy }] = useDeleteLeadAddressMutation();

    function onSubmit(args: LeadAddress) {
        setOpenEdit(false);
        if (args.external_id) {
            update({
                variables: {
                    address: args.Address?.address!,
                    city: args.Address?.city!,
                    state: args.Address?.state!,
                    zipcode: args.Address?.zipcode!,
                    external_id: args.external_id,
                    review_date: args.review_date
                }
            }).then(response => {
                const result = response.data?.updateLeadAddress;
                if (result) {
                    dispatch(updateLeadAddress(result))
                }
            })
        } else {
            add({
                variables: {
                    lead_id: leadSelector?.external_id!,
                    address: args.Address?.address!,
                    city: args.Address?.city!,
                    state: args.Address?.state!,
                    zipcode: args.Address?.zipcode!,
                    review_date: args.review_date
                }
            }).then(response => {
                const result = response.data?.addLeadAddress;
                if (result) {
                    dispatch(addLeadAddress(result))
                }
            })
        }
    }

    function onDelete(args: LeadAddress) {
        dispatch(removeLeadAddress(args));
        destroy({
            variables: {
                external_id: args.external_id!
            }
        }).then(response => {

        })
    }


    return (
        <Box>
            <CardHeader style={{ padding: '0px', marginBottom: '10px' }} action={<LoadingButton loading={loadingAdd} onClick={() => setOpenEdit(!openEdit)}>{openEdit ? <Close></Close> : <Add></Add>}</LoadingButton>} avatar={<Typography fontWeight={600}>Addresses</Typography>} />
            {openEdit && <AddLeadAddress loading={loadingAdd} onSubmit={onSubmit}></AddLeadAddress>}
            <List dense={true} sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                paddingLeft: '0px',
                maxHeight: 170,
                '& ul': { padding: 0 }
            }}>
                {addresses?.map((address, index) => {
                    return (
                        <AddLeadAddress loading={loadingUpdate} key={address?.external_id} onDelete={onDelete} onSubmit={onSubmit} defaultValue={address!}></AddLeadAddress>
                    )
                })}
            </List>
        </Box>
    );
}