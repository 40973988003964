import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddSegmentFilterDocument, DeleteSegmentFilterDocument, SegmentFilter, SegmentFiltersDocument, UpdateSegmentFilterDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function SegmentFilterScreen() {
    const [query, { loading }] = useLazyQuery(SegmentFiltersDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddSegmentFilterDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateSegmentFilterDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteSegmentFilterDocument);

    const columns: GridColDef<SegmentFilter>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', flex: 1 }
    ];

    function formatParams(args: SegmentFilter) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"segmentFilter"} pluralQueryName={"segmentFilters"} formatParams={formatParams} form={Form} title={"Segment Filters"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}