import { DialogContent, TextField } from "@mui/material";
import { PhoneType } from "../../../../gql/types.graphql";

export default function Form({ defaultValue }: Readonly<{ defaultValue: PhoneType }>) {

    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}