import { AppBar, Box, Divider, IconButton, Stack, TextField, ThemeProvider, Toolbar, Typography, colors, createTheme } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import Logo from "./Logo";
import { StyledAppBar, StyledToolbar } from "./StyledComponents";
import ToolbarTitle from "./ToolbarTitle";

interface ArgsType {
    add?: JSX.Element;
    text?: string;
    search?: Function;
    refresh?: Function;
}

export default function BasicHeader({ add, text, search, refresh }: Readonly<ArgsType>): JSX.Element {

    return (
        <StyledAppBar position="static">
            <StyledToolbar style={{ boxShadow: "none", justifyContent: 'space-between' }}>
                <ToolbarTitle title={text}></ToolbarTitle>
                <Stack direction={'row'} spacing={1}>
                    {search && <TextField onChange={(value) => search(value.target.value)} size="small" label="Search" variant="outlined" />}
                    {add || <></>}
                    {refresh && <IconButton onClick={() => refresh()}>
                        <RefreshIcon style={{ color: 'rgb(96 103 117)' }}></RefreshIcon>
                    </IconButton>}
                </Stack>
            </StyledToolbar>
        </StyledAppBar>
    );
}