import { Checkbox, DialogContent, FormControlLabel, Grid, TextField } from "@mui/material";
import { CompanyUser, Permission, PermissionsDocument } from "../../../../gql/types.graphql";
import { useLazyQuery } from "@apollo/client";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { useEffect, useState } from "react";

export default function Form({ defaultValue }: Readonly<{ defaultValue: CompanyUser }>) {
    const [query, { loading }] = useLazyQuery(PermissionsDocument);
    const [values, setValues] = useState<CompanyUser>();
    function formatParams(args: Permission): string {
        return `${args.name}`;
    }

    useEffect(() => {
        if (defaultValue) {
            setValues({
                ...defaultValue,
                active: typeof defaultValue?.active === "boolean" ? defaultValue.active : true
            });
        }
    }, [defaultValue]);

    return (<DialogContent style={{ width: '400px' }}>
        <Grid padding={"10px"} container spacing={2}>
            <Grid item padding={"5px"} xs={12}>
                <TextField
                    autoFocus
                    disabled={Boolean(values?.external_id!)}
                    required
                    defaultValue={values?.User?.email}
                    margin="dense"
                    id="email"
                    inputProps={{
                        maxLength: 100
                    }}
                    name="email"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    autoFocus
                    required
                    defaultValue={values?.alias}
                    margin="dense"
                    id="alias"
                    inputProps={{
                        maxLength: 100
                    }}
                    name="alias"
                    label="Alias"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <AutocompleteSelect defaultValue={values?.Permission} name={'permission_id'} formatParams={formatParams} queryName={"permissions"} query={query} label="Permission" id="permission_id"></AutocompleteSelect>
                <FormControlLabel control={<Checkbox defaultChecked={values?.active!} name="active" id="active" />} label="Active" />
            </Grid>
        </Grid>
    </DialogContent>)
}