import { DialogContent, TextField } from "@mui/material";
import { EmailTemplate } from "../../../../../gql/types.graphql";
export default function TestEmailForm({ defaultValue }: Readonly<{ defaultValue: EmailTemplate }>) {
    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}