import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddEmailIdentityDocument, DeleteEmailIdentityDocument, EmailIdentity, EmailIdentitiesDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function EmailIdentityScreen() {
    const [query, { loading }] = useLazyQuery(EmailIdentitiesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddEmailIdentityDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteEmailIdentityDocument);

    const columns: GridColDef<EmailIdentity>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'domain', headerName: 'Domain', flex: 1 }
    ];

    function formatParams(args: EmailIdentity) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"emailIdentity"} pluralQueryName={"emailIdentities"} formatParams={formatParams} form={Form} title={"Identities"} add={add} destroy={destroy} loading={loading || loadingAdd || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}