import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddPhoneDocument, DeletePhoneDocument, Phone, PhonesDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function PhoneScreen() {
    const [query, { loading }] = useLazyQuery(PhonesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddPhoneDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeletePhoneDocument);

    const columns: GridColDef<Phone>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'phone', headerName: 'Phone', flex: 1 }
    ];

    function formatParams(args: Phone) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"phone"} pluralQueryName={"phones"} formatParams={formatParams} form={Form} title={"Phones"} add={add} destroy={destroy} loading={loading || loadingAdd || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}