import { AppBar, AppBarProps, styled, Toolbar, ToolbarProps } from "@mui/material";

export const StyledAppBar = styled((props: AppBarProps) => (
    <AppBar
        elevation={0}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'white',
    boxShadow: '1px 2px 4px 0 rgba(0,0,0,.03)'
}));

export const StyledToolbar = styled((props: ToolbarProps) => (
    <Toolbar
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'white',
    justifyContent: 'space-between',
    boxShadow: 'none',
    borderBottom: '1px solid #e0e0e0',
    height: '65px'
}));
