import { Checkbox, DialogContent, FormControl, FormControlLabel, Input, InputLabel, TextField } from "@mui/material";
import { IMaskInput } from 'react-imask';
import { User } from "../../../../gql/types.graphql";
import { useEffect, useState } from "react";
import { PhoneField } from "../../../../components/PhoneField";

export default function Form({ defaultValue }: Readonly<{ defaultValue: User }>) {
    const [values, setValues] = useState<User>();

    useEffect(() => {
        if (defaultValue) {
            setValues({
                ...defaultValue,
                verified_email: typeof defaultValue?.verified_email === "boolean" ? defaultValue.verified_email : true
            });
        }
    }, [defaultValue]);

    return (<DialogContent style={{ width: '300px' }}>
        <FormControl fullWidth variant="standard">
            <TextField
                autoFocus
                required
                defaultValue={defaultValue?.first_name}
                margin="dense"
                id="first_name"
                name="first_name"
                label="First Name"
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                autoFocus
                required
                defaultValue={defaultValue?.last_name}
                margin="dense"
                id="last_name"
                name="last_name"
                label="Last Name"
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                autoFocus
                required
                defaultValue={defaultValue?.email}
                margin="dense"
                id="email"
                name="email"
                label="Email"
                type="text"
                fullWidth
                variant="standard"
            />
        </FormControl>
    </DialogContent>)
}