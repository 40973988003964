import { Button, ButtonGroup, Checkbox, DialogContent, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, TextField, Typography } from "@mui/material";
import { Template, TemplateType, TemplateTypesDocument } from "../../../../gql/types.graphql";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export default function Form({ defaultValue }: Readonly<{ defaultValue: Template }>) {
    const [query, { loading }] = useLazyQuery(TemplateTypesDocument);
    const [value, setValue] = useState<Template>();
    const [checked, setChecked] = useState<string[]>([]);
    const [templateTypeFields, setTemplateTypeFields] = useState<string>();

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue)
            if (defaultValue.TemplateTypeFields) {
                let fields = defaultValue.TemplateTypeFields.map(field => field?.external_id!);
                setChecked(fields);
                setTemplateTypeFields(fields.join())
            }
        }
    }, [defaultValue]);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        console.log('checked', newChecked)
    };

    function removeAll() {
        setChecked([]);
    }

    function selectAll() {
        setChecked(value!.TemplateType!.TemplateTypeFields!.map(field => field!.external_id!));
    }

    function formatParams(args: Template): string {
        return `${args.name}`;
    }

    return (<DialogContent style={{ width: '600px' }}>
        <Grid container spacing={5}>
            <Grid sm={6} item>
                <TextField
                    autoFocus
                    required
                    value={value?.name}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <AutocompleteSelect onChange={(templateType: TemplateType) => {
                    setValue({
                        ...value,
                        TemplateType: templateType
                    });
                }} defaultValue={value?.TemplateType} name={'template_type_id'} formatParams={formatParams} queryName={"templateTypes"} query={query} label="Template Type" id="template_type_id"></AutocompleteSelect>
            </Grid>
            <Grid sm={6} item>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    subheader={<ListSubheader>
                        <Typography>Fields</Typography>
                        <ButtonGroup variant="outlined" aria-label="Basic button group">
                            <Button size="small" onClick={selectAll}>Select All</Button>
                            <Button color="warning" size="small" onClick={removeAll}>Remove All</Button>
                        </ButtonGroup>
                    </ListSubheader>}
                >
                    {value?.TemplateType?.TemplateTypeFields!.map((field) => {
                        const labelId = `checkbox-list-label-${field?.external_id}`;
                        return (
                            <ListItem
                                key={field?.external_id}
                                disablePadding
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(field?.external_id!)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(field?.external_id!) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={field?.name} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
                <TextField
                    style={{ display: 'none' }}
                    autoFocus
                    required
                    id={'template_type_fields_id'}
                    name={'template_type_fields_id'}
                    value={templateTypeFields}
                    type="text"
                />
            </Grid>
        </Grid>
    </DialogContent>)
}