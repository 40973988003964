import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import { AddBankRoutingDocument, BankRouting, BankRoutingsDocument, DeleteBankRoutingDocument, UpdateBankRoutingDocument } from "../../../../gql/types.graphql";
import BankRoutingForm from "./form";
import { GridColDef } from "@mui/x-data-grid";

export default function BankRoutingScreen() {
    const [query, { loading }] = useLazyQuery(BankRoutingsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddBankRoutingDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateBankRoutingDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteBankRoutingDocument);

    const columns: GridColDef<BankRouting>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'bank_name', headerName: 'Bank Name', flex: 1 },
        { field: 'number', headerName: 'Number', minWidth: 150 },
        { field: 'phone', headerName: 'Phone', minWidth: 150 },
        { field: 'address', headerName: 'Address', minWidth: 200, valueGetter: ({ row }: { row: BankRouting }) => `${row?.Address?.address}` },
        { field: 'zipcode', headerName: 'Zip Code', minWidth: 100, valueGetter: ({ row }: { row: BankRouting }) => `${row?.Address?.zipcode}` },
        { field: 'city', headerName: 'City', minWidth: 200, valueGetter: ({ row }: { row: BankRouting }) => `${row?.Address?.city}` },
        { field: 'state', headerName: 'State', minWidth: 100, valueGetter: ({ row }: { row: BankRouting }) => `${row?.Address?.state}` },
    ];

    function formatParams(args: BankRouting) {
        if (args?.number) {
            args.number = Number(args.number)
        }
        return args;
    }

    return (<DataGridCRUD singularQueryName={"bankRouting"} pluralQueryName={"bankRoutins"} formatParams={formatParams} form={BankRoutingForm} title={"Bank Routing"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}