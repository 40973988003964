import { Box, Button, Card, CardContent, Checkbox, Divider, FormControlLabel, Grid, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import BasicHeader from "../../../../../components/BasicHeader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { VisuallyHiddenInput } from "../../../settings/portfolio/portfolio-import-builder";
import { Close, Download } from "@mui/icons-material";
import { useState } from "react";
import { Customer, InputCustomer, Segment, useImportSegmentMutation, usePreviewImportSegmentMutation, useSegmentsLazyQuery } from "../../../../../gql/types.graphql";
import { LoadingButton } from "@mui/lab";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { v4 as uuidv4 } from 'uuid';
import AutocompleteSelect from "../../../../../components/AutocompleteSelect";

export default function ImportSegmentScreen() {
    const [file, setFile] = useState<File | null>();
    const [importSegmentMutation, { loading: loadingSegment }] = useImportSegmentMutation();
    const [previewImportSegmentMutation, { loading: loadingPreview }] = usePreviewImportSegmentMutation();
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [existingSegment, setExistingSegment] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [segment, setSegment] = useState<string>('');
    const [querySegments, { loading: loadingSegments }] = useSegmentsLazyQuery();

    function formatParams(args: Segment): string {
        return `${args.name}`;
    }

    const columns: GridColDef<Customer>[] = [{
        field: 'first_name',
        headerName: 'First Name',
        flex: 1
    }, {
        field: 'last_name',
        headerName: 'Last Name',
        flex: 1
    }, {
        field: 'phone_number',
        headerName: 'Phone Number',
        flex: 1
    }, {
        field: 'email',
        headerName: 'Email',
        flex: 1
    }];

    function previewImportSegment() {
        previewImportSegmentMutation({
            variables: {
                file: file
            },
        }).then(response => {
            const cmrs = response?.data?.previewImportSegment;
            if (cmrs) {
                setCustomers(cmrs.map(cmr => {
                    delete cmr?.__typename;
                    return cmr as Customer;
                }));
            }
        })
    }

    function importSegment() {
        importSegmentMutation({
            variables: {
                customers: customers as InputCustomer[],
                existing_segment: existingSegment,
                name: name,
                segment_id: null
            }
        }).then(response => {
            const customrs = response?.data?.importSegment;
            if (customrs) {

            }
        })
    }


    const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            setFile(event.target.files[0])
        }
    }

    return (
        <Box onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            importSegment()
        }} component={'form'}>
            <BasicHeader text="Import Segment"></BasicHeader>
            <Grid spacing={2} container sx={{ textAlign: 'start', padding: '30px' }}>
                <Grid item sm={3}>
                    <Card>
                        <CardContent>
                            <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
                                <Typography variant="h5" component="div">
                                    Import File
                                </Typography>
                                <Tooltip title={'Downlaod template'}>
                                    <IconButton color="secondary">
                                        <Download></Download>
                                    </IconButton>
                                </Tooltip>
                            </Stack>

                            <TextField
                                autoFocus
                                required
                                defaultValue={''}
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                margin="dense"
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                            <FormControlLabel control={<Checkbox checked={existingSegment} onChange={(event) => setExistingSegment(event.target.checked)} name="existing_segment" id="existing_segment" />} label="Add to existing segment" />
                            {existingSegment && <AutocompleteSelect onChange={(value: string) => console.log(value)} style={{ marginTop: '0px' }} defaultValue={null} name={'segment_id'} formatParams={formatParams} queryName={"segments"} query={querySegments} label="Segments" id="segment_id"></AutocompleteSelect>}

                            <Box style={{ marginTop: '20px' }}>

                                {file && <Box component={'div'} style={{ backgroundColor: '#00000020', padding: '5px', borderRadius: '4px', marginBottom: '10px' }}><Typography fontSize={'12px'}>{file.name}</Typography></Box>}
                                <Stack alignItems={'center'} direction={'row'}>
                                    <Button
                                        component="label"
                                        color="secondary"
                                        role={undefined}
                                        fullWidth
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload file
                                        <VisuallyHiddenInput onChange={handleCapture} type="file" />
                                    </Button>
                                </Stack>
                            </Box>
                            <LoadingButton onClick={() => previewImportSegment()} disabled={!file} variant="contained" fullWidth color="primary" style={{ marginTop: '10px' }}>
                                Preview Customers
                            </LoadingButton>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={9}>
                    <Card>
                        <CardContent>
                            <Stack justifyContent={'space-between'} style={{ marginBottom: '10px' }} direction={'row'} alignItems={'center'}>
                                <Typography variant="h5" component="div">
                                    Preview Customers
                                </Typography>
                                <Button type="submit" disabled={customers.length == 0} size="medium" variant="contained" color="success">
                                    Import Customers
                                </Button>
                            </Stack>
                            <Box height="calc(100vh - 250px)">
                                <DataGrid
                                    rows={customers || []}
                                    getRowId={() => uuidv4()}
                                    rowCount={customers?.length}
                                    loading={loadingPreview}
                                    columns={columns}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}