import { Add, Close } from "@mui/icons-material";
import { Box, CardHeader, List, Typography } from "@mui/material";
import { LeadName, useAddLeadNameMutation, useDeleteLeadNameMutation, useUpdateLeadNameMutation } from "../../../../../gql/types.graphql";
import { useState } from "react";
import AddLeadName from "./AddLeadName";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { addLeadName, lead, leadNames, removeLeadName, updateLeadName } from "../../../../../store/reducers/lead";
import { LoadingButton } from "@mui/lab";

export default function LeadNames(): JSX.Element {
    const leadSelector = useAppSelector(lead);
    const names = useAppSelector(leadNames);
    const dispatch = useAppDispatch();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [add, { loading: loadingAdd }] = useAddLeadNameMutation();
    const [update, { loading: loadingUpdate }] = useUpdateLeadNameMutation();
    const [destroy, { loading: loadingDestroy }] = useDeleteLeadNameMutation();

    function onSubmit(args: LeadName) {
        setOpenEdit(false);
        if (args.external_id) {
            update({
                variables: {
                    first_name: args.first_name!,
                    last_name: args.last_name!,
                    external_id: args.external_id,
                    review_date: args.review_date
                }
            }).then(response => {
                dispatch(updateLeadName(response.data?.updateLeadName!))
            })
        } else {
            add({
                variables: {
                    first_name: args.first_name!,
                    last_name: args.last_name!,
                    lead_id: leadSelector?.external_id!,
                    review_date: args.review_date
                }
            }).then(response => {
                const result = response.data?.addLeadName;
                if (result) {
                    dispatch(addLeadName(result))
                }
            })
        }
    }

    function onDelete(args: LeadName) {
        dispatch(removeLeadName(args));
        destroy({
            variables: {
                external_id: args.external_id!
            }
        }).then(response => {

        })
    }


    return (
        <Box>
            <CardHeader style={{ padding: '0px', marginBottom: '10px' }} action={<LoadingButton loading={loadingAdd} onClick={() => setOpenEdit(!openEdit)}>{openEdit ? <Close></Close> : <Add></Add>}</LoadingButton>} avatar={<Typography fontWeight={600}>AKAs</Typography>} />
            {openEdit && <AddLeadName loading={loadingUpdate} onSubmit={onSubmit}></AddLeadName>}
            <List sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                paddingLeft: '0px',
                maxHeight: 170,
                '& ul': { padding: 0 }
            }}>
                {names?.map((name, index) => {
                    return (
                        <AddLeadName loading={loadingUpdate} key={name?.external_id} onDelete={onDelete} onSubmit={onSubmit} defaultValue={name!}></AddLeadName>
                    )
                })}
            </List>
        </Box>
    );
}

// {index != (names.length - 1) && <Box sx={{ marginY: '10px' }}><Divider></Divider></Box>}