import { AddCircle, Delete, Save } from "@mui/icons-material";
import { Box, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import { LeadEmail } from "../../../../../gql/types.graphql";
import { useEffect, useState } from "react";
import { LoadingButton } from '@mui/lab';

export default function AddLeadEmail({ defaultValue, onSubmit, onDelete, loading: loadingValue }: Readonly<{ defaultValue?: LeadEmail, onSubmit: (args: LeadEmail) => void; onDelete?: (args: LeadEmail) => void; loading: boolean }>): JSX.Element {
    const [email, setEmail] = useState<LeadEmail>({
        email: ""
    });

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(loadingValue);
    }, [loadingValue])

    useEffect(() => {
        if (defaultValue) {
            setEmail(defaultValue)
        }
    }, [defaultValue]);

    return (
        <Box style={{ width: '100%' }} component={'form'} onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit(email)
        }} >
            <ListItem key={'list-item-' + email.external_id} disablePadding>
                <ListItemText
                    primary={<TextField onChange={(event) => setEmail({
                        ...email,
                        email: event.target.value
                    })} required id="email" name="first_emailname" value={email.email} fullWidth size="small" label="Email" variant="outlined" />}
                />
                {!defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }} aria-label="delete">
                    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" style={{ margin: 'auto' }} color="secondary"><AddCircle /></LoadingButton>
                </ListItemIcon>}
                {defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center', marginInline: '10px' }} aria-label="delete">
                    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" style={{ margin: 'auto' }} color="success"><Save /></LoadingButton>
                </ListItemIcon>}
                {defaultValue?.external_id && <ListItemIcon style={{ display: 'flex', alignItems: 'center' }} aria-label="delete">
                    <LoadingButton size="large" variant="outlined" onClick={() => onDelete && onDelete(email)} style={{ margin: 'auto' }} color="error"><Delete /></LoadingButton>
                </ListItemIcon>}
            </ListItem>
        </Box>
    );
}