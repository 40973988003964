import { PayloadAction } from "@reduxjs/toolkit";
import { ActivityList, Lead, LeadAddress, LeadEmail, LeadFile, LeadName, Maybe, StatNumber } from "../../gql/types.graphql";
import { createAppSlice } from "../slice";

export interface StoreLeadReducerType {
    lead: Lead | null,
    enterToSubmit: boolean
}

const initialState: Readonly<StoreLeadReducerType> = {
    lead: {},
    enterToSubmit: false
};

export const leadSlice = createAppSlice({
    name: "lead",
    initialState,
    reducers: create => ({
        updateEnterToSubmit: create.reducer(
            (state, action: PayloadAction<boolean>) => {
                return {
                    ...state,
                    enterToSubmit: action.payload
                }
            }
        ),
        updateLeadInformation: create.reducer(
            (state, action: PayloadAction<Lead>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        warning: action.payload.warning,
                        LeadStatus: action.payload.LeadStatus,
                        LeadSplit: action.payload.LeadSplit
                    }
                }
            }
        ),
        addLeadEmail: create.reducer(
            (state, action: PayloadAction<LeadEmail>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadEmails: state.lead?.LeadEmails!.concat(action.payload)
                    }
                }
            },
        ),
        addActivityList: create.reducer(
            (state, action: PayloadAction<ActivityList>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        ActivityLists: [action.payload].concat(state.lead?.ActivityLists as ActivityList[])
                    }
                }
            },
        ),
        addLeadAddress: create.reducer(
            (state, action: PayloadAction<LeadAddress>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadAddresses: state.lead?.LeadAddresses!.concat(action.payload)
                    }
                }
            },
        ),
        addLeadName: create.reducer(
            (state, action: PayloadAction<LeadName>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadNames: state.lead?.LeadNames!.concat(action.payload)
                    }
                }
            },
        ),
        addLeadFile: create.reducer(
            (state, action: PayloadAction<LeadFile>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadFiles: state.lead?.LeadFiles!.concat(action.payload)
                    }
                }
            },
        ),
        updateLeadEmail: create.reducer(
            (state, action: PayloadAction<LeadEmail>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadEmails: state.lead?.LeadEmails!.map(args => {
                            if (action.payload?.external_id == args?.external_id) {
                                args = action.payload;
                            }
                            return args;
                        })
                    }
                }
            },
        ),
        updateLeadName: create.reducer(
            (state, action: PayloadAction<LeadName>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadNames: state.lead?.LeadNames!.map(args => {
                            if (action.payload?.external_id == args?.external_id) {
                                args = action.payload;
                            }
                            return args;
                        })
                    }
                }
            },
        ),
        updateLeadAddress: create.reducer(
            (state, action: PayloadAction<LeadAddress>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadAddresses: state.lead?.LeadAddresses!.map(args => {
                            if (action.payload?.external_id == args?.external_id) {
                                args = action.payload;
                            }
                            return args;
                        })
                    }
                }
            },
        ),
        removeLeadName: create.reducer(
            (state, action: PayloadAction<LeadName>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadNames: state.lead?.LeadNames!.filter(name => name?.external_id != action.payload?.external_id)
                    }
                }
            },
        ),
        removeLeadEmail: create.reducer(
            (state, action: PayloadAction<LeadEmail>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadEmails: state.lead?.LeadEmails!.filter(name => name?.external_id != action.payload?.external_id)
                    }
                }
            },
        ),
        removeLeadAddress: create.reducer(
            (state, action: PayloadAction<LeadAddress>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadAddresses: state.lead?.LeadAddresses!.filter(address => address?.external_id != action.payload?.external_id)
                    }
                }
            },
        ),
        removeLeadFile: create.reducer(
            (state, action: PayloadAction<LeadFile>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        LeadFiles: state.lead?.LeadFiles!.filter(file => file?.external_id != action.payload?.external_id)
                    }
                }
            },
        ),
        updateLeadStatNumber: create.reducer(
            (state, action: PayloadAction<StatNumber | undefined | null>) => {
                return {
                    ...state,
                    lead: {
                        ...state.lead,
                        StatNumber: action.payload
                    }
                }
            }
        ),
        updateLead: create.reducer(
            (state, action: PayloadAction<Lead>) => {
                return {
                    ...state,
                    lead: action.payload
                }
            }
        ),
        destroyLead: create.reducer(
            (state) => {
                return {
                    ...state,
                    lead: null
                }
            }
        )
    }),
    selectors: {
        leadExternalId: state => state.lead?.external_id,
        lead: state => state.lead,
        leadStatNumber: state => state.lead?.StatNumber,
        leadAddresses: state => state.lead?.LeadAddresses,
        leadActivityLists: state => state.lead?.ActivityLists,
        leadNames: state => state.lead?.LeadNames,
        leadEmails: state => state.lead?.LeadEmails,
        leadFiles: state => state.lead?.LeadFiles,
        leadSimilarAccounts: state => state.lead?.Childrens,
        enterToSubmit: state => state.enterToSubmit,
        leadPrincipalBalance: state => state.lead?.Childrens?.reduce((a: number, b: Maybe<Lead>) => {
            return a + b?.LeadBalance?.principal_balance!
        }, 0),
        leadFeeBalance: state => state.lead?.Childrens?.reduce((a: number, b: Maybe<Lead>) => {
            return a + b?.LeadBalance?.service_fee_balance! + b?.LeadBalance?.check_fee_balance!
        }, 0),
        leadInterestBalance: state => state.lead?.Childrens?.reduce((a: number, b: Maybe<Lead>) => {
            return a + b?.LeadBalance?.interest_balance!
        }, 0),
        leadBalance: state => state.lead?.Childrens?.reduce((a: number, b: Maybe<Lead>) => {
            return a + b?.LeadBalance?.balance!
        }, 0),
        leadPaid: state => state.lead?.Childrens?.reduce((a: number, b: Maybe<Lead>) => {
            return a + b?.LeadBalance?.interest_paid! + b?.LeadBalance?.check_fee_paid! + b?.LeadBalance?.principal_paid! + b?.LeadBalance?.service_fee_paid!
        }, 0)
    }
});

export const { addLeadAddress, addLeadName, destroyLead, updateLead, removeLeadName, updateLeadName, addLeadEmail, removeLeadEmail, updateLeadEmail, updateLeadInformation, removeLeadAddress, updateLeadAddress, addLeadFile, removeLeadFile, updateLeadStatNumber, addActivityList, updateEnterToSubmit } = leadSlice.actions

export const { lead, leadAddresses, leadNames, leadExternalId, leadEmails, leadFiles, leadStatNumber, leadActivityLists, enterToSubmit, leadSimilarAccounts, leadPrincipalBalance, leadFeeBalance, leadInterestBalance, leadBalance, leadPaid } = leadSlice.selectors