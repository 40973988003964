import { Box, CircularProgress, DialogContentText, Grid, Typography } from "@mui/material";

interface ArgsType {
    leads: number
    total_character_count: number
    voicemails: {
        total: number
    }
    loading: boolean
    total_price: number
    description: string
}

export default function VoicemailPrice({ leads, total_character_count, loading, voicemails, total_price, description }: Readonly<ArgsType>) {
    return (
        <Box sx={{ display: "flex" }}>{!loading ?
            <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography>Leads</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Characters</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Voicemails</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Price</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography>{leads.toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>{total_character_count.toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>{voicemails?.total.toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>${total_price.toLocaleString()}</Typography>
                    </Grid>
                </Grid>
                {description && <Typography variant="subtitle1">
                    {description}
                </Typography>}
            </Box> : <CircularProgress style={{ marginInline: "auto", marginTop: "30px", marginBottom: "10px" }} />}
        </Box>
    );
}