import { DialogContent, TextField } from "@mui/material";
import AddressForm from "../address/form";
import { BankRouting, Subaccount } from "../../../../gql/types.graphql";

export default function BankRoutingForm({ defaultValue }: Readonly<{ defaultValue: Subaccount }>) {
    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}