import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddDispositionCodeDocument, DeleteDispositionCodeDocument, DispositionCode, DispositionCodesDocument, UpdateDispositionCodeDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function DispositionCodeScreen() {
    const [query, { loading }] = useLazyQuery(DispositionCodesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddDispositionCodeDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateDispositionCodeDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteDispositionCodeDocument);

    const columns: GridColDef<DispositionCode>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', flex: 1 }
    ];

    function formatParams(args: DispositionCode) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"dispositionCode"} pluralQueryName={"dispositionCodes"} formatParams={formatParams} form={Form} title={"Disposition Codes"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}