import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddPhoneTypeDocument, DeletePhoneTypeDocument, PhoneType, PhoneTypesDocument, UpdatePhoneTypeDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function PhoneTypeScreen() {
    const [query, { loading }] = useLazyQuery(PhoneTypesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddPhoneTypeDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdatePhoneTypeDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeletePhoneTypeDocument);

    const columns: GridColDef<PhoneType>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 }
    ];

    function formatParams(args: PhoneType) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"phoneType"} pluralQueryName={"phoneTypes"} formatParams={formatParams} form={Form} title={"Phone Types"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}