import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddCustomerDocument, DeleteCustomerDocument, Customer, CustomersDocument, UpdateCustomerDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function CustomerScreen() {
    const [query, { loading }] = useLazyQuery(CustomersDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddCustomerDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateCustomerDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteCustomerDocument);

    const columns: GridColDef<Customer>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'first_name', headerName: 'First Name', width: 150, valueGetter({ value }) {
                return value || ""
            },
        },
        {
            field: 'last_name', headerName: 'Last Name', width: 150, valueGetter({ value }) {
                return value || ""
            },
        },
        {
            field: 'email', headerName: 'Email', width: 150, valueGetter({ value }) {
                return value || ""
            },
        },
        {
            field: 'phone_number', headerName: 'Phone Number', flex: 1, valueGetter({ value }) {
                return value || ""
            },
        }
    ];

    function formatParams(args: Customer) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"customer"} pluralQueryName={"customers"} formatParams={formatParams} form={Form} title={"Customers"} update={update} add={add} destroy={destroy} loading={loading || loadingAdd || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}