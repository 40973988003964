import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddSegmentCategoryDocument, DeleteSegmentCategoryDocument, SegmentCategory, SegmentCategoriesDocument, UpdateSegmentCategoryDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function SegmentCategoryScreen() {
    const [query, { loading }] = useLazyQuery(SegmentCategoriesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddSegmentCategoryDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateSegmentCategoryDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteSegmentCategoryDocument);

    const columns: GridColDef<SegmentCategory>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'type', headerName: 'Type', width: 100 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', flex: 1 },
    ];

    function formatParams(args: SegmentCategory) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"segmentCategory"} pluralQueryName={"segmentCategories"} formatParams={formatParams} form={Form} title={"Segment Categories"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}