import { Add, Close } from "@mui/icons-material";
import { Box, CardHeader, List, Typography } from "@mui/material";
import { LeadEmail, useAddLeadEmailMutation, useDeleteLeadEmailMutation, useUpdateLeadEmailMutation } from "../../../../../gql/types.graphql";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { addLeadEmail, lead, leadEmails, removeLeadEmail, updateLeadEmail } from "../../../../..//store/reducers/lead";
import { LoadingButton } from "@mui/lab";
import AddLeadEmail from "./AddLeadEmail";

export default function LeadEmails(): JSX.Element {
    const leadSelector = useAppSelector(lead);
    const emails = useAppSelector(leadEmails);
    const dispatch = useAppDispatch();
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [add, { loading: loadingAdd }] = useAddLeadEmailMutation();
    const [update, { loading: loadingUpdate }] = useUpdateLeadEmailMutation();
    const [destroy, { loading: loadingDestroy }] = useDeleteLeadEmailMutation();

    function onSubmit(args: LeadEmail) {
        setOpenEdit(false);
        if (args.external_id) {
            update({
                variables: {
                    email: args.email!,
                    external_id: args.external_id,
                    review_date: args.review_date
                }
            }).then(response => {
                const result = response.data?.updateLeadEmail;
                if (result) {
                    dispatch(updateLeadEmail(result))
                }
            })
        } else {
            add({
                variables: {
                    email: args.email!,
                    lead_id: leadSelector?.external_id!,
                    review_date: args.review_date
                }
            }).then(response => {
                const result = response.data?.addLeadEmail;
                if (result) {
                    dispatch(addLeadEmail(result))
                }
            })
        }
    }

    function onDelete(args: LeadEmail) {
        dispatch(removeLeadEmail(args));
        destroy({
            variables: {
                external_id: args.external_id!
            }
        }).then(response => {

        })
    }


    return (
        <Box>
            <CardHeader style={{ padding: '0px', marginBottom: '10px' }} action={<LoadingButton loading={loadingAdd} onClick={() => setOpenEdit(!openEdit)}>{openEdit ? <Close></Close> : <Add></Add>}</LoadingButton>} avatar={<Typography fontWeight={600}>Emails</Typography>} />
            {openEdit && <AddLeadEmail loading={loadingAdd} onSubmit={onSubmit}></AddLeadEmail>}
            <List dense={true} sx={{
                width: '100%',
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                paddingLeft: '0px',
                maxHeight: 170,
                '& ul': { padding: 0 }
            }}>
                {emails?.map((email, index) => {
                    return (
                        <AddLeadEmail loading={loadingUpdate} key={email?.external_id} onDelete={onDelete} onSubmit={onSubmit} defaultValue={email!}></AddLeadEmail>
                    )
                })}
            </List>
        </Box>
    );
}