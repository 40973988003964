

import { Box, Button, ButtonPropsColorOverrides, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { OverridableStringUnion } from '@mui/types';
interface ArgsType {
    onClick: Function
    text: String
    color?: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
    >
}

export default function ButtonLink({ onClick, text, color = 'success' }: Readonly<ArgsType>): JSX.Element {
    return (
        <Button disableElevation color={color} variant="contained" style={{ paddingInline: '20px' }} onClick={() => onClick()}>
            <AddIcon style={{ fontSize: '14px', marginRight: '5px' }}></AddIcon><Typography style={{ fontSize: '14px', textTransform: 'capitalize' }}>{text}</Typography>
        </Button>
    );
}