import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddUserDocument, DeleteUserDocument, User, UsersDocument, UpdateUserDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";
import { Close, Check } from '@mui/icons-material';

export default function UserScreen() {
    const [query, { loading }] = useLazyQuery(UsersDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddUserDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateUserDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteUserDocument);

    const columns: GridColDef<User>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'first_name', headerName: 'First Name', flex: 1 },
        { field: 'last_name', headerName: 'Last Name', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'verified_email', headerName: 'Verified Email', sortable: false, renderCell: (params) => {
                return (params.row.verified_email ? <Check /> : <Close />)
            }
        },
    ];

    function formatParams(args: User) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"user"} pluralQueryName={"users"} formatParams={formatParams} form={Form} title={"Users"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}