import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ActivityList, Lead, StatNumber, useLeadsLazyQuery, useStatNumbersLazyQuery } from '../../../../gql/types.graphql';
import { Avatar, AvatarGroup, Card, CardContent, CardHeader, Divider, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { AddTask, Checklist, FilterAlt, LinkRounded, MoreTime, NavigateBefore, NavigateNext, RecordVoiceOver, Timeline } from '@mui/icons-material';
import { useDemoData } from '@mui/x-data-grid-generator';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { destroyLead, lead, updateLead } from "../../../../store/reducers/lead";
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import SearchInput, { Search, StyledInputBase } from '../../../../components/SearchInput';
import LeadInfoCardLoading from './components/LeadInfoCardLoading';
import OnlyIconLogo from '../../../../components/OnlyIconLogo';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import LeadStatNumber from './components/EditLeadStatNumber';
import LeadInfoCard from './components/LeadInfoCard';
import ActivityListTable from './components/ActivityList';
import SimilarAccountTable from './components/SimilarAccount';
import { StyledAppBar, StyledToolbar } from '../../../../components/StyledComponents';

export default function AgentBoardScreen() {
    const [query, { loading }] = useLeadsLazyQuery();
    const leadSelector = useAppSelector(lead);
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { data } = useDemoData({
        dataSet: 'Commodity',
        rowLength: 100,
        editable: true,
    });

    useEffect(() => {
        const lead_id = searchParams.get('lead_id');
        if (lead_id) {
            searchLead(null, lead_id)
        }
    }, []);

    function searchLead(lead_id?: string | null, external_id?: string) {
        if ((lead_id || external_id) && !loading) {
            query({
                variables: {
                    lead_id: lead_id,
                    external_id: external_id
                }
            }).then(response => {
                let leads = response.data?.leads?.data;
                if (leads) {
                    if (leads.length == 1) {
                        dispatch(updateLead(leads[0] as Lead));
                        setSearchParams({ ['lead_id']: leads[0]?.external_id! })
                    } else if (leads.length == 0) {
                        dispatch(destroyLead());
                    }
                }
            })
        }
    }

    return (<Box sx={{ flexGrow: 1 }}>
        <StyledAppBar position="static">
            <StyledToolbar>
                <Box display={'flex'} alignItems={'center'}>
                    <OnlyIconLogo fillColor='#0169ff' ></OnlyIconLogo>
                    <IconButton>
                        <NavigateBefore color='primary'></NavigateBefore>
                    </IconButton>
                    <Typography color={'black'} component={'span'} fontWeight={'bold'} fontSize={'20px'}>{leadSelector?.id}</Typography>
                    <IconButton>
                        <NavigateNext color='primary'></NavigateNext>
                    </IconButton>
                    <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                    <Tooltip title="Schedule">
                        <IconButton>
                            <MoreTime color='primary'></MoreTime>
                        </IconButton>
                    </Tooltip>
                    <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                    <Tooltip title="Worklist">
                        <IconButton>
                            <Checklist color='primary'></Checklist>
                        </IconButton>
                    </Tooltip>
                    <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                    <Tooltip title="Voicemail">
                        <IconButton>
                            <RecordVoiceOver color='primary'></RecordVoiceOver>
                        </IconButton>
                    </Tooltip>
                    <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                    <Tooltip title="History">
                        <IconButton>
                            <Timeline color='primary'></Timeline>
                        </IconButton>
                    </Tooltip>
                    <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                    <Tooltip title="Action Codes">
                        <IconButton>
                            <AddTask color='primary'></AddTask>
                        </IconButton>
                    </Tooltip>
                    <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                    <Tooltip title="Share Link">
                        <IconButton>
                            <LinkRounded color='primary'></LinkRounded>
                        </IconButton>
                    </Tooltip>
                    <Divider style={{ marginInline: '10px' }} orientation="vertical" variant="middle" flexItem />
                    <LeadStatNumber></LeadStatNumber>
                </Box>
                <Box display={'flex'}>
                    <Tooltip title="Users in this lead">
                        <AvatarGroup max={4}>
                            <Avatar alt="Remy Sharp" src="https://mui.com/static/images/avatar/1.jpg" />
                            <Avatar alt="Travis Howard" src="https://mui.com/static/images/avatar/2.jpg" />
                            <Avatar alt="Cindy Baker" src="https://mui.com/static/images/avatar/3.jpg" />
                            <Avatar alt="Agnes Walker" src="https://mui.com/static/images/avatar/4.jpg" />
                            <Avatar alt="Trevor Henderson" src="https://mui.com/static/images/avatar/5.jpg" />
                        </AvatarGroup>
                    </Tooltip>
                    <SearchInput loading={loading} onChange={searchLead}></SearchInput>
                    <Tooltip title="Advanced Search">
                        <IconButton color='primary'>
                            <FilterAlt></FilterAlt>
                        </IconButton>
                    </Tooltip>
                </Box>
            </StyledToolbar>
        </StyledAppBar>
        <Box width={'100%'}>
            {leadSelector && <Grid style={{ padding: '10px' }} container spacing={1}>
                <Grid item sm={7}>
                    {loading ? <LeadInfoCardLoading></LeadInfoCardLoading> : <LeadInfoCard></LeadInfoCard>}
                </Grid>
                <Grid item sm={5}>
                    <ActivityListTable></ActivityListTable>
                </Grid>
                <Grid item sm={7}>
                    <Card>
                        <CardHeader action={<></>} avatar={<Typography fontSize={'20px'} fontWeight={600}>Phone Numbers</Typography>}></CardHeader>
                        <CardContent>
                            <DataGridPro
                                rows={[]}
                                columns={[]}
                                style={{ height: '433px' }}
                                loading={data.rows.length === 0}
                                rowHeight={38}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={5}>
                    <Card>
                        <CardHeader action={<></>} avatar={<Typography fontSize={'20px'} fontWeight={600}>Payment History</Typography>}></CardHeader>
                        <CardContent>
                            <DataGridPro
                                rows={[]}
                                columns={[]}
                                style={{ height: '433px' }}
                                loading={data.rows.length === 0}
                                rowHeight={38}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={7}>
                    <SimilarAccountTable></SimilarAccountTable>
                </Grid>
            </Grid>}
        </Box>
    </Box >)
}