
import { PayloadAction } from '@reduxjs/toolkit';
import { CompanyUser, User } from '../../gql/types.graphql';
import { createAppSlice } from '../slice';

export interface StateUserLogin {
    user?: User | null,
    companyUser?: CompanyUser | null,
    isLoading?: boolean,
    isSignout?: boolean,
    userToken?: string | null
}

export interface UserLogin {
    token: string,
    user: User
}

export interface StoreReducerType {
    user: StateUserLogin
}

const initialState: Readonly<StateUserLogin> = {
    user: {},
    companyUser: null,
    isLoading: true,
    isSignout: false,
    userToken: null
};

export const userSlice = createAppSlice({
    name: "user",
    initialState,
    reducers: create => ({
        restoreToken: create.reducer(
            (state, action: PayloadAction<User>) => {
                return {
                    ...state,
                    isLoading: false,
                    userToken: action.payload.token,
                    user: action.payload
                }
            },
        ),
        signIn: create.reducer(
            (state, action: PayloadAction<User>) => {
                return {
                    ...state,
                    userToken: action.payload.token,
                    user: action.payload,
                    isSignout: false,
                    isLoading: false
                }
            }
        ),
        signOut: create.reducer(
            (state) => {
                return {
                    ...state,
                    companyUser: null,
                    user: null,
                    userToken: null,
                    isSignout: true,
                    isLoading: false
                }
            }
        ),
        joinCompanyUser: create.reducer(
            (state, action: PayloadAction<CompanyUser>) => {
                return {
                    ...state,
                    companyUser: action.payload!
                }
            }
        ),
        removeCompanyUser: create.reducer(
            (state) => {
                return {
                    ...state,
                    companyUser: null
                }
            }
        ),
        updateCompanyUsers: create.reducer(
            (state, action: PayloadAction<CompanyUser[]>) => {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        CompanyUsers: action.payload
                    }
                }
            }
        ),
        updateAcceptCompanyUser: create.reducer(
            (state, action: PayloadAction<CompanyUser>) => {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        CompanyUsers: state.user?.CompanyUsers?.map(user => {
                            if (user?.external_id == action.payload.external_id) {
                                user = action.payload;
                            }
                            return user;
                        })
                    }
                }
            }
        ),
        fetchUser: create.asyncThunk(
            () => {
                return {

                }
            }
        )
    }),
    selectors: {
        user: state => state
    }
});

export const { fetchUser, restoreToken, signIn, signOut, updateAcceptCompanyUser, joinCompanyUser, updateCompanyUsers, removeCompanyUser } = userSlice.actions

export const { user } = userSlice.selectors