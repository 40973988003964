import { Card, CardContent, Grid, Skeleton } from "@mui/material";

export default function LeadInfoCardLoading(): JSX.Element {
    return (<Card>
        <CardContent>
            <Grid spacing={3} container>
                <Grid item sm={12}>
                    <Skeleton variant="rectangular" height={80} />
                </Grid>
                <Grid item sm={12}>
                    <Skeleton variant="rectangular" height={80} />
                </Grid>
                <Grid item sm={4}>
                    <Skeleton variant="rectangular" height={100} />
                </Grid>
                <Grid item sm={4}>
                    <Skeleton variant="rectangular" height={100} />
                </Grid>
                <Grid item sm={4}>
                    <Skeleton variant="rectangular" height={100} />
                </Grid>
                <Grid item sm={6}>
                    <Skeleton variant="rectangular" height={130} />
                </Grid>
                <Grid item sm={6}>
                    <Skeleton variant="rectangular" height={130} />
                </Grid>
                <Grid item sm={6}>
                    <Skeleton variant="rectangular" height={130} />
                </Grid>
                <Grid item sm={6}>
                    <Skeleton variant="rectangular" height={130} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>)
}