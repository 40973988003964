import { DialogContent, FormControl, Input, InputLabel, TextField } from "@mui/material";
import { Customer, EmailIdentity } from "../../../../gql/types.graphql";
import { PhoneField } from "../../../../components/PhoneField";

export default function Form({ defaultValue }: Readonly<{ defaultValue: Customer }>) {

    return (<DialogContent style={{ width: '400px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.first_name}
            margin="dense"
            id="first_name"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.last_name}
            margin="dense"
            id="last_name"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            defaultValue={defaultValue?.email}
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
        />
        <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="formatted-text-mask-input">Phone Number</InputLabel>
            <Input
                autoFocus
                type="text"
                margin="dense"
                defaultValue={defaultValue?.phone_number}
                name="phone_number"
                id="phone_number"
                inputComponent={PhoneField as any}
            />
        </FormControl>
    </DialogContent>)
}