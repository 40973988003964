import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddTemplateTypeFieldDocument, DeleteTemplateTypeFieldDocument, TemplateTypeField, TemplateTypeFieldsDocument, UpdateTemplateTypeFieldDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";
import { Check, Close } from "@mui/icons-material";

export default function TemplateTypeFieldScreen() {
    const [query, { loading }] = useLazyQuery(TemplateTypeFieldsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddTemplateTypeFieldDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateTemplateTypeFieldDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteTemplateTypeFieldDocument);

    const columns: GridColDef<TemplateTypeField>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'field_id', headerName: 'Field', flex: 1 },
        {
            field: 'multiple', headerName: 'Multiple', sortable: false, renderCell: (params) => {
                return (params.row.multiple ? <Check /> : <Close />)
            }
        },
        {
            field: 'required', headerName: 'Required', sortable: false, renderCell: (params) => {
                return (params.row.required ? <Check /> : <Close />)
            }
        },
        { field: 'template_type', headerName: 'Template Type', flex: 1, valueGetter: ({ row }: { row: TemplateTypeField }) => `${row?.TemplateType?.name}` }
    ];

    function formatParams(args: TemplateTypeField) {
        //args.multiple = args.multiple ? true : false;
        //args.required = args.required ? true : false;
        return args;
    }

    return (<DataGridCRUD singularQueryName={"templateTypeField"} pluralQueryName={"templateTypeFields"} formatParams={formatParams} form={Form} title={"Template Type Fields"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}