import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../../components/DataGridCRUD";
import { AddSegmentDocument, DeleteSegmentDocument, Segment, SegmentsDocument, UpdateSegmentDocument, } from "../../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";
import ButtonLink from "../../../../../components/ButtonLink";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

export default function SegmentListScreen() {
    const [query, { loading }] = useLazyQuery(SegmentsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddSegmentDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateSegmentDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteSegmentDocument);
    const navigate = useNavigate();

    const columns: GridColDef<Segment>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 300 },
        { field: 'subject', headerName: 'Subject', flex: 1 }
    ];

    function formatParams(args: Segment) {
        return args;
    }

    return (<DataGridCRUD editButtonClick={(row: Segment) => navigate(`/marketing/email-templates/edit/${row.external_id}`)} addButton={ <ButtonLink text={'Import'} onClick={() => navigate("/marketing/segments/import")}></ButtonLink>} singularQueryName={"segment"} pluralQueryName={"segments"} formatParams={formatParams} title={"Segments"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}