import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddBranchDocument, DeleteBranchDocument, BranchesDocument, UpdateBranchDocument, Branch } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function BranchScreen() {
    const [query, { loading }] = useLazyQuery(BranchesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddBranchDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateBranchDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteBranchDocument);
    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'short_name', headerName: 'Short Name', flex: 1 },
        { field: 'city', headerName: 'City', minWidth: 200, valueGetter: ({ row }: { row: Branch }) => `${row?.Address?.city}` },
        { field: 'state', headerName: 'State', minWidth: 100, valueGetter: ({ row }: { row: Branch }) => `${row?.Address?.state}` }
    ]

    function formatParams(args: Branch) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"branch"} pluralQueryName={"branches"} formatParams={formatParams} form={Form} title={"Branches"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}