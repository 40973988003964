import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { StatNumber, useStatNumbersLazyQuery, useUpdateLeadStatNumberMutation } from "../../../../../gql/types.graphql";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { leadExternalId, leadStatNumber, updateLeadStatNumber } from "../../../../../store/reducers/lead";
import { useState } from "react";
import { Edit, Save } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import AutocompleteSelect from "../../../../../components/AutocompleteSelect";

export function formatPhoneNumber(phone: string) {
    const cleaned = ('' + phone).replace(/\D/g, '');
    const match = RegExp(/^(\d{3})(\d{3})(\d{4})$/).exec(cleaned);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

export default function LeadStatNumber(): JSX.Element {
    const [queryStatNumbers, { loading: loadingStatNumber }] = useStatNumbersLazyQuery();
    const [update, { loading: loadingUpdate }] = useUpdateLeadStatNumberMutation();
    const statNumberSelector = useAppSelector(leadStatNumber);
    const externalId = useAppSelector(leadExternalId);
    const dispatch = useAppDispatch();
    const [edit, setEdit] = useState<boolean>(false);
    const [statNumber, setStatNumber] = useState<StatNumber>();

    function formatStatNumberParams(args: StatNumber) {
        return `${formatPhoneNumber(args?.phone!)}`
    }

    function updateStatNumber() {
        console.log('statNumber', statNumber)
        update({
            variables: {
                external_id: externalId!,
                stat_number_id: statNumber?.external_id!
            }
        }).then(response => {
            const statNumber = response?.data?.updateLeadStatNumber;
            dispatch(updateLeadStatNumber(statNumber));
            setEdit(false);
        })
    }

    return (
        <Box>
            {(!statNumberSelector && !edit) ? <Button onClick={() => setEdit(!edit)}><Typography color={red[600]} fontWeight={'bold'} fontSize={'16px'}>Add stat number</Typography></Button> :
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box width={`${edit ? 200 : 150}px`} >
                        {!edit && <Typography color={'black'} fontWeight={'bold'} fontSize={'13px'}>Stat Number</Typography>}
                        <Box>
                            {edit ? <AutocompleteSelect style={{}} onChange={setStatNumber} defaultValue={statNumberSelector} name={'phone'} formatParams={formatStatNumberParams} queryName={"statNumbers"} query={queryStatNumbers} label="Stat Number" id="phone"></AutocompleteSelect> : <Typography fontSize={`18px`} color={red[600]} fontWeight={'bold'}>{formatPhoneNumber(statNumberSelector?.phone!)}</Typography>}
                        </Box>
                    </Box>
                    {edit ? <Tooltip title="Save stat number"><IconButton color="success" onClick={() => updateStatNumber()} ><Save></Save></IconButton></Tooltip> : <Tooltip title="Edit stat number"><IconButton color="primary" onClick={() => setEdit(!edit)}><Edit></Edit></IconButton></Tooltip>}
                </Box>}
        </Box>
    )
}

/**  {(!statNumberSelector) ? <Button onClick={() => setEdit(!edit)}><Typography color={red[600]} fontWeight={'bold'} fontSize={'16px'}>Add stat number</Typography></Button> : <Box sx={{ width: `${!edit ? 150 : 200}px` }}>
                {statNumberSelector && <Typography color={'black'} fontWeight={'bold'} fontSize={'13px'}>Stat Number</Typography>}
                {!edit && statNumberSelector ? <Typography fontSize={`${statNumberSelector?.phone ? 18 : 16}px`} color={!edit ? red[600] : 'black'} fontWeight={'bold'}>{statNumberSelector?.phone ? formatPhoneNumber(statNumberSelector?.phone) : 'Add Stat Number'}</Typography> : <AutocompleteSelect style={{}} onChange={setStatNumber} defaultValue={statNumberSelector} name={'phone'} formatParams={formatStatNumberParams} queryName={"statNumbers"} query={queryStatNumbers} label="Stat Number" id="phone"></AutocompleteSelect>}
                {!edit ? <IconButton onClick={() => updateStatNumber()}><Edit></Edit></IconButton> : <IconButton onClick={() => setEdit(!edit)}><Save></Save></IconButton>}
            </Box>} */