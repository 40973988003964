import { DialogContent, FormControl, Grid, Input, InputLabel, TextField, Typography } from "@mui/material";
import { Vendor, VendorTypesDocument } from "../../../../gql/types.graphql";
import { useLazyQuery } from "@apollo/client";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { PhoneField } from "../../../../components/PhoneField";
import AddressForm from "../address/form";

export default function Form({ defaultValue }: Readonly<{ defaultValue: Vendor }>) {
    const [queryVendorTypes, { loading: loadingVendorType }] = useLazyQuery(VendorTypesDocument);

    function formatParams(args: Vendor): string {
        return `${args.name}`;
    }


    return (<DialogContent style={{ width: '600px' }}>
        <Grid container spacing={2}>
            <Grid xs={12} md={6} item>
                <TextField
                    autoFocus
                    required
                    defaultValue={defaultValue?.name}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <AutocompleteSelect defaultValue={defaultValue?.VendorType} name={'vendor_type_id'} formatParams={formatParams} queryName={"vendorTypes"} query={queryVendorTypes} label="Vendor Type" id="vendor_type_id"></AutocompleteSelect>
                <TextField
                    autoFocus
                    defaultValue={defaultValue?.description}
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    type="text"
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    variant="standard"
                />
            </Grid>
            <Grid xs={12} md={6} item>
                <TextField
                    autoFocus
                    defaultValue={defaultValue?.contact_name}
                    margin="dense"
                    id="contact_name"
                    name="contact_name"
                    label="Contact Name"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    autoFocus
                    defaultValue={defaultValue?.email}
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email"
                    type="text"
                    fullWidth
                    variant="standard"
                />
                <FormControl style={{ marginTop: "8px" }} fullWidth variant="standard">
                    <InputLabel htmlFor="formatted-text-mask-input">Phone</InputLabel>
                    <Input
                        autoFocus
                        type="text"
                        margin="dense"
                        inputProps={{
                            maxLength: 14
                        }}
                        defaultValue={defaultValue?.phone}
                        name="phone"
                        id="phone"
                        inputComponent={PhoneField as any}
                    />
                </FormControl>
                <AddressForm required={false} defaultValue={defaultValue?.Address!}></AddressForm>
            </Grid>
        </Grid>
    </DialogContent>)
}