import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, CircularProgress, colors, Snackbar, SnackbarCloseReason, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Company, CompanyPublicInfoDocument, useCompanyPublicInfoLazyQuery, useUnsubscribeMutation } from "../../../gql/types.graphql";
import { useLazyQuery, useQuery } from "@apollo/client";

export default function UnsubscribeScreen(): JSX.Element {
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState<string>("");
    const [company, setCompany] = useState<Company>({});
    const [unsubscribe, { loading }] = useUnsubscribeMutation();
    const [query, { loading: companyLoading }] = useLazyQuery(CompanyPublicInfoDocument);
    const [open, setOpen] = useState<boolean>(false);
    const [msg, setMsg] = useState<string>('');

    useEffect(() => {
        const email = searchParams.get('email');
        const company_id = searchParams.get('company_id');
        if (searchParams.size && email && company_id) {
            setEmail(email);
            companyInfo(company_id);
        }
    }, [searchParams]);

    function companyInfo(id: string) {
        query({
            variables: {
                externalId: id
            }
        }).then(response => {
            const result = response.data?.companyPublicInfo;
            if (result) {
                setCompany(result);
            }
        })
    }

    function unsubscribeEmail() {
        unsubscribe({
            variables: {
                company_id: company.external_id!,
                email: email
            }
        }).then(response => {
            const result = response.data?.unsubscribe;
            if (result?.msg) {
                setMsg(result.msg);
                setOpen(true);
            }
        })
    }


    return (<Box sx={{ flexGrow: 1 }}>
        <Box margin={'auto'} maxWidth={'500px'} width={'80%'}>
            <Card style={{ marginTop: '50px' }}>
                <CardContent style={{ padding: '30px' }}>
                    {!companyLoading ? <>
                        {company.external_id ? <>
                            <img alt="Nelo" style={{ width: '100px' }} src={company.Image?.url!}></img>
                            <Box marginTop={'40px'}>
                                <Typography variant="h6">Email Subscription Lookup</Typography>
                                <Typography fontSize={'14pxF'}>{email ?? ''} -  Mailing List</Typography>
                                <Typography style={{ marginTop: '20px' }}>Want to stop receiving emails?</Typography>
                                <LoadingButton onClick={() => unsubscribeEmail()} loading={loading} style={{ textTransform: 'none' }}>
                                    <Typography style={{ textDecoration: 'underline', color: colors.blue[800] }}>Click here to unsubscribe</Typography>
                                </LoadingButton>
                                {open ? <Card elevation={0} style={{ borderColor: colors.green[700], borderWidth: '1px', borderStyle: 'solid', padding: '10px', marginTop: '20px' }}>
                                    <Typography fontWeight={'bold'} color={colors.green[900]}>{msg}</Typography>
                                </Card> : <></>}
                            </Box>
                        </> : <Typography>Company not found</Typography>
                        }
                    </> :
                        <CircularProgress></CircularProgress>
                    }
                </CardContent>
            </Card>
        </Box>
    </Box>)
}