import { DialogContent, TextField } from "@mui/material";
import { LeadStatus } from "../../../../gql/types.graphql";

export default function Form({ defaultValue }: Readonly<{ defaultValue: LeadStatus }>) {
    
    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            id="description"
            autoFocus
            required
            defaultValue={defaultValue?.description}
            name="description"
            label="Description"
            variant="standard"
            type="text"
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.status_code}
            margin="dense"
            id="status_code"
            name="status_code"
            label="Status Code"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.bureau_stat_code}
            margin="dense"
            id="bureau_stat_code"
            name="bureau_stat_code"
            label="Bureau Stat Code"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.color}
            margin="dense"
            id="color"
            name="color"
            label="Color"
            type="color"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}