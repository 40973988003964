import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../../components/DataGridCRUD";
import Form from "./form";
import { AddSystemEmailTemplateDocument, DeleteSystemEmailTemplateDocument, EmailTemplate, SystemEmailTemplatesDocument, UpdateSystemEmailTemplateDocument } from "../../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";
import ButtonLink from "../../../../../components/ButtonLink";
import { useNavigate } from "react-router-dom";

export default function SystemEmailTemplateListScreen() {
    const [query, { loading }] = useLazyQuery(SystemEmailTemplatesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddSystemEmailTemplateDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateSystemEmailTemplateDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteSystemEmailTemplateDocument);
    const navigate = useNavigate();

    const columns: GridColDef<EmailTemplate>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', width: 300 },
        { field: 'subject', headerName: 'Subject', flex: 1 }
    ];

    function formatParams(args: EmailTemplate) {
        return args;
    }

    return (<DataGridCRUD editButtonClick={(row: EmailTemplate) => navigate(`/system/email-templates/edit/${row.external_id}`)} addButton={<ButtonLink text={'Create'} onClick={() => navigate("/system/email-templates/create")}></ButtonLink>} singularQueryName={"systemEmailTemplate"} pluralQueryName={"systemEmailTemplates"} formatParams={formatParams} form={Form} title={"System Email Templates"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}