import { DialogContent, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { ActivityType, ActivityTypeEnum} from "../../../../gql/types.graphql";

export default function Form({ defaultValue }: Readonly<{ defaultValue: ActivityType }>) {
    const [activityType, setActivityType] = useState(Object.values(ActivityTypeEnum));

    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.description}
            margin="dense"
            id="description"
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
        />
        <Select
            style={{ marginTop: "20px" }}
            autoFocus
            required
            margin="dense"
            id="type"
            name="type"
            label="Type"
            defaultValue={defaultValue?.type ? defaultValue?.type : activityType[0]}
            fullWidth
            variant="standard"
        >
            {activityType.map(type => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
        </Select>
        <TextField
            autoFocus
            margin="dense"
            id="color"
            defaultValue={defaultValue?.color}
            name="color"
            label="Color"
            type="color"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}