import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddScriptDocument, DeleteScriptDocument, Script, ScriptsDocument, UpdateScriptDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function ScriptScreen() {
    const [query, { loading }] = useLazyQuery(ScriptsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddScriptDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateScriptDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteScriptDocument);

    const columns: GridColDef<Script>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 }
    ];

    function formatParams(args: Script) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"script"} pluralQueryName={"scripts"} formatParams={formatParams} form={Form} title={"Scripts"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}