import { DialogContent, TextField } from "@mui/material";
import { CompaniesDocument, Company, FileType, Permission } from "../../../../gql/types.graphql";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { useLazyQuery } from "@apollo/client";

export default function Form({ defaultValue }: Readonly<{ defaultValue: Permission }>) {

    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.roles}
            margin="dense"
            id="roles"
            name="roles"
            label="Roles"
            type="text"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}