import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import { AddSubaccountDocument, Subaccount, SubaccountsDocument, DeleteSubaccountDocument, UpdateSubaccountDocument } from "../../../../gql/types.graphql";
import SubaccountForm from "./form";
import { GridColDef } from "@mui/x-data-grid";
import { Check, Close } from "@mui/icons-material";

export default function SubaccountScreen() {
    const [query, { loading }] = useLazyQuery(SubaccountsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddSubaccountDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateSubaccountDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteSubaccountDocument);

    const columns: GridColDef<Subaccount>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'original_balance', headerName: 'Original Balance', flex: 1 },
        { field: 'leads', headerName: 'Leads', valueGetter: ({ row }: { row: Subaccount }) => `${row?.leads?.toLocaleString()}`, flex: 1 },
        { field: 'portfolio', headerName: 'Portfolio', valueGetter: ({ row }: { row: Subaccount }) => `${row?.Portfolio?.name}`, flex: 1 },
        {
            field: 'active', headerName: 'Active', sortable: false, renderCell: (params) => {
                return (params.row.active ? <Check /> : <Close />)
            }
        }
    ];

    function formatParams(args: Subaccount) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"subaccount"} pluralQueryName={"subaccounts"} formatParams={formatParams} form={SubaccountForm} title={"Subaccount"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}