import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddLeadStatusDocument, DeleteLeadStatusDocument, LeadStatus, LeadStatusesDocument, UpdateLeadStatusDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function LeadStatusScreen() {
    const [query, { loading }] = useLazyQuery(LeadStatusesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddLeadStatusDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateLeadStatusDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteLeadStatusDocument);

    const columns: GridColDef<LeadStatus>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
        {
            field: 'color', headerName: 'Color', flex: 1, renderCell: ({ row }: { row: LeadStatus }) => {
                return <div style={{ width: '20px', height: '20px', border: '1px solid black', backgroundColor: `${row.color}`, borderRadius: '50%' }}></div>
            }
        },
        { field: 'status_code', headerName: 'Status Code', flex: 1 },
        { field: 'bureau_stat_code', headerName: 'Bureau Stat Code', flex: 1 }
    ];

    function formatParams(args: LeadStatus) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"leadStatus"} pluralQueryName={"leadStatuses"} formatParams={formatParams} form={Form} title={"Lead Statuses"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}