import { Typography, Link, TypographyOwnProps, Box, Button, Grid, Card, CardHeader, CardActions, ButtonGroup, CardContent, Divider } from "@mui/material";
import BasicHeader from "../../../../components/BasicHeader";
import ButtonLink from "../../../../components/ButtonLink";
import FormModal from "../../../../components/FormModal";
import Form from "./form";
import { useEffect, useState } from "react";
import { AddVoiceDocument, DeleteVoiceDocument, EditVoiceDocument, InputVoice, Voice, VoicesDocument, useAddVoiceMutation, useDeleteVoiceMutation, useEditVoiceMutation, useVoicesLazyQuery } from "../../../../gql/types.graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { enqueueSnackbar } from "notistack";
import { Delete, Edit, PlayArrow } from "@mui/icons-material";

export default function VoiceCreatorScreen(props: Readonly<TypographyOwnProps>): JSX.Element {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [add, { loading: loadingAdd }] = useAddVoiceMutation();
    const [update, { loading: loadingUpdate }] = useEditVoiceMutation();
    const [destroy, { loading: loadingDestroy }] = useDeleteVoiceMutation();
    const [query, { loading: loading }] = useVoicesLazyQuery();
    const [voices, setVoices] = useState<Voice[]>([]);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    useEffect(() => {
        fetchData()
    }, []);


    const fetchData = () => {
        query({
            variables: {
                limit: paginationModel.pageSize,
                cursor: paginationModel.page
            }
        }).then(response => {
            let data = response?.data?.voices;
            if (data) {
                setVoices(data.data as Voice[])
            }

        }).catch(err => console.log(err.message))
    }

    function destroyVoice(voice: Voice) {
        destroy({
            variables: {
                external_id: voice.external_id!
            }
        }).then(response => {
            fetchData()
        })
    }


    function addVoice(voice: InputVoice) {
        add({
            variables: {
                file: voice.file,
                name: voice.name!,
                description: voice.description,
                labels: voice.labels
            }
        }).then(response => {
            fetchData()
        });
    }

    return (
        <Box>
            <BasicHeader add={<FormModal submit={addVoice} closeModal={() => setOpenModal(openModal)} openModal={openModal} defaultValue={{
                external_id: ""
            }} form={<Form defaultValue={{}}></Form>}></FormModal>} text="Voices"></BasicHeader>
            <Grid style={{ padding: '10px' }} container spacing={3}>
                {voices.map(voice => {
                    return (<Grid key={voice.external_id} item sm={4}>
                        <Card style={{ border: '1px solid #00000030', padding: '0px 20px' }} elevation={0}>
                            <CardContent style={{ paddingBottom: '0px' }}>
                                <Grid style={{ textAlign: 'left' }} container>
                                    <Grid item sm={12}>
                                        <Typography fontWeight={'bold'}>{voice.voice?.name}</Typography>
                                        <Typography>{voice.voice?.description}</Typography>
                                        <Divider style={{ margin: '15px 0px 0px 0px' }}></Divider>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container>
                                    <Grid item sm={4}>
                                        <Button style={{ fontSize: '10px' }} fullWidth size="small">
                                            <PlayArrow style={{ marginRight: '10px' }}></PlayArrow> Listen
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button style={{ fontSize: '10px' }} fullWidth size="small">
                                            <Edit style={{ marginRight: '10px' }}></Edit> Edit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button onClick={() => destroyVoice(voice)} style={{ fontSize: '10px' }} fullWidth size="small">
                                            <Delete style={{ marginRight: '10px' }}></Delete> Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>)
                })}
            </Grid>
        </Box>
    );
}