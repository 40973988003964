import { DialogContent, TextField } from "@mui/material";
import { EmailIdentity } from "../../../../gql/types.graphql";

export default function Form({ defaultValue }: Readonly<{ defaultValue: EmailIdentity }>) {

    return (<DialogContent style={{ width: '400px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.domain}
            margin="dense"
            id="domain"
            name="domain"
            label="Domain"
            type="text"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}