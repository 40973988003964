import { Box, Button, DialogContent, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ActivityType, CompaniesDocument, Company, ExternalVoice, InputVoice, Voice } from "../../../../gql/types.graphql";
import { useLazyQuery } from "@apollo/client";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "@emotion/styled";

export default function Form({ defaultValue }: Readonly<{ defaultValue: Voice }>) {
    const [voice, setVoice] = useState<InputVoice>({
        file: '',
        name: '',
        description: '',
        labels: ''
    });

    useEffect(() => {
        if (defaultValue) {
            setVoice({
                name: defaultValue.voice?.name || "",
                description: defaultValue.voice?.description || ""
            })
        }
    }, [defaultValue])

    function updateParams(args: InputVoice) {
        setVoice({
            ...voice,
            ...args
        })
    }


    return (<DialogContent style={{ width: '300px' }}>
        <Box>
            <TextField
                autoFocus
                required
                value={voice.name}
                onChange={(event) => updateParams({ name: event.target.value })}
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                autoFocus
                onChange={(event) => updateParams({ description: event.target.value })}
                value={voice.description}
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
            />
            {voice?.file && <Box sx={{ backgroundColor: '#e0e0e0', padding: '10px', marginTop: '10px' }}>
                {voice?.file?.name}
            </Box>}
            <Button
                style={{ marginTop: '10px' }}
                fullWidth
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                Upload file
                <input
                    type="file"
                    required
                    name="file"
                    style={{
                        clip: 'rect(0 0 0 0)',
                        clipPath: 'inset(50%)',
                        height: 1,
                        overflow: 'hidden',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        whiteSpace: 'nowrap',
                        width: 1
                    }}
                />
            </Button>
        </Box>
    </DialogContent>)
}