import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import { StyledToolbar } from "../../../../../components/StyledComponents";
import ToolbarTitle from "../../../../../components/ToolbarTitle";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { useEffect, useRef, useState } from "react";
import EditForm from "./edit-form";
import TestEmailForm from "./test-email-form";
import { Edit } from "@mui/icons-material";
import { EmailTemplate, Exact, InputMaybe, useAddSystemEmailTemplateMutation, useDeleteSystemEmailTemplateMutation, useSystemEmailTemplateLazyQuery, useTestSystemEmailTemplateMutation, useUpdateSystemEmailTemplateMutation } from "../../../../../gql/types.graphql";
import MenuList from "../../../../../components/MenuList";
import { LoadingButton } from "@mui/lab";
import { red } from "@mui/material/colors";
import { useParams } from "react-router-dom";

export default function SystemEmailTemplateCreateScreen() {
    const emailEditorRef = useRef<EditorRef>(null);
    const [email, setEmail] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [openTest, setOpenTest] = useState<boolean>(false);
    const [template, setTemplate] = useState<EmailTemplate>({});
    const [unlayerInit, setUnlayerInit] = useState<boolean>(false);
    const menu = ["Preview", "Import", "Export", "Delete"];
    const [test, { loading: loadingTest }] = useTestSystemEmailTemplateMutation();
    const [add, { loading: loadingAdd }] = useAddSystemEmailTemplateMutation();
    const [update, { loading: loadingUpdate }] = useUpdateSystemEmailTemplateMutation();
    const [destroy, { loading: loadingDestroy }] = useDeleteSystemEmailTemplateMutation();
    const { external_id } = useParams();
    const [query, { loading }] = useSystemEmailTemplateLazyQuery();

    useEffect(() => {
        if (external_id) {
            loadTemplate()
        }
    }, []);

    function loadTemplate() {
        query({
            variables: {
                external_id: external_id!
            }
        }).then(response => {
            const editTemplate = response?.data?.systemEmailTemplate;
            const design = editTemplate?.design;
            if (editTemplate && emailEditorRef.current?.editor) {
                setTemplate(editTemplate)
                emailEditorRef.current.editor.loadDesign(design);
            }
        })
    }

    function addSystemEmailTemplateDocument(emailTemplate: EmailTemplate) {
        add({
            variables: emailTemplate as Exact<{ name: string; subject: string; html: string; preview?: InputMaybe<string> | undefined; design: any; }>
        }).then(response => {
            console.log(response)
        })
    }

    function deleteSystemEmailTemplateDocument() {
        destroy({
            variables: {
                externalId: template.external_id!
            }
        }).then(response => {
            console.log(response)
        })
    }

    function testSystemEmailTemplateDocument(test_email: string) {
        setEmail(test_email);
        test({
            variables: {
                email: test_email,
                externalId: template.external_id!
            }
        }).then(response => {
            setOpenTest(false);
            console.log(response)
        })
    }

    function updateSystemEmailTemplateDocument(emailTemplate: EmailTemplate) {
        update({
            variables: emailTemplate as Exact<{ external_id: string; name: string; subject: string; html: string; preview?: InputMaybe<string> | undefined; design: any; }>
        }).then(response => {
            console.log(response)
        })
    }

    const saveTemplate = () => {
        if (template?.name) {
            if (emailEditorRef.current?.editor) {
                emailEditorRef.current.editor.exportHtml((data) => {
                    const { design, html } = data;
                    const emailTemplate = {
                        ...template,
                        html,
                        design
                    };
                    setTemplate(emailTemplate);
                    if (template?.external_id) {
                        updateSystemEmailTemplateDocument(emailTemplate)
                    } else {
                        addSystemEmailTemplateDocument(emailTemplate);
                    }
                });
            }
        } else {
            setOpen(true);
        }
    }

    return (
        <Box>
            <StyledToolbar>
                <Box alignItems={'center'} display={'flex'}>
                    <ToolbarTitle title={"Template Creator"}></ToolbarTitle>
                    <Divider sx={{ height: '35px', marginInline: "10px" }} orientation="vertical"></Divider>
                    {unlayerInit && <>
                        {template?.name && template?.subject ? <Box textAlign={'right'}>
                            <Typography fontSize={'16px'}><b>Name:</b> {template?.name}</Typography>
                            <Typography fontSize={'12px'}><b>Subject:</b> {template?.subject}</Typography>
                        </Box> : <Typography color={red[600]}>*Edit Name</Typography>}
                        <IconButton color={template?.name ? 'secondary' : 'error'} sx={{ marginLeft: '10px' }} onClick={() => setOpen(!open)}><Edit fontSize="small"></Edit></IconButton>
                    </>}
                </Box>
                {unlayerInit && <Stack direction={'row'} spacing={1}>
                    <Button onClick={() => setOpenTest(!openTest)} variant="text" color="secondary">Sent Test</Button>
                    <Button variant="text" color="primary">Merge Tags</Button>
                    <LoadingButton loading={loadingAdd || loadingUpdate} onClick={() => saveTemplate()} variant="text" color="success">Save</LoadingButton>
                    <MenuList options={menu}></MenuList>
                </Stack>}
            </StyledToolbar>
            <Box display="flex" flexDirection="column" height={'calc(100vh - 72px)'}>
                <EmailEditor
                    onReady={() => setUnlayerInit(true)}
                    ref={emailEditorRef}
                ></EmailEditor>
            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(!open)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const data = Object.fromEntries(formData.entries());
                        setTemplate({
                            ...template,
                            ...data
                        });
                        setOpen(false);
                    },
                }}
            >
                <DialogTitle>Edit</DialogTitle>
                <EditForm defaultValue={template}></EditForm>
                <DialogActions>
                    <Button color="secondary" type="submit">Save</Button>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openTest}
                onClose={() => setOpenTest(!openTest)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const data = Object.fromEntries(formData.entries());
                        testSystemEmailTemplateDocument(data.email as string)
                    },
                }}
            >
                <DialogTitle>Sent Test</DialogTitle>
                <TestEmailForm defaultValue={{}}></TestEmailForm>
                <DialogActions>
                    <LoadingButton loading={loadingTest} color="secondary" type="submit">Send</LoadingButton>
                    <Button onClick={() => setOpenTest(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}