import { Box, Card, CardContent, CircularProgress, colors, Grid, Typography } from "@mui/material";
import BasicHeader from "../../../../components/BasicHeader";
import { MarketingDasboard, useMarketingDashboardLazyQuery } from "../../../../gql/types.graphql";
import { useEffect, useState } from "react";

export default function MarketingDashboardScreen() {
    const [query, { loading }] = useMarketingDashboardLazyQuery();
    const [dashboard, setDashboard] = useState<MarketingDasboard>({
        Bounce: 0,
        Click: 0,
        Complaint: 0,
        Delivery: 0,
        Open: 0,
        Send: 0,
        DeliveryDelay: 0
    });

    function marketingDashboard() {
        query().then(response => {
            const result = response.data?.marketingDashboard;
            if (result) {
                setDashboard(result)
            }
        })
    };

    useEffect(() => {
        marketingDashboard()
    }, []);

    return (<Box>
        <BasicHeader text="Dashboard"></BasicHeader>
        {loading ? <CircularProgress style={{ marginTop: '10px' }}></CircularProgress> :
            <Grid style={{ padding: '10px' }} spacing={2} container>
                <Grid item sm={4}>
                    <Card style={{ backgroundColor: colors.blue[50] }}>
                        <CardContent>
                            <Typography variant="h5">{dashboard?.Send?.toLocaleString()}</Typography>
                            <Typography variant="body2">Emails Sent</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4}>
                    <Card style={{ backgroundColor: colors.orange[50] }}>
                        <CardContent>
                            <Typography variant="h5">{dashboard?.Bounce?.toLocaleString()}</Typography>
                            <Typography variant="body2">Emails Bounced</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4}>
                    <Card style={{ backgroundColor: colors.red[50] }}>
                        <CardContent>
                            <Typography variant="h5">{dashboard?.Complaint?.toLocaleString()}</Typography>
                            <Typography variant="body2">Complaint</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4}>
                    <Card style={{ backgroundColor: colors.purple[50] }}>
                        <CardContent>
                            <Typography variant="h5">{dashboard?.Open?.toLocaleString()}</Typography>
                            <Typography variant="body2">Emails Opened</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4}>
                    <Card style={{ backgroundColor: colors.green[50] }}>
                        <CardContent>
                            <Typography variant="h5">{dashboard?.Click?.toLocaleString()}</Typography>
                            <Typography variant="body2">Click-thru's</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>}
    </Box>)
}