import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddProductTypeDocument, DeleteProductTypeDocument, ProductType, ProductTypesDocument, UpdateProductTypeDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function ProductTypeScreen() {
    const [query, { loading }] = useLazyQuery(ProductTypesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddProductTypeDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateProductTypeDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteProductTypeDocument);

    const columns: GridColDef<ProductType>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 }
    ];

    function formatParams(args: ProductType) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"productType"} pluralQueryName={"productTypes"} formatParams={formatParams} form={Form} title={"Product Types"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}