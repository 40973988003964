import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddSegmentInputDocument, DeleteSegmentInputDocument, SegmentInput, SegmentInputsDocument, UpdateSegmentInputDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function SegmentInputScreen() {
    const [query, { loading }] = useLazyQuery(SegmentInputsDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddSegmentInputDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateSegmentInputDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteSegmentInputDocument);

    const columns: GridColDef<SegmentInput>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'operator', headerName: 'Operator', width: 100 },
        { field: 'placeholder', headerName: 'Placeholder', width: 150 },
        { field: 'value', headerName: 'Value', flex: 1 }
    ];

    function formatParams(args: SegmentInput) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"segmentInput"} pluralQueryName={"segmentInputs"} formatParams={formatParams} form={Form} title={"Segment Inputs"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}