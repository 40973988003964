import { DialogContent, TextField } from "@mui/material";
import { CompaniesDocument, Company, FileType } from "../../../../gql/types.graphql";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
import { useLazyQuery } from "@apollo/client";

export default function Form({ defaultValue }: Readonly<{ defaultValue: FileType }>) {
    const [query, { loading }] = useLazyQuery(CompaniesDocument);

    function formatParams(args: Company): string {
        return `${args.name}`;
    }

    return (<DialogContent style={{ width: '300px' }}>
        <TextField
            autoFocus
            required
            defaultValue={defaultValue?.name}
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
        />
    </DialogContent>)
}