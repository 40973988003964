import { Box, Button, Grid, Typography } from "@mui/material";
import { ImportBuilderType } from "./portfolio-import-builder";
import { useEffect, useState } from "react";
import { InMemoryCache, useMutation } from "@apollo/client";
import { Lead, PreviewPortfolioImportDocument } from "../../../../gql/types.graphql";
import { DataGrid } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';

export default function PortfolioOptionsForm({ defaultValue, next, back }: Readonly<{
    defaultValue: ImportBuilderType | null, back: () => void, next: (args: ImportBuilderType) => void;
}>) {
    const [previewPortfolioImport, { loading }] = useMutation(PreviewPortfolioImportDocument);
    const [value, setValue] = useState<ImportBuilderType>();

    const [columns, setColumns] = useState([{
        field: 'first_name',
        headerName: 'First Name',
    }, {
        field: 'last_name',
        headerName: 'Last Name',
    }, {
        field: 'ssn',
        headerName: 'SSN',
    }, {
        field: 'dob',
        headerName: 'DBO',
    }, {
        field: 'cosigner_first_name',
        headerName: 'Cosigner First Name',
    }, {
        field: 'cosigner_last_name',
        headerName: 'Cosigner Last Name',
    }, {
        field: 'cosigner_ssn',
        headerName: 'Cosigner SSN',
    }, {
        field: 'cosigner_dob',
        headerName: 'Cosigner DOB',
    }, {
        field: 'agcy_fee',
        headerName: 'agcy Fee',
    }, {
        field: 'charge_off',
        headerName: 'Charge Off',
    }, {
        field: 'reference',
        headerName: 'Reference',
    }, {
        field: 'list_date',
        headerName: 'List Date',
    }, {
        field: 'service_date',
        headerName: 'Service Date',
    }, {
        field: 'warning',
        headerName: 'Warning',
    }, {
        field: 'account',
        headerName: 'Account',
    }, {
        field: 'original_balance',
        headerName: 'Original Balance',
    }]);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        previewPortfolioImport({
            variables: {
                file: defaultValue?.Files![0],
                keys: Object.keys(defaultValue?.SelectedFields!),
                values: Object.values(defaultValue?.SelectedFields!),
                template_id: defaultValue?.Template?.id
            }
        }).then(response => {
            let leads = response.data?.previewPortfolioImport;
            if (leads) {
                setValue({
                    ...defaultValue,
                    leads: leads.map((lead: Lead) => {
                        delete lead.__typename;
                        return lead;
                    })
                });
            }
        });
    }, []);

    return (
        <Box sx={{ paddingTop: '20px' }}>
            <Box height="calc(100vh - 250px)">
                <DataGrid
                    rows={value?.leads || []}
                    getRowId={() => uuidv4()}
                    rowCount={value?.leads?.length}
                    loading={loading}
                    columns={columns}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button onClick={back} variant="contained">
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => next(value!)} variant="contained">
                    Import
                </Button>
            </Box>
        </Box>
    )
}