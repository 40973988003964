import { useLazyQuery, useMutation } from "@apollo/client";
import DataGridCRUD from "../../../../components/DataGridCRUD";
import Form from "./form";
import { AddTemplateTypeDocument, DeleteTemplateTypeDocument, TemplateType, TemplateTypesDocument, UpdateTemplateTypeDocument } from "../../../../gql/types.graphql";
import { GridColDef } from "@mui/x-data-grid";

export default function TemplateTypeScreen() {
    const [query, { loading }] = useLazyQuery(TemplateTypesDocument);
    const [add, { loading: loadingAdd }] = useMutation(AddTemplateTypeDocument);
    const [update, { loading: loadingUpdate }] = useMutation(UpdateTemplateTypeDocument);
    const [destroy, { loading: loadingDestroy }] = useMutation(DeleteTemplateTypeDocument);

    const columns: GridColDef<TemplateType>[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'name', headerName: 'Name', flex: 1 }
    ];

    function formatParams(args: TemplateType) {
        return args;
    }

    return (<DataGridCRUD singularQueryName={"templateType"} pluralQueryName={"templateTypes"} formatParams={formatParams} form={Form} title={"Template Type"} add={add} destroy={destroy} update={update} loading={loading || loadingAdd || loadingUpdate || loadingDestroy} query={query} columns={columns}></DataGridCRUD>)
}